export const DELETE_METHOD = 'DELETE';
export const GET_METHOD = 'GET';
export const POST_METHOD = 'POST';
export const PUT_METHOD = 'PUT';
export const ERROR_STATUS = 'ERROR';
export const SUCCESS_STATUS = 'SUCCESS';
export const SUCCESS_CODE = 200;
export const HTTP_CONFLICT_CODE = 409;
export const OTP_CODE = 202;
export const LOGIN_CODE = 201

export const CHANGE_PASSWORD_CODE = '20201';
export const RESET_PASSWORD_CODE = '20202';
export const NEW_DEVICE_CODE = '20203';
export const VERIFY_PHONE_NUMBER_CODE = '20200';
export const DEVICE_IN_USE_CODE = '20204';
export const EXCEED_DEVICE_LIMIT_CODE = '20205';
export const SELECT_USER_PROFILE = '20206';
export const HTTP_NOT_FOUND_CODE = 404;
export const HTTP_BAD_REQUEST_CODE = 400;
export const HTTP_SERVICE_UNAVAILABLE_CODE = 500;
export const GUEST = 'GUEST';
export const CONFLICT_USER = 'CONFLICT_USER';

export const PHONE_NUMBER = 'phoneNumber';
export const PRODUCTION = 'PRODUCTION';
