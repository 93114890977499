import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";

export default class ReportNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedServiceCategory: null,
      ...props,
    };
  }

  render() {
    return (
      <Row style={{ paddingLeft: this.state.deviceIsPC ? "30px" : "0px" }}>
        {!(this.state.currentUser && this.state.currentUser.isAggregator) && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "pos-report"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => {
              this.props.showPosReportTable();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "pos-report"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #F483FA -7.1%, #00B8DE 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                  boxShadow: "0 3px 8.3px 0.7px rgb(163 93 255 / 35%)",
                }}
              ></div>
              POS Request
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "pos-report"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
            {/* <img src={require('../../../../assets/media/icons/pay-a-bill-icon-3.png')} height="50%" width="50%" alt="" /> */}
          </Col>
        )}

        {
          //!(this.state.currentUser && this.state.currentUser.isAggregator) &&
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "transaction-report"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => {
              this.props.showTransactionReportTable();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory === "transaction-report"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                  boxShadow: "0 3px 8.3px 0.7px rgb(163 93 255 / 35%)",
                }}
              ></div>
              Transaction
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "transaction-report"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        }
        {process.env.REACT_APP_CAC_REGISTRATION === "true" && (
          <Col
            md={12}
            className={`service-category${
              this.state.selectedServiceCategory === "cac-registration-report"
                ? "-active"
                : ""
            }`}
            style={{
              alignItems: "center",
              borderRadius: process.env.REACT_APP_BORDER_STYLE ? "4px" : "24px",
              borderBottom: "0.6px solid #ccc",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: this.state.deviceIsPC
                ? process.env.REACT_APP_MID_FONT_SIZE
                : process.env.REACT_APP_MID_BIG_FONT_SIZE,
              padding: this.state.deviceIsPC ? "10pt" : "15pt",
              paddingRight: "10pt",
              paddingLeft: "15px",
              margin: "0px",
            }}
            onClick={() => {
              this.props.showRegistrationReportTable();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color:
                  this.state.selectedServiceCategory ===
                  "cac-registration-report"
                    ? process.env.REACT_APP_BLUE_COLOUR
                    : process.env.REACT_APP_BLACK_COLOUR,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)",
                  borderRadius: "4px",
                  height: "25pt",
                  marginRight: "20px",
                  width: "25pt",
                  boxShadow: "0 3px 8.3px 0.7px rgb(163 93 255 / 35%)",
                }}
              ></div>
              Registration Report
            </div>
            <MdArrowDropright
              color={
                this.state.selectedServiceCategory === "cac-registration-report"
                  ? process.env.REACT_APP_BLUE_COLOUR
                  : process.env.REACT_APP_LIGHT_GREY_COLOUR
              }
            />
          </Col>
        )}
      </Row>
    );
  }
}
