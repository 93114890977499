import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  buyAirtimeIcon,
  cashOutIcon,
  payBillIcon,
  sendMoneyIcon,
} from '../../../../../icons';
import {
  SHOW_CARD_LINKING,
  SHOW_OPEN_ACCOUNT,
} from '../../../../../utils/api-properties';
import ServiceCard from '../components/service-card';

import { SetupAgentCard } from '../components/setup-agent-card';

const desktopServiceCardRow = (props) => (
  <Row>
    <Col
      style={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '135pt',
        flex: 'nowrap',
        justifyContent: 'flex-start',
        overflowX: 'auto',
        padding: '15px',
        paddingLeft: '15px',
        paddingTop: props.deviceIsMobile ? '15px' : '5px',
      }}
    >
      {!props.currentUser.isAggregator && (
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#9F4FF5'}
          disabled={props.disabled}
          icon={(iconProps) =>
            payBillIcon({
              color: props.disabled ? '#A2A2A2' : '#9F4FF5',
              ...iconProps,
            })
          }
          text='Pay a Bill'
          slug='pay-a-bill'
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          navigateTo={props.navigateTo}
        />
      )}
      {!props.currentUser.isAggregator && (
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#00B8DE'}
          disabled={props.disabled}
          icon={(iconProps) =>
            buyAirtimeIcon({
              color: props.disabled ? '#A2A2A2' : '#00B8DE',
              ...iconProps,
            })
          }
          text='Sell Airtime & Data'
          slug='sell-airtime-and-data'
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          navigateTo={props.navigateTo}
        />
      )}
      {/* TODO check for whitelisted numbers */}
      {!props.currentUser.isAggregator && (
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#A5534F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            cashOutIcon({
              color: props.disabled ? '#A2A2A2' : '#A5534F',
              ...iconProps,
            })
          }
          text='Cardless Cashout'
          slug='cardless-cashout'
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          navigateTo={props.navigateTo}
        />
      )}
      {!props.currentUser.isAggregator && (
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#F5834F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            sendMoneyIcon({
              color: props.disabled ? '#A2A2A2' : '#F5834F',
              ...iconProps,
            })
          }
          text='Send Money'
          slug='send-money'
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          navigateTo={props.navigateTo}
        />
      )}
      {/* {!props.currentUser.isAggregator && (
        <ServiceCard
          background="white" //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #FFA69E -7.1%, #861657 93.85%)',
          color={props.disabled ? "#FFA69E " : "#861657"}
          disabled={props.disabled}
          icon={(iconProps) =>
            payBillIcon({
              color: props.disabled ? "#FFA69E" : "#861657",
              ...iconProps,
            })
          }
          text="Transfer To MMO"
          slug="cash-in"
          style={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
          navigateTo={props.navigateTo}
        />
      )} */}
      {/* Z */}
      {/* (!props.currentUser.isAggregator &&{" "}
      <ServiceCard
        background="white" //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
        color={props.disabled ? "#A2A2A2" : "#EE312A"}
        disabled={props.disabled}
        icon={(iconProps) =>
          cashOutIcon({
            color: props.disabled ? "#A2A2A2" : "#EE312A",
            ...iconProps,
          })
        }
        text="Transfer To MMO"
        slug="transfer to mmo"
        style={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
        navigateTo={props.navigateTo}
      />
      ) */}
      {props.currentUser.isAggregator && (
        <>
          <ServiceCard
            background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
            color={props.disabled ? '#A2A2A2' : '#F5834F'}
            disabled={props.disabled}
            icon={(iconProps) =>
              sendMoneyIcon({
                color: props.disabled ? '#A2A2A2' : '#F5834F',
                ...iconProps,
              })
            }
            text='Distribute'
            slug='distribute'
            style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
            navigateTo={props.navigateTo}
          />
          {process.env.REACT_APP_SHOW_AGGREGATOR && (
            <SetupAgentCard
              background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
              color={props.disabled ? '#A2A2A2' : '#00B8DE'}
              disabled={props.disabled}
              icon={(iconProps) =>
                buyAirtimeIcon({
                  color: props.disabled ? '#A2A2A2' : '#00B8DE',
                  ...iconProps,
                })
              }
              navigateTo={props.navigateTo}
              text='Setup New Agent'
              slug='setup-new-agent'
              style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
            />
          )}
        </>
      )}
    </Col>
  </Row>
);

const mobileServiceCardRow = (props) => (
  <Row style={{ margin: 0, padding: 0 }}>
    {!props.currentUser.isAggregator && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#00B8DE'}
          disabled={props.disabled}
          icon={(iconProps) =>
            buyAirtimeIcon({
              color: props.disabled ? '#A2A2A2' : '#00B8DE',
              ...iconProps,
            })
          }
          text='Sell Airtime & Data'
          size='sm'
          slug='sell-airtime-and-data'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            marginLeft: 0,
            marginRight: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
      </Col>
    )}
    {!props.currentUser.isAggregator && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#F5834F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            sendMoneyIcon({
              color: props.disabled ? '#A2A2A2' : '#F5834F',
              ...iconProps,
            })
          }
          text='Send Money'
          size='sm'
          slug='send-money'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            marginLeft: 0,
            marginRight: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
      </Col>
    )}

    {!props.currentUser.isAggregator && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#9F4FF5'}
          disabled={props.disabled}
          icon={(iconProps) =>
            payBillIcon({
              color: props.disabled ? '#A2A2A2' : '#9F4FF5',
              ...iconProps,
            })
          }
          text='Pay a Bill'
          size='sm'
          slug='pay-a-bill'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            marginLeft: 0,
            marginRight: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
      </Col>
    )}
    {!props.currentUser.isAggregator && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#A5534F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            cashOutIcon({
              color: props.disabled ? '#A2A2A2' : '#A5534F',
              ...iconProps,
            })
          }
          text='Cardless Cashout'
          size='sm'
          slug='cardless-cashout'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            marginLeft: 0,
            marginRight: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
      </Col>
    )}
    {!props.currentUser.isAggregator && SHOW_CARD_LINKING && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#53A54F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            payBillIcon({
              color: props.disabled ? '#A2A2A2' : '#53A54F',
              ...iconProps,
            })
          }
          text='Link Debit Card'
          size='sm'
          slug='link-debit-card'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            margin: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
      </Col>
    )}
    {!props.currentUser.isAggregator && SHOW_OPEN_ACCOUNT && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white'
          color={props.disabled ? '#A2A2A2' : '#A5534F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            payBillIcon({
              color: props.disabled ? '#A2A2A2' : '#A5534F',
              ...iconProps,
            })
          }
          text='Create Account'
          size='sm'
          slug='create-account-form'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            margin: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
      </Col>
    )}
    {/* {SHOW_CASH_IN && (!props.currentUser.isAggregator && <Col xs={6} sm={6} style={{margin: 0, padding: 10}}>
    <ServiceCard
      background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)', 
      color={(props.disabled || SHOW_CASH_IN) ? '#A2A2A2' : '#EE312A'}
      disabled={props.disabled || SHOW_CASH_IN}
      icon={(iconProps) => cashOutIcon({
        color: (props.disabled || SHOW_CASH_IN) ? '#A2A2A2' : '#EE312A',
        ...iconProps
      })}
      text='Cash In'
      size="sm"
      slug='cash-in'
      style={{
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        margin: 0,
        scale: .35,
        height: '90pt'
      }}
      navigateTo={props.navigateTo}
      deviceIsMobile={props.deviceIsMobile}
    />
  </Col>)} */}
    {props.currentUser.isAggregator && (
      <Col xs={6} sm={6} style={{ margin: 0, padding: 10 }}>
        <ServiceCard
          background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
          color={props.disabled ? '#A2A2A2' : '#F5834F'}
          disabled={props.disabled}
          icon={(iconProps) =>
            sendMoneyIcon({
              color: props.disabled ? '#A2A2A2' : '#F5834F',
              ...iconProps,
            })
          }
          text='Distribute'
          size='sm'
          slug='distribute'
          style={{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            marginLeft: 0,
            marginRight: 0,
            scale: 0.35,
            height: '90pt',
          }}
          navigateTo={props.navigateTo}
          deviceIsMobile={props.deviceIsMobile}
        />
        {process.env.REACT_APP_SHOW_AGGREGATOR && (
          <SetupAgentCard
            background='white' //  props.disabled ? 'white' : 'linear-gradient(184.81deg, #83F4FA -7.1%, #00B8DE 93.85%)',
            color={props.disabled ? '#A2A2A2' : '#00B8DE'}
            disabled={props.disabled}
            icon={(iconProps) =>
              buyAirtimeIcon({
                color: props.disabled ? '#A2A2A2' : '#00B8DE',
                ...iconProps,
              })
            }
            text='Setup New Agent'
            size='sm'
            slug='setup-new-agent'
            style={{
              cursor: props.disabled ? 'not-allowed' : 'pointer',
              marginLeft: 0,
              marginRight: 0,
              scale: 0.35,
              height: '90pt',
            }}
            navigateTo={props.navigateTo}
            deviceIsMobile={props.deviceIsMobile}
          />
        )}
      </Col>
    )}
  </Row>
);

export default (props) =>
  props.deviceIsMobile
    ? mobileServiceCardRow(props)
    : desktopServiceCardRow(props);
