import React from "react";

export default class ServiceCard extends React.Component {
  state = {
    isHovered: false,
  };

  render() {
    const { props } = this;
    const {
      background,
      color,
      icon,
      size,
      slug,
      style,
      text,
      navigateTo,
      deviceIsMobile,
    } = props;

    return (
      <div
        id="serviceCard"
        className="grow"
        onMouseEnter={() =>
          this.setState({
            isHovered: true,
          })
        }
        onMouseLeave={() =>
          this.setState({
            isHovered: false,
          })
        }
        style={{
          display: "flex",
          height: size === "sm" ? "140px" : "140px",
          minWidth: size === "sm" ? "none" : "200px",
          background: this.state.isHovered ? color : background,
          backgroundImage: this.state.isHovered
            ? null
            : "linear-gradient(75deg, #eaeaea, transparent)",
          borderRadius: "5px",
          marginLeft: deviceIsMobile ? "10px" : "0px",
          marginRight: deviceIsMobile ? "10px" : "30pt",
          padding: deviceIsMobile ? "10px" : "20px",
          flexDirection: "column",
          justifyContent: "space-between",
          elevation: "60deg",
          boxShadow: "5px 5px 10px rgba(0,0,0,0.12)",
          ...style,
        }}
        onClick={() =>
          props.disabled ? null : navigateTo(`/my-services/${slug}`)
        }
      >
        <div>
          {icon({
            color: this.state.isHovered ? "white" : color,
          })}
        </div>
        <div
          md={12}
          style={{
            color: this.state.isHovered ? "white" : color,
            fontSize: "20px",
          }}
        >
          {text}
        </div>
      </div>
    );
  }
}
