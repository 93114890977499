import Requester from "../finch-requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class UserManagement {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getDomainByCode(code) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/domains/get",
      args: {
        code,
      },
    });
  }

  getPosModel() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/pos-stocks/pos-models",
    });
  }
  getDeploymentFee() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/pos-request/deployment_fee",
    });
  }

  validatePosRequest(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/pos-request/validate",
      body: payload,
    });
  }
  validateAggregatorPosRequest(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/pos-request/aggregator/validate",
      body: payload,
    });
  }
  submitPosRequest(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/pos-request",
      body: payload,
    });
  }
  submitAggregatorPosRequest(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/pos-request/aggregator",
      body: payload,
    });
  }

  getDomains() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/domains",
    });
  }

  createDomains(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/domains",
      body: payload,
    });
  }

  updateDomains(domainId, payload) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/domains/${domainId}`,
      body: payload,
    });
  }

  getPermissions(pageNo, pageSize) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/permissions",
      args: {
        pageNum: pageNo,
        pageSize,
      },
    });
  }

  createPermission(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/permissions",
      body: payload,
    });
  }

  updatePermission(permissionId, payload) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/permissions/${permissionId}`,
      body: payload,
    });
  }

  getRoles() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/roles",
    });
  }

  createRole(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/roles",
      body: payload,
    });
  }

  updateRole(roleId, payload) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/roles/${roleId}`,
      body: payload,
    });
  }

  getRole(roleId) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/roles/${roleId}`,
    });
  }

  deleteRole(roleId) {
    return this.apiRequester.delete({
      endpoint: `v1/finch/user-mgmt/roles/${roleId}`,
    });
  }

  searchRole(args) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/roles/search",
      args,
    });
  }

  assignRolePermissions(payload) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/roles/permission",
      body: payload,
    });
  }

  getRolePermissions(roleId) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/roles/${roleId}/permissions`,
    });
  }

  deleteRolePermissions(roleId, permissionIds) {
    return this.apiRequester.delete({
      endpoint: "v1/finch/user-mgmt/roles/permission",
      body: {
        roleId,
        permissionIds,
      },
    });
  }

  getHealthEndpoint() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/health",
    });
  }

  getUserByUsername(username) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/users",
      args: {
        username,
      },
    });
  }

  getUsers() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/users/list",
    });
  }

  searchUsers(args) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/users/search",
      args,
    });
  }

  createUser(payload) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/users",
      payload,
    });
  }

  updateUser(userId, payload) {
    return this.apiRequester.post({
      endpoint: `v1/finch/user-mgmt/users/${userId}`,
      payload,
    });
  }

  getUserRoles(username) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/users/${username}/roles`,
    });
  }

  addUserRoles(userId, roleIds) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/users/role`,
      payload: {
        userId,
        roleIds,
      },
    });
  }

  deleteUserRole(userId, roleIds) {
    return this.apiRequester.delete({
      endpoint: `v1/finch/user-mgmt/users/role`,
      payload: {
        userId,
        roleIds,
      },
    });
  }

  deviceAuth(otp, tokenId, device, registerDevice, headers) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/oauth/authenticate/otp",
      body: {
        authenticateOtpRequest: {
          otp,
          tokenId,
        },
        device,
        registerDevice: JSON.stringify(registerDevice),
      },
      headers,
    });
  }

  getUser(username) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/auth/users/getUser",
      headers: {
        username,
      },
    });
  }

  login(username, password, device, domainTypeId) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/oauth/authenticate-user",
      body: {
        username,
        password,
        device,
        domainTypeId,
      },
    });
  }

  getUserProfile(username) {
    return this.apiRequester.get({
      endpoint: `v2/finch/user-mgmt/auth/users`,
      headers: {
        username,
      },
    });
  }

  recoverPassword(username, destinationUri, resetWith) {
    return this.apiRequester.post({
      endpoint: `v2/finch/user-mgmt/auth/users/recover-password`,
      body: {
        username,
        destinationUri,
        resetWith,
      },
    });
  }

  resetPassword(password, confirmPassword, uuid, username, userRef, otp) {
    return this.apiRequester.post({
      endpoint: `v2/finch/user-mgmt/auth/users/reset-password`,
      body: {
        password,
        confirmPassword,
        uuid,
        username,
        userRef,
        otp,
      },
    });
  }

  resetPasswordPhone(password, confirmPassword, uuid, otp) {
    return this.apiRequester.post({
      endpoint: `v2/finch/user-mgmt/auth/users/reset-password`,
      body: {
        password,
        confirmPassword,
        uuid,
        otp,
      },
    });
  }

  activateDevice(deviceId, otp, verifyOtp) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/device/activate",
      headers: {},
      body: {
        deviceId: deviceId,
        otp,
        verifyOtp,
      },
    });
  }

  deactivateDevice(deviceId, otp, verifyOtp) {
    return this.apiRequester.post({
      endpoint: "v1/finch/user-mgmt/device/deactivate",
      headers: {},
      body: {
        deviceId: deviceId,
        otp,
        verifyOtp,
      },
    });
  }

  deleteDevice(deviceId, otp, verifyOtp) {
    return this.apiRequester.delete({
      endpoint: "v1/finch/user-mgmt/device/delete",
      headers: {},
      body: {
        deviceId: deviceId,
        otp,
        verifyOtp,
      },
    });
  }

  getDeviceList(authToken) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/device/deviceList",
      headers: {
        // accessToken: authToken,
      },
    });
  }

  regenerateToken(deviceUuid, tokenId, headers) {
    return this.apiRequester.get({
      endpoint: "v2/finch/user-mgmt/auth/users/generateToken",
      headers: {
        deviceUuid,
        tokenId,
        ...headers,
      },
    });
  }

  getAuthenticatedUserDetails() {
    return this.apiRequester.get({
      endpoint: "v2/finch/user-mgmt/users/me",
    });
  }

  refreshAccessToken(refreshToken, username) {
    return this.apiRequester.get({
      endpoint: "v2/finch/user-mgmt/auth/users/refreshUserToken",
      headers: {
        refreshToken,
        username,
      },
      auth: false,
    });
  }

  validateUserOnPassport(emailOrPhone) {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/auth/users/validate-passport-user",
      headers: {
        userNameOrMobile: emailOrPhone,
      },
    });
  }

  signupUser(data, sendOtp, verifyOtp) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/signUp-new-user",
      body: data,
      headers: {
        sendOtp: sendOtp,
        verifyOtp: verifyOtp,
      },
    });
  }

  resendOtp(mobileNo) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/send-otp",
      body: {
        mobileNo,
      },
    });
  }

  signupGuestUser(data, sendOtp, verifyOtp) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/signUp-existing-user",
      body: data,
      headers: {
        sendOtp: sendOtp,
        verifyOtp: verifyOtp,
      },
    });
  }

  verifyOtp(mobileNo, otp) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/verify-otp",
      body: {
        mobileNo,
        otp,
      },
    });
  }

  resendEmail(id) {
    return this.apiRequester.get({
      endpoint: "v2/finch/user-mgmt/auth/users/resendEmail-confirmation",
      args: {
        id,
      },
    });
  }

  changePassword(oldPassword, password, confirmPassword, accessToken) {
    return this.apiRequester.post({
      endpoint: "v2/finch/user-mgmt/auth/users/change-password",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: {
        oldPassword,
        password,
        confirmPassword,
      },
    });
  }

  postAgregratorPosRequest(payload) {
    return this.apiRequester.post({
      endpoint: `v1/finch/user-mgmt/pos-remap-request`,
      body: {
        currentTerminalOwner: payload.currentTerminalOwner,
        deviceId: payload.deviceId,
        nextOwnerBusinessAddress: payload.nextOwnerBusinessAddress,
        nextOwnerState: payload.nextOwnerState,
        nextTerminalOwner: payload.nextTerminalOwner,
        remapRequestType: payload.remapRequestType,
        reasonForRemaping: payload.reasonForRemaping,
        terminalName: payload.terminalName,
        unitPrice: payload.unitPrice,
      },
    });
  }
  getAgregratorAgentByTerminalId(terminalId) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/device/terminal_id`,
      headers: {
        terminalId: terminalId,
      },
    });
  }
  getAllPosRemapReqest(pageSize = null, pageNum = null) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-remap-request/search`,
      args: {
        pageNum,
        pageSize,
      },
    });
  }
  putPosRemapRequestConfirmation(id) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/pos-remap-request/${id}`,
    });
  }
  putPosRequestConfirmation(posRequestId) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/pos-request/delivery/by-request-stock-id?posRequestStockId=${posRequestId}`,
    });
  }
  postConcurDelivery(RequestId, payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch/user-mgmt/pos-request/confirm-delivery?posRequestId=${RequestId}`,
      body: payload,
    });
  }

  putConfirmPosRequest(posRequestId) {
    return this.apiRequester.put({
      endpoint: `v1/finch/user-mgmt/pos-request/deliver/by-request-id?posRequestId=${posRequestId}`,
    });
  }

  getPosWorkflowDetails(posRequestId) {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-request?posRequestId=${posRequestId}`,
    });
  }
  getAggregatorRequest() {
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/pos-request/aggregator/pos_summary",
    });
  }

  getAggregatorStatus(data) {
    
    return this.apiRequester.get({
      endpoint: "v1/finch/user-mgmt/pos-request/aggregator/requests",
      args: data,
    });
  }
  getPosRequestDetails(posRequestId) {
    return this.apiRequester.get({
      endpoint: `v2/finch/user-mgmt/pos-request/awaiting_delivery?posRequestId=${posRequestId}`,
    });
  }

  getActivePosRequests() {
    return this.apiRequester.get({
      endpoint: `v1/finch/user-mgmt/pos-request/active`,
    });
  }
  validatePosTerminal(terminalId) {
    return this.apiRequester.post({
      endpoint: `v2/finch/user-mgmt/pos-request/validate-terminalId?terminalId=${terminalId}`,
    });
  }

  logout(token) {
    return this.apiRequester.delete({
      endpoint: `v2/finch/user-mgmt/auth/users/logout`,
      headers: {
        accessToken: token,
      },
    });
  }
}
