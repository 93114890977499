import React from "react";
import { Form } from "react-bootstrap";
import MdSync from "react-ionicons/lib/MdSync";

const COLOUR = "grey";
const InputText = ({
  label,
  placeholder,
  value,
  maxLength,
  onChange,
  labelColor,
  disabled = false,
  type = "text",
  border,
  loading,
  handlePaste,
  handleCopy,
  onBlur,
  flex,
  name,
}) => {
  return (
    <div
      style={{
        flex: flex,
      }}
    >
      <Form.Group
        style={{
          position: "relative",
        }}
        className="p-0"
        controlId="exampleForm.ControlInput1"
      >
        <Form.Label
          style={{
            color: labelColor,

            fontSize: "14px",
          }}
        >
          {label}{" "}
        </Form.Label>
        <Form.Control
          style={{
            border: border,
          }}
          name={name}
          onBlur={onBlur}
          onCopy={handleCopy}
          onPaste={handlePaste}
          onChange={onChange}
          maxLength={maxLength}
          disabled={disabled}
          value={value}
          required
          autoComplete="off"
          type={type}
          placeholder={placeholder}
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
            }}
          >
            <MdSync
              color={COLOUR}
              fontSize="20pt"
              rotate={true}
              // rotate={loading}
              style={{
                marginRight: "5pt",
              }}
            />
          </div>
        )}
      </Form.Group>
    </div>
  );
};

export default InputText;
