import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { ArrowDownIcon, ArrowUpIcon } from "../../../../icons";

const PreviewAccordion = ({
  children,
  title,
  index,
  application = false,
  onClick,
  showAccordion,
}) => {
  return (
    <div>
      <Accordion defaultActiveKey={index} onClick={onClick}>
        <div
          style={{
            borderRadius: application ? "0" : "10px",
            border: application ? "none" : "1px solid #01010117",

            marginBottom: "1rem",
          }}
        >
          <Accordion.Toggle
            style={{
              cursor: "pointer",
              color: process.env.REACT_APP_BLUE_COLOUR,
              fontWeight: "600",
              padding: "15px 20px",
              backgroundColor: application && "white",
              borderBottom: application ? "2px solid #01010117" : "",
              outlineBottom: application ? "2px solid #01010117" : "",
            }}
            as={"div"}
            eventKey="1"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {title}
              {showAccordion ? (
                <div>{ArrowUpIcon()}</div>
              ) : (
                <div>{ArrowDownIcon()}</div>
              )}
              {/* {!showAccordion? <div>{ArrowUpIcon()}</div>:<div>{ArrowDownIcon()}</div>} */}
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body
              style={{
                borderRadius: "10px",
                border: application ? "none" : "1px solid #eef2f4ab",
              }}
            >
              {children}
            </Card.Body>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

export default PreviewAccordion;
