/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { notify,firetoast } from "../../../../components/extras";
import H1 from "../../../../components/h1";
import HeaderSignup from "../../../../components/header-signup";
import VerticallyCenteredModal from "../../../../components/vertically-centered-modal";
import VerticallyCenteredModalTerms from "../../../../components/vertically-centered-modal-terms";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { InfoIcon2, greenCheckIcon } from "../../../../icons";
import UserManagement from "../../../../services/api/resources/user-management";
import Onboarding from "../../../../services/api/resources/onboarding";
import PrivacyPolicy from "../../../privacy-policy";
import TermsAndConditions from "../../../terms-and-conditions";
import { isBvnValid, stripNonNumbers } from "../component/extras";
import InputText from "../component/input";
import OtpInput from "../component/otpInput";
import Stepper from "../component/stepper";
import {
  formatPhoneNumber,
} from "../../../../utils/formatters";

const BvnValidationScene = ({ setScreen, application }) => {
  const history = useHistory();
  const onboarding = new Onboarding();
  const userManagement = new UserManagement();
  const [showModal, setShowModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [otp, setOtp] = useState("");
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [bvnDetailsForm, setBvnDetailsForm] = useState({
    bvn: application ? application?.applicantDetails?.bvnDetails?.bvn : "",
  });
  const [bvnDetails, setBvnDetails] = useState([])
  const [error, setError] = useState(false)



  const confirmBVN = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowOtpModal(true);
    }, 1000);
    if (bvnDetailsForm.bvn) {
        const { status, response } = await onboarding.bvnConfirmation(bvnDetailsForm.bvn);

        setIsLoading(false);
        if (status === ERROR_STATUS) {
            firetoast("error", `${response.description}`, "");

        } else if (status === SUCCESS_STATUS) {
            if (response?.code === "20000") {
                notify("success");
                localStorage.setItem("bvnInformation", JSON.stringify(response));
            } else {
                setBvnDetails(response)
                setError(true)
            }

        }
    }
};

  useEffect(() => {
    const updatedBvnDetailsForm = JSON.parse(
      localStorage.getItem("bvnDetailsForm")
    );

    if (updatedBvnDetailsForm) {
      setBvnDetailsForm({
        bvn:
          application?.applicantDetails?.bvnDetails?.bvn ||
          updatedBvnDetailsForm.bvn ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "bvnDetailsForm",
      JSON.stringify(bvnDetailsForm)
    );
  }, [bvnDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...bvnDetailsForm,
      ...params,
    };

    setBvnDetailsForm(newForm);
  };


  function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);


    setTimeout(() => {
      setIsLoading(false);
      setShowOtpModal(true);
    }, 1000);
  };

  const navigateToFaceVerification = () => {
    setScreen(3); 
    setShowModal(false);
    setShowOtpModal(false);
  };

  const isFormValid = () => {
    return bvnDetailsForm.bvn && isBvnValid(bvnDetailsForm);
  };

  const textStyle = {
    color: "#1F2937",
    fontWeight: "500",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
  };

  const spanStyle = {
    display: "inline-flex",
    alignItems: "center",
  };

  const pStyle = {
    marginLeft: "10px",
    marginTop: "12px",
  };

  const iconStyle = {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const instructions = [
    "Ensure you are in a well-lit area",
    "Make sure you are in front of a plain background",
    "Make sure to remove hats, thick glasses, or anything else that might obscure your face",
    "Make sure you keep your expressions neutral",
  ];

  const callResendOtp = async () => {
    setIsLoading(true);

    const responseObj = await userManagement.resendOtp(
      formatPhoneNumber('08161564659')
    );
    const { status, response } = responseObj;
    const newResponse = response.toString().replace(/[, ]+/g, "").trim();
    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${newResponse.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("OTP successfully sent!", "success");

      setScreen(3);
    }
  };

  const showFaceIdModalWithDelay = () => {
    setTimeout(() => {
      setShowModal(true);
    }, 5000);
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    showFaceIdModalWithDelay();
    const responseObj = await userManagement.signupUser(
      otp,
      false,
      true
    );
    const { status, response } = responseObj;
    setLoading(false);
    if (status === ERROR_STATUS) {
      notify(`${response.description}`, "error");
    } else if (status === SUCCESS_STATUS) {
      // navigateToNinVerification();
    }
  };

  const checkAuthentication = () => {
    const isAuthenticated = localStorage.getItem("auth_token") === null;

    if (!isAuthenticated) {
      history.push("/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const debouncedHandleOtpSubmit = useCallback(
    debounce(handleOtpSubmit, 1000),
    [tokenId]
  );

  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <Col style={{ marginBottom: "20px", margin: "0 auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>

          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
              color: "#1F2937"
            }}
          >
            Enter your BVN
          </H1>
          <p style={{ marginBottom: "20px", color:'#848F9F' }}>
            Dial *565*0# to securely get your BVN from your network provider.
          </p>

          <form onSubmit={handleSubmit}>
            <InputText
            style={{backgroundColor: '#F3F5F6',}}
              label={"BVN"}
              labelColor={
                !bvnDetailsForm.bvn || isBvnValid(bvnDetailsForm)
                  ? "#353F50"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !bvnDetailsForm.bvn || isBvnValid(bvnDetailsForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              placeholder={"Enter BVN"}
              onChange={(e) => {
                updateFormField({
                  bvn: stripNonNumbers(e.target.value.trim()),
                });
              }}
              maxLength={11}
              value={bvnDetailsForm.bvn}
            />

            <p style={{ marginBottom: "20px", color:'#848F9F' }}>
              By signing up, you agree to our{" "}
              <a
                onClick={() => setShowTermsAndConditions(true)}
                target="_blank"
                style={{ color: "rgba(0, 184, 222, 1)", cursor: "pointer" }}
              >
                Terms of Use{" "}
              </a>
              and
              <a
                onClick={() => setShowPrivacyPolicy(true)}
                target="_blank"
                style={{ color: "rgba(0, 184, 222, 1)", cursor: "pointer" }}
              >
                {" Privacy Policy. "}
              </a>
            </p>

            <button
              disabled={!isFormValid() || isLoading}
              onClick={confirmBVN}
              className="signupButton2"
              style={{
                marginTop: "1rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {isLoading ? "Processing..." : "Next"}
            </button>

            <p
              style={{
                marginBottom: "10px",
                textAlign: "center",
                paddingTop: "20px",
                color:'#848F9F'
              }}
            >
              Already have an account?{" "}
              <a href="/login" target="_blank">
                {localStorage.getItem("currentApplication") ? (
                  <Link style={{ color: "rgba(0, 184, 222, 1)" }} to="/logout">
                    Logout{" "}
                  </Link>
                ) : (
                  <Link style={{ color: "rgba(0, 184, 222, 1)" }} to="/login">
                    Log in
                  </Link>
                )}
              </a>
            </p>
          </form>
        </Col>
      </main>
      <VerticallyCenteredModalTerms
        onHide={() => setShowTermsAndConditions(false)}
        title="Quickteller Paypoint Terms and Conditions"
        body={<TermsAndConditions />}
        show={showTermsAndConditions}
      />

      <VerticallyCenteredModal
        onHide={() => setShowPrivacyPolicy(false)}
        title="Privacy Policy"
        body={<PrivacyPolicy />}
        show={showPrivacyPolicy}
      />

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          paddingTop: "6rem",
        }}
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <div style={{ alignContent: "center", padding: "1rem" }}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Face ID Verification
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#1F2937",
              }}
            >
              Ensure your camera is steady and your face fits into the shape.
            </p>
            <div style={textStyle}>
              {instructions.map((instruction, index) => (
                <span key={index} style={spanStyle}>
                  <div style={iconStyle}>{greenCheckIcon()}</div>
                  <p style={pStyle}>{instruction}</p>
                </span>
              ))}
            </div>

            <button
              onClick={navigateToFaceVerification}
              className="signupButton2"
              style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
            >
              {isLoading ? "Processing..." : "Next"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        closeButton
        show={showOtpModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          backdropFilter: "blur(8px)",
          backgroundColor: "rgba(16, 24, 40, 0.3)",
          paddingTop: "6rem",
        }}
      >
        <Modal.Body style={{ padding: "3rem" }}>
          <div style={{ alignContent: "center" }}>
            <form className="otp-conatiner">
              <div style={{ fontSize: "20px", fontWeight: 700 }}>Enter OTP</div>
              <p style={{ fontSize: "14px", color: "grey" }}>
                An OTP was sent to your BVN Phone number 08078****54. Kindly
                enter it below
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "7px",
                  alignItems: "center",
                  marginBottom: "-15px",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  length={5}
                  onComplete={debouncedHandleOtpSubmit}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                {isLoadingOtp ? (
                  <IosRefresh fontSize="20px" color="grey" rotate={true} />
                ) : (
                  <span
                    style={{ cursor: "pointer", marginTop: "20px" }}
                    className="otp-resend"
                    onClick={callResendOtp}
                  >
                    Resend OTP
                  </span>
                )}
              </div>

              <div>
                {!true && (
                  <>
                    <InfoIcon2 fontSize="20px" color="#DC4437" />

                    <span
                      style={{ marginTop: "20px", marginLeft:'10px', color: "#DC4437" }}
                      className="otp-resend"
                    >
                      OTP is incorrect
                    </span>
                  </>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ onboarding, application }) => ({
  onboarding,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  // setScreen: (screen) => dispatch(navigateTo(screen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BvnValidationScene);
