import qs from "qs";
import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "react-table/react-table.css";

import MdSync from "react-ionicons/lib/MdSync";
import ActivityIndicator from "../../../../components/activity-indicator";
import DateInputButton from "../../../../components/date-inputs/button";
import Text from "../../../../components/text";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_STATUS,
} from "../../../../constants/api";
import { successfulTransactions } from "../../../../constants/transactions";
import {
  ServiceType,
  StatusCode,
  posReportTableOptions,
} from "../../../../fixtures/report_filter_options";
import { SampleTransactions } from "../../../../fixtures/transactions";
import UserSerializer from "../../../../serializers/resources/user";
import Platform from "../../../../services/api/resources/platform";
import PosRequest from "../../../../services/api/resources/pos-request-automation";
import TransactionHistory from "../../../../services/api/resources/transaction-history";
import { convertNgkToNgn } from "../../../../utils/converters/currencies";
import {
  calculatePercentage,
  findArrayIndexWithMaximumLength,
  formatAmount,
  formatDate,
  formatEmailOrPhone,
  handleLongNumber,
  handleStatusFormat,
  handleTransactionTypeFormat,
  percentageIncreaseOrDecrease,
  refineGraphData,
  refineGraphDataForDate,
  specialDateRangeFormatter_EndDate,
  specialDateRangeFormatter_StartDate,
} from "../../../../utils/formatters";
import Transaction from "../../../../utils/mocks/transaction";
import Wallet from "../../../../utils/mocks/wallet";
import BaseScene from "../../../base-scene";
import ReportsBarChart from "./charts/reports-bar-chart";
import ReportNav from "./report-nav";
import "./styles.scss";
import PosRemapTable from "./tables/pos-remap-report";
import PosRequestTable from "./tables/pos-request-report";
import TransactionsReportTable from "./tables/transactions-report";
import RegistrationScreen from "./registrationReport/registrationReportScreen";

export default class ReportScene extends BaseScene {
  location = window.location.href;
  path = "/reports";
  title = "Reports";

  transaction = new Transaction({
    wallet: new Wallet(),
  });

  transactionHistory = new TransactionHistory();
  platform = new Platform();

  posRequest = new PosRequest();

  TodaysDate = new Date();
  OneMonthsBefore = new Date(
    this.TodaysDate.getFullYear(),
    this.TodaysDate.getMonth() - 1
  );

  constructor(props) {
    super(props);

    const lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);

    const firstDayCurrentMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth(),
      1
    );
    const lastDayCurrentMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth() + 1,
      0
    );

    const firstDayPrevMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth() - 1,
      1
    );
    const lastDayPrevMonth = new Date(
      this.TodaysDate.getFullYear(),
      this.TodaysDate.getMonth(),
      0
    );

    this.state = {
      endDate: this.TodaysDate,
      startDate: this.OneMonthsBefore,
      reportsGraphEndDate: this.TodaysDate,
      reportsGraphStartDate: lastWeekDate,
      firstDayCurrentMonth,
      lastDayCurrentMonth,
      firstDayPrevMonth,
      lastDayPrevMonth,
      transactionHistory: this.transaction.history,
      sampleTransactions: [],
      filteredTransactions: [],
      filteredTransactions2: SampleTransactions,
      searchTerm: "",
      selectedOption: { label: "Transaction Type ", value: "0" },
      statusOption: { label: "Transaction Status", value: "0" },
      agentList: { label: "Select Agent", value: "0" },
      pageSize: 50,
      pageNum: 1,
      pageIndex: 1,
      isLoading: false,
      didErrorOccurWhileFetchingAllTransactions: false,
      didErrorOccurWhileFetchingAllPOSRequests: false,
      didErrorOccurWhileFetchingAllPOSRemap: false,
      deviceIsMobile:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 576,
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
      deviceIsTab:
        qs.parse(window.location.search)["?mobile"] || window.innerWidth <= 992,
      //showTransactionReportComponent:true,
      posReportData: [],
      posRemapReport: [],
      //showPosRequestOrRemap:{value:0 , label:'Select POS Report'},
      showPosRequestOrRemap: { value: 1, label: "POS Requests" },
      isTransactionReportComponent: false,
      isPosReportComponent: true,
      isRegistrationReportComponent: false,
      showServiceCategories: true,
    };

    this.getTransactionsByType = this.getTransactionsByType.bind(this);
    this.filterTransactionsByType = this.filterTransactionsByType.bind(this);
    this.getTransactionsByStatus = this.getTransactionsByStatus.bind(this);
    this.filterTransactionsByStatus =
      this.filterTransactionsByStatus.bind(this);
    this.getTransactionsByDateRange =
      this.getTransactionsByDateRange.bind(this);
    this.getGraphTransactionsData = this.getGraphTransactionsData.bind(this);
    this.getMonthlyGraphTransactionsData =
      this.getMonthlyGraphTransactionsData.bind(this);
    this.getPrevMonthGraphTransactionsData =
      this.getPrevMonthGraphTransactionsData.bind(this);
    this.filterTransactionsByAgent = this.filterTransactionsByAgent.bind(this);
    this.showPosReportTable = this.showPosReportTable.bind(this);
    this.showTransactionReportTable =
      this.showTransactionReportTable.bind(this);
    this.getPosRequestData = this.getPosRequestData.bind(this);
    this.showRegistrationReportTable =
      this.showRegistrationReportTable.bind(this);
    this.getPosRemapReport = this.getPosRemapReport.bind(this);
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    const userSerializer = new UserSerializer(currentUser);

    userSerializer && userSerializer.isAggregator && this.getAgents();

    this.setState({
      userSerializer: userSerializer,
    });
  }

  componentWillMount() {
    // this.getAllTransactions()

    if (this.state.isTransactionReportComponent) {
      this.getTransactionsByDateRange(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedOption.value,
        this.state.statusOption.value,
        this.state.pageSize,
        this.state.pageNum,
        this.state.domainCode
      );
      !process.env.REACT_APP_SHOW_SERVICES_CHARTS &&
        this.getGraphTransactionsData(
          this.state.reportsGraphStartDate,
          this.state.reportsGraphEndDate
        );
      !process.env.REACT_APP_SHOW_SERVICES_CHARTS &&
        this.getMonthlyGraphTransactionsData(
          this.state.firstDayCurrentMonth,
          this.state.lastDayCurrentMonth
        );
      !process.env.REACT_APP_SHOW_SERVICES_CHARTS &&
        this.getPrevMonthGraphTransactionsData(
          this.state.firstDayPrevMonth,
          this.state.lastDayPrevMonth
        );
    }
  }

  showTransactionReportTable() {
    this.setState({
      isTransactionReportComponent: true,
      isPosReportComponent: false,
      isRegistrationReportComponent: false,
    });
    // this.props.showTransactionReportComponent(true);

    this.getTransactionsByDateRange(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedOption.value,
      this.state.statusOption.value,
      this.state.pageSize,
      this.state.pageNum,
      this.state.domainCode
    );
  }

  showPosReportTable() {
    this.setState({
      isTransactionReportComponent: false,
      isPosReportComponent: true,
      isRegistrationReportComponent: false,
    });
    // this.props.showTransactionReportComponent(true);

    //call the backend service
    this.getPosRequestData(this.state.pageSize, this.state.pageNum);
  }
  showRegistrationReportTable() {
    this.setState({
      isTransactionReportComponent: false,
      isPosReportComponent: false,
      isRegistrationReportComponent: true,
    });
    // this.props.showRegistrationReportComponent(true);

    //call the backend service
    // this.getPosRequestData(this.state.pageSize, this.state.pageNum);
  }

  handleSelections(event) {
    const val = event;

    this.setState({
      showPosRequestOrRemap: val,
    });

    if (val.value === 1) {
      //call fetch pos requests
      this.getPosRequestData(this.state.pageSize, this.state.pageNum);
    } else if (val.value === 2) {
      //call fetch pos remaps
      this.getPosRemapReport(this.state.pageSize, this.state.pageNum);
    }
  }
  handleEmptyResponse = (code) => {
    this.setState({
      sampleTransactions: [],
      filteredTransactions: [],
      pages: 1,
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileFetchingAllTransactions: true,
      });
    }
  };

  handleGetAgentsEmptyResponse = (code) => {
    this.setState({
      agents: [],
      isLoading: false,
    });
    if (code === HTTP_NOT_FOUND_CODE) {
      this.setState({
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.setState({
        didErrorOccurWhileGettingAgents: true,
      });
    }
  };

  async getPosRemapReport(pageSize, pageNum) {
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
    });

    const { status, response, code } = await this.posRequest.getPosRemaps(
      pageSize,
      pageNum
    );
    if (status === SUCCESS_STATUS) {
      const posRequestResponse = response.data;
      // const filteredResponse = transactionResponse.filter((response) => {
      //     return response.statusCode !== 'INITIATED'
      // })
      this.setState({
        posRemapReport: posRequestResponse,
        isLoading: false,
        pages: Math.ceil(response.count / this.state.pageSize),
        didErrorOccurWhileFetchingAllPOSRemap: false,
      });
    } else {
      //alert("response:" + response +"  status:"+ status +"  code:" + code );
      this.setState({
        posRemapReport: [],
        pages: 1,
        isLoading: false,
      });
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          didErrorOccurWhileFetchingAllPOSRemap: false,
        });
      } else {
        this.setState({
          didErrorOccurWhileFetchingAllPOSRemap: true,
        });
      }
    }
  }

  //TODO: look into this
  async getAgents() {
    //noPaging & noDetails
    const { status, response, code } = await this.platform.searchAgents(
      null,
      null,
      null,
      true,
      true
    );
    const agentSample = response.content;

    if (status === SUCCESS_STATUS) {
      const transformAgent = (agentSample) => {
        const newAgentArray = agentSample.map(
          ({ businessName: label, businessName: value, ...rest }) => ({
            label,
            value,
            ...rest,
          })
        );
        const selfObject = {
          agentCode: this.state.userSerializer.domainCode,
          value: this.state.userSerializer.domainCode,
          label: "Self",
        };
        const modifiedAgentArray = [...newAgentArray, selfObject];
        return modifiedAgentArray;
      };

      this.setState({
        agents: transformAgent(agentSample),
        didErrorOccurWhileGettingAgents: false,
      });
    } else {
      this.handleGetAgentsEmptyResponse(code);
    }
  }

  async getTransactionsByDateRange(
    startDate,
    endDate,
    selectedOption,
    statusOption,
    pageSize,
    pageNum,
    domainCode
  ) {
    const transactionStartDate = specialDateRangeFormatter_StartDate(startDate);
    const transactionEndDate = specialDateRangeFormatter_EndDate(endDate);
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
    });

    domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      domainCode !== "0"
        ? domainCode
        : null;

    const { status, response, code } =
      await this.transactionHistory.getTableTransactionsByDateRange(
        transactionStartDate,
        transactionEndDate,
        selectedOption,
        statusOption,
        pageSize,
        pageNum,
        domainCode
      );
    if (status === SUCCESS_STATUS) {
      const transactionResponse = response.content;
      const filteredResponse = transactionResponse.filter((response) => {
        return response.statusCode !== "INITIATED";
      });

      this.setState({
        sampleTransactions: filteredResponse,
        filteredTransactions: filteredResponse,
        isLoading: false,
        pages: Math.ceil(response.count / this.state.pageSize),
        dataCount: response.count / this.state.pageSize,
        didErrorOccurWhileFetchingAllTransactions: false,
      });
    } else {
      this.handleEmptyResponse(code);
    }
  }

  async getPosRequestData(pageSize, pageNum) {
    this.setState({
      isLoading: true,
      pageNum,
      pageSize,
    });

    const { status, response, code } = await this.posRequest.getPosRequests(
      pageSize,
      pageNum
    );
    if (status === SUCCESS_STATUS) {
      const posRequestResponse = response.data;
      // const filteredResponse = transactionResponse.filter((response) => {
      //     return response.statusCode !== 'INITIATED'
      // })
      this.setState({
        posReportData: posRequestResponse,
        isLoading: false,
        pages: Math.ceil(response.count / this.state.pageSize),
        didErrorOccurWhileFetchingAllPOSRequests: false,
      });
    } else {
      this.setState({
        posReportData: [],
        pages: 1,
        isLoading: false,
      });
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          didErrorOccurWhileFetchingAllPOSRequests: false,
        });
      } else {
        this.setState({
          didErrorOccurWhileFetchingAllPOSRequests: true,
        });
      }
    }
  }

  async filterTransactionsByAgent(event) {
    const domainCode = event.agentCode;
    this.setState({
      domainCode,
      agentList: event,
    });

    await this.getTransactionsByDateRange(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedOption.value,
      this.state.statusOption.value,
      this.state.pageSize,
      1,
      domainCode
    );
  }

  async filterTransactionsByType(selectedOption) {
    this.setState({
      selectedOption,
      pageNum: 1,
    });
    await this.getTransactionsByType(
      selectedOption.value,
      this.state.pageSize,
      1
    );
  }

  async getTransactionsByType(type, pageSize, pageNum) {
    this.setState({
      isLoading: true,
      pageSize,
      pageNum,
    });
    const startDate = specialDateRangeFormatter_StartDate(this.state.startDate);
    const endDate = specialDateRangeFormatter_EndDate(this.state.endDate);

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    const { status, response, code } =
      await this.transactionHistory.getTableTransactionsByType(
        startDate,
        endDate,
        type,
        this.state.statusOption.value,
        pageSize,
        pageNum,
        domainCode
      );
    if (status === SUCCESS_STATUS) {
      const transactionResponse = response.content;
      const filteredResponse = transactionResponse.filter((response) => {
        return response.statusCode !== "INITIATED";
      });
      this.setState({
        sampleTransactions: filteredResponse,
        filteredTransactions: filteredResponse,
        isLoading: false,
        pages: Math.ceil(response.count / this.state.pageSize),
      });
    } else {
      this.handleEmptyResponse(code);
    }
  }

  async getTransactionsByStatus(statusCode, pageSize, pageNum) {
    this.setState({
      isLoading: true,
      pageSize,
      pageNum,
    });
    const startDate = specialDateRangeFormatter_StartDate(this.state.startDate);
    const endDate = specialDateRangeFormatter_EndDate(this.state.endDate);

    const domainCode =
      this.state.userSerializer &&
      this.state.userSerializer.isAggregator &&
      this.state.domainCode !== "0"
        ? this.state.domainCode
        : null;

    const { status, response, code } =
      await this.transactionHistory.getTableTransactionsByStatus(
        startDate,
        endDate,
        statusCode,
        this.state.selectedOption.value,
        pageSize,
        pageNum,
        domainCode
      );
    if (status === SUCCESS_STATUS) {
      const transactionResponse = response.content;
      const filteredResponse = transactionResponse.filter((response) => {
        return response.statusCode !== "INITIATED";
      });
      this.setState({
        sampleTransactions: filteredResponse,
        filteredTransactions: filteredResponse,
        isLoading: false,
        pages: Math.ceil(response.count / this.state.pageSize),
      });
    } else {
      this.handleEmptyResponse(code);
    }
  }

  async filterTransactionsByStatus(statusOption) {
    this.setState({
      statusOption,
    });
    await this.getTransactionsByStatus(
      statusOption.value,
      this.state.pageSize,
      1
    );
  }

  handleSearch = (event) => {
    const { sampleTransactions } = this.state;

    let search = event.target.value;
    let initialTransactions = sampleTransactions;

    if (search !== "") {
      initialTransactions = initialTransactions.filter((transactions) => {
        return transactions.transactionRef.toString().match(search);
      });
    } else {
      initialTransactions = sampleTransactions;
    }

    this.setState({
      searchTerm: search,
      filteredTransactions: initialTransactions,
    });
  };

  async getGraphTransactionsData(startDate, endDate) {
    const graphStartDate = specialDateRangeFormatter_StartDate(startDate);
    const graphEndDate = specialDateRangeFormatter_EndDate(endDate);
    this.setState({
      isLoadingServicesReport: true,
    });

    const { response, status, code } =
      await this.transactionHistory.getGraphTransactions(
        graphStartDate,
        graphEndDate,
        null,
        successfulTransactions
      );
    if (status === ERROR_STATUS) {
      if (code === HTTP_NOT_FOUND_CODE) {
        this.setState({
          isLoadingServicesReport: false,
          graphData: null,
          didErrorOccurWhileFetchingServicesReport: false,
        });
      } else {
        this.setState({
          isLoadingServicesReport: false,
          didErrorOccurWhileFetchingServicesReport: true,
        });
      }
    } else {
      this.setState({
        transactionGraphData: response,
        isLoadingServicesReport: false,
        didErrorOccurWhileFetchingServicesReport: false,
      });
    }
  }

  async getMonthlyGraphTransactionsData(startDate, endDate) {
    const graphStartDate = specialDateRangeFormatter_StartDate(startDate);
    const graphEndDate = specialDateRangeFormatter_EndDate(endDate);
    this.setState({
      isLoadingMonthlyServiceStatistics: true,
    });

    const { response, status, code } =
      await this.transactionHistory.getGraphTransactions(
        graphStartDate,
        graphEndDate,
        null,
        successfulTransactions
      );

    if (status === ERROR_STATUS) {
      this.setState({
        isLoadingMonthlyServiceStatistics: false,
        currentMonthError: true,
      });
    } else {
      this.setState({
        currentMonthGraphData: response,
        isLoadingMonthlyServiceStatistics: false,
        currentMonthError: false,
      });
    }
  }

  async getPrevMonthGraphTransactionsData(startDate, endDate) {
    const graphStartDate = specialDateRangeFormatter_StartDate(startDate);
    const graphEndDate = specialDateRangeFormatter_EndDate(endDate);
    this.setState({
      isLoadingMonthlyServiceStatistics: true,
    });

    const { response, status, code } =
      await this.transactionHistory.getGraphTransactions(
        graphStartDate,
        graphEndDate,
        null,
        successfulTransactions
      );

    if (status === ERROR_STATUS) {
      this.setState({
        isLoadingMonthlyServiceStatistics: false,
        previousMonthError: true,
      });
    } else {
      this.setState({
        previousMonthGraphData: response,
        isLoadingMonthlyServiceStatistics: false,
        previousMonthError: false,
      });
    }
  }

  setReportsGraphStartDate(value) {
    this.setState({
      reportsGraphStartDate: value,
    });
    this.getGraphTransactionsData(value, this.state.reportsGraphEndDate);
  }

  setReportsGraphEndDate(value) {
    this.setState({
      reportsGraphEndDate: value,
    });
    this.getGraphTransactionsData(this.state.reportsGraphStartDate, value);
  }

  setStartDate(value) {
    this.setState({
      startDate: value,
    });

    const domainCode =
      this.state.userSerializer && this.state.userSerializer.isAggregator
        ? this.state.domainCode
        : null;

    this.getTransactionsByDateRange(
      value,
      this.state.endDate,
      this.state.selectedOption.value,
      this.state.statusOption.value,
      this.state.pageSize,
      1,
      domainCode
    );
  }

  setEndDate(value) {
    this.setState({
      endDate: value,
    });

    const domainCode =
      this.state.userSerializer && this.state.userSerializer.isAggregator
        ? this.state.domainCode
        : null;

    this.getTransactionsByDateRange(
      this.state.startDate,
      value,
      this.state.selectedOption.value,
      this.state.statusOption.value,
      this.state.pageSize,
      1,
      domainCode
    );
  }

  didErrorOccurWhileFetchingMonthlyServiceStatistics() {
    const { currentMonthError, previousMonthError } = this.state;
    return currentMonthError || previousMonthError;
  }

  renderRefreshTransactionMessage() {
    const { startDate, endDate, selectedOption, statusOption } = this.state;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() =>
            this.getTransactionsByDateRange(
              startDate,
              endDate,
              selectedOption.value,
              statusOption.value
            )
          }
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  renderRefreshPOSRequestMessage() {
    const { pageSize, pageNum } = this.state;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() => this.getPosRequestData(pageSize, pageNum)}
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  renderRefreshPOSRemapMessage() {
    const { pageSize, pageNum } = this.state;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() => this.getPosRemapReport(pageSize, pageNum)}
        >
          <MdSync
            rotate={this.state.isLoading}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoading ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  renderRefreshMonthlyServiceStatisticsMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={this.getGraphTransactionsData(
            this.state.reportsGraphStartDate,
            this.state.reportsGraphEndDate
          )}
        >
          <MdSync
            rotate={this.state.isLoadingMonthlyServiceStatistics}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoadingMonthlyServiceStatistics
            ? "Loading..."
            : "RETRY"}
        </div>
      </div>
    );
  }

  renderRefreshServicesReportMessage() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() => {
            this.getMonthlyGraphTransactionsData(
              this.state.firstDayCurrentMonth,
              this.state.lastDayCurrentMonth
            );
            this.getPrevMonthGraphTransactionsData(
              this.state.firstDayPrevMonth,
              this.state.lastDayPrevMonth
            );
          }}
        >
          <MdSync
            rotate={this.state.isLoadingMonthlyServiceStatistics}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.state.isLoadingMonthlyServiceStatistics
            ? "Loading..."
            : "RETRY"}
        </div>
      </div>
    );
  }

  checkEmptyRecords(reportBarChartObject) {
    return (
      reportBarChartObject.rechargeData &&
      reportBarChartObject.rechargeData.length === 0 &&
      reportBarChartObject.transferData &&
      reportBarChartObject.transferData.length === 0 &&
      reportBarChartObject.payBillsData &&
      reportBarChartObject.payBillsData.length === 0 &&
      reportBarChartObject.cashoutData &&
      reportBarChartObject.cashoutData.length === 0 &&
      reportBarChartObject.fundData &&
      reportBarChartObject.fundData.length === 0 &&
      reportBarChartObject.walletToWalletData &&
      reportBarChartObject.walletToWalletData.length === 0 &&
      reportBarChartObject.cashinData &&
      reportBarChartObject.cashinData.length === 0
    );
  }

  checkMonthlyStatisticsEmptyRecord(monthlyServicesStatisticsObject) {
    return (
      monthlyServicesStatisticsObject.rechargeMonthDataSum === 0 &&
      monthlyServicesStatisticsObject.transferMonthDataSum === 0 &&
      monthlyServicesStatisticsObject.payBillsMonthDataSum === 0 &&
      monthlyServicesStatisticsObject.fundMonthDataSum === 0 &&
      monthlyServicesStatisticsObject.cashoutMonthDataSum === 0 &&
      monthlyServicesStatisticsObject.walletToWalletMonthDataSum === 0 &&
      monthlyServicesStatisticsObject.cashinMonthDataSum === 0
    );
  }

  renderReportsBarChart(reportBarChartObject) {
    if (this.state.didErrorOccurWhileFetchingServicesReport) {
      return this.renderRefreshServicesReportMessage();
    } else if (
      this.checkEmptyRecords(reportBarChartObject) &&
      !this.state.isLoadingServicesReport
    ) {
      return (
        <p
          style={{
            textAlign: "center",
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: "85px 0 85px 0",
          }}
        >
          {"No record found, change date range"}
        </p>
      );
    } else if (this.state.isLoadingServicesReport) {
      return <ActivityIndicator />;
    } else {
      return <ReportsBarChart data={reportBarChartObject.graphData} />;
    }
  }

  renderMonthlyServicesStatistics(monthlyServicesStatisticsObject) {
    if (this.didErrorOccurWhileFetchingMonthlyServiceStatistics()) {
      return this.renderRefreshMonthlyServiceStatisticsMessage();
    } else if (
      this.checkMonthlyStatisticsEmptyRecord(monthlyServicesStatisticsObject) &&
      !this.state.isLoadingMonthlyServiceStatistics
    ) {
      return (
        <p
          style={{
            textAlign: "center",
            color: process.env.REACT_APP_BLUE_COLOUR,
            fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
            padding: "85px 0 85px 0",
          }}
        >
          {"No transactions record this month"}
        </p>
      );
    } else if (this.state.isLoadingMonthlyServiceStatistics) {
      return <ActivityIndicator />;
    } else {
      return (
        <Table bordered responsive>
          <tbody>
            <tr>
              <td md={6} id="buy-airtime-services-statistics-card">
                <span className="services-statistics-card-label">
                  Sell Airtime
                </span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/buy-airtime-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.rechargeMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.rechargePrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.rechargePrevMonthDataSum,
                      monthlyServicesStatisticsObject.rechargeMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.rechargePrevMonthDataSum >
                    monthlyServicesStatisticsObject.rechargeMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
              <td md={6} id="send-money-services-statistics-card">
                <span className="services-statistics-card-label">
                  Send Money
                </span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/send-money-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.transferMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.transferPrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.transferPrevMonthDataSum,
                      monthlyServicesStatisticsObject.transferMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.transferPrevMonthData >
                    monthlyServicesStatisticsObject.transferMonthData
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
            </tr>
            <tr>
              <td md={6} id="pay-bills-services-statistics-card">
                <span className="services-statistics-card-label">
                  Pay Bills
                </span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/pay-a-bill-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.payBillsMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.payBillsPrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.payBillsPrevMonthDataSum,
                      monthlyServicesStatisticsObject.payBillsMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.payBillsPrevMonthDataSum >
                    monthlyServicesStatisticsObject.payBillsMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
              <td md={6} id="cash-out-services-statistics-card">
                <span className="services-statistics-card-label">Cash Out</span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/cash-out-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.cashoutMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.cashoutPrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.cashoutPrevMonthDataSum,
                      monthlyServicesStatisticsObject.cashoutMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.cashoutPrevMonthDataSum >
                    monthlyServicesStatisticsObject.cashoutMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
            </tr>
            <tr>
              <td md={6} id="pay-bills-services-statistics-card">
                <span className="services-statistics-card-label">Fund</span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/buy-airtime-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.fundMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.fundPrevMonthDataSum === 0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.fundPrevMonthDataSum,
                      monthlyServicesStatisticsObject.fundMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.fundPrevMonthDataSum >
                    monthlyServicesStatisticsObject.fundMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
              <td md={6} id="cash-out-services-statistics-card">
                <span className="services-statistics-card-label">
                  Wallet To Wallet
                </span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/send-money-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.walletToWalletMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.walletToWalletPrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.walletToWalletPrevMonthDataSum,
                      monthlyServicesStatisticsObject.walletToWalletMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.walletToWalletPrevMonthDataSum >
                    monthlyServicesStatisticsObject.walletToWalletMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
            </tr>
            <tr>
              <td md={6} id="cash-out-services-statistics-card">
                <span className="services-statistics-card-label">Cash In</span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/cash-out-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.cashinMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.cashinPrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.cashinPrevMonthDataSum,
                      monthlyServicesStatisticsObject.cashinMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.cashinPrevMonthDataSum >
                    monthlyServicesStatisticsObject.cashinMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
              <td md={6} id="cash-out-services-statistics-card">
                <span className="services-statistics-card-label">
                  PayPoint Fund
                </span>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={5}>
                    <img
                      src={"/assets/media/icons/buy-airtime-icon-2.png"}
                      alt=""
                    />
                  </Col>
                  <Col
                    md={7}
                    style={{ fontSize: process.env.REACT_APP_BIG_FONT_SIZE }}
                  >
                    {calculatePercentage(
                      monthlyServicesStatisticsObject.payPointFundMonthDataSum,
                      monthlyServicesStatisticsObject.totalMonthlySum
                    )}
                    %
                  </Col>
                </Row>
                {monthlyServicesStatisticsObject.cashinPrevMonthDataSum ===
                0 ? (
                  <Text className="services-statistics-detail">
                    No transactions last month
                  </Text>
                ) : (
                  <Text className="services-statistics-detail">
                    {percentageIncreaseOrDecrease(
                      monthlyServicesStatisticsObject.payPointFundPrevMonthDataSum,
                      monthlyServicesStatisticsObject.payPointFundMonthDataSum
                    )}
                    %{" "}
                    {monthlyServicesStatisticsObject.payPointFundPrevMonthDataSum >
                    monthlyServicesStatisticsObject.payPointFundMonthDataSum
                      ? "less"
                      : "more"}{" "}
                    than previous month
                  </Text>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      );
    }
  }

  // async downloadReport () {
  //   this.state({
  //     isLoading: true
  //   })
  //   const { status, response, code } = await this.transactionHistory.
  // }
  onBackButtonClick = () => {
    this.setState({
      selectedServiceCategory: null,
      services: null,
    });
  };

  render() {
    const {
      searchTerm,
      filteredTransactions,
      statusOption,
      selectedOption,
      transactionGraphData,
      currentMonthGraphData,
      previousMonthGraphData,
      userSerializer,
      agentList,
    } = this.state;

    const payBillsData =
      transactionGraphData && refineGraphData(transactionGraphData.bills);
    const transferData =
      transactionGraphData && refineGraphData(transactionGraphData.transfer);
    const rechargeData =
      transactionGraphData && refineGraphData(transactionGraphData.recharge);
    const fundData =
      transactionGraphData && refineGraphData(transactionGraphData.fund);
    const cashoutData =
      transactionGraphData && refineGraphData(transactionGraphData.cashOut);
    const walletToWalletData =
      transactionGraphData &&
      refineGraphData(transactionGraphData.walletToWallet);
    const cashinData =
      transactionGraphData && refineGraphData(transactionGraphData.cashIn);
    const payPointFundData =
      transactionGraphData &&
      refineGraphData(transactionGraphData.payPointFund);

    const arraysOfData = transactionGraphData && [
      transactionGraphData.transfer,
      transactionGraphData.recharge,
      transactionGraphData.fund,
      transactionGraphData.bills,
      transactionGraphData.cashOut,
      transactionGraphData.walletToWallet,
      transactionGraphData.cashIn,
      transactionGraphData.payPointFund,
    ];

    const arrayIndex =
      transactionGraphData && findArrayIndexWithMaximumLength(arraysOfData);

    const dateLabel =
      transactionGraphData && refineGraphDataForDate(arraysOfData[arrayIndex]);

    const payBillsMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.bills).reduce((a, b) => a + b, 0);
    const rechargeMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.recharge).reduce(
        (a, b) => a + b,
        0
      );
    const transferMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.transfer).reduce(
        (a, b) => a + b,
        0
      );
    const fundMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.fund).reduce((a, b) => a + b, 0);
    const cashoutMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.cashOut).reduce((a, b) => a + b, 0);
    const walletToWalletMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.walletToWallet).reduce(
        (a, b) => a + b,
        0
      );
    const cashinMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.cashIn).reduce((a, b) => a + b, 0);
    const payPointFundMonthDataSum =
      currentMonthGraphData &&
      refineGraphData(currentMonthGraphData.payPointFund).reduce(
        (a, b) => a + b,
        0
      );

    const payBillsPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.bills).reduce((a, b) => a + b, 0);
    const rechargePrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.recharge).reduce(
        (a, b) => a + b,
        0
      );
    const transferPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.transfer).reduce(
        (a, b) => a + b,
        0
      );
    const fundPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.fund).reduce((a, b) => a + b, 0);
    const cashoutPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.cashOut).reduce(
        (a, b) => a + b,
        0
      );
    const walletToWalletPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.walletToWallet).reduce(
        (a, b) => a + b,
        0
      );
    const cashinPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.cashIn).reduce((a, b) => a + b, 0);
    const payPointFundPrevMonthDataSum =
      previousMonthGraphData &&
      refineGraphData(previousMonthGraphData.payPointFund).reduce(
        (a, b) => a + b,
        0
      );

    const totalMonthlySum =
      payBillsMonthDataSum +
      rechargeMonthDataSum +
      transferMonthDataSum +
      fundMonthDataSum +
      cashoutMonthDataSum +
      walletToWalletMonthDataSum +
      cashinMonthDataSum +
      payPointFundMonthDataSum;

    const graphData = {
      labels: dateLabel,
      datasets: [
        {
          label: "Sell Airtime",
          backgroundColor: "#83F4FA",
          borderColor: "#00B8DE",
          borderJoinStyle: "round",
          borderWidth: 0.6,
          data: rechargeData,
          pointBorderWidth: 0,
        },
        {
          label: "Send Money",
          backgroundColor: "#FACB83",
          borderColor: "#F5834F",
          borderWidth: 0.6,
          data: transferData,
        },
        {
          label: "Pay Bills",
          backgroundColor: "#9483FA",
          borderColor: "#9F4FF5",
          borderWidth: 0.6,
          data: payBillsData,
        },
        {
          label: "Cash Out",
          backgroundColor: "#F9596C",
          borderColor: "#EE312A",
          borderWidth: 0.6,
          data: cashoutData,
        },
        {
          label: "Fund",
          backgroundColor: "#faa383",
          borderColor: "#faa383",
          borderWidth: 0.6,
          data: fundData,
        },
        {
          label: "Wallet To Wallet",
          backgroundColor: "#83fa89",
          borderColor: "#83fa89",
          borderWidth: 0.6,
          data: walletToWalletData,
        },
        {
          label: "Cash In",
          backgroundColor: "#F959BC",
          borderColor: "#EE312A",
          borderWidth: 0.6,
          data: cashinData,
        },
        {
          label: "Paypoint Fund",
          backgroundColor: "#ff6c36",
          borderColor: "#ff6c36",
          borderWidth: 0.6,
          data: payPointFundData,
        },
      ],
    };

    const csvFilteredTransaction = filteredTransactions.map((trans) => ({
      transactionRef: handleLongNumber(trans.transactionRef),
      narration: trans.narration,
      transactionType: handleTransactionTypeFormat(trans.transactionTypeInt),
      fee: `₦${formatAmount(convertNgkToNgn(trans.fee))}`,
      transactionAmount: `₦${formatAmount(
        convertNgkToNgn(trans.transactionAmount)
      )}`,
      statusCode: handleStatusFormat(trans.statusCode),
      customerId:
        trans.customerId &&
        handleLongNumber(formatEmailOrPhone(trans.customerId)),
      dateCreated: formatDate(trans.dateCreated),
      address: trans.address ? trans.address : "",
      bankName: trans.bankName ? trans.bankName : "",
      beneficiaryName: trans.beneficiaryFirstName
        ? `${trans.beneficiaryFirstName} ${
            trans.beneficiaryOtherName ? trans.beneficiaryOtherName : ""
          } ${trans.beneficiaryLastName ? trans.beneficiaryLastName : ""}`
        : "",
      senderName: trans.customerFirstName
        ? `${trans.customerFirstName} ${
            trans.customerOtherName ? trans.customerOtherName : ""
          } ${trans.customerLastName ? trans.customerLastName : ""}`
        : "",
      accountNumber: trans.beneficiaryAccountNumber
        ? trans.beneficiaryAccountNumber
        : "",
      availableBalance: trans.availableBalance
        ? `₦${formatAmount(convertNgkToNgn(trans.availableBalance))}`
        : "",
    }));

    // console.log(csvFilteredTransaction);

    const csvHeader = [
      { label: "Transaction Reference", key: "transactionRef" },
      { label: "Transaction Type", key: "transactionType" },
      { label: "Description", key: "narration" },
      { label: "Customer ID", key: "customerId" },
      { label: "Amount", key: "transactionAmount" },
      { label: "Fee", key: "fee" },
      { label: "Status", key: "statusCode" },
      { label: "Balance After", key: "availableBalance" },
      { label: "Date", key: "dateCreated" },
      { label: "Address", key: "address" },
      { label: "Bank", key: "bankName" },
      // { label: 'Beneficiary Name', key: 'beneficiaryName' },
      // { label: 'Sender Name', key: 'senderName' },
    ];

    const monthlyServicesStatisticsObject = {
      rechargeMonthDataSum,
      transferMonthDataSum,
      payBillsMonthDataSum,
      fundMonthDataSum,
      cashoutMonthDataSum,
      walletToWalletMonthDataSum,
      cashinMonthDataSum,
      payPointFundMonthDataSum,
      payBillsPrevMonthDataSum,
      rechargePrevMonthDataSum,
      transferPrevMonthDataSum,
      fundPrevMonthDataSum,
      cashoutPrevMonthDataSum,
      walletToWalletPrevMonthDataSum,
      cashinPrevMonthDataSum,
      payPointFundPrevMonthDataSum,
      totalMonthlySum,
    };

    const reportBarChartObject = {
      payBillsData,
      transferData,
      rechargeData,
      fundData,
      cashoutData,
      walletToWalletData,
      cashinData,
      payPointFundData,
      graphData,
    };

    const transactionReportComponent = (
      <div
        className={
          this.state.deviceIsMobile
            ? "container container-fluid reports-scene-container-mobile"
            : "reports-scene-container"
        }
      >
        <div className="h1 reports-scene-header">My Reports</div>
        <Container
          fluid
          className="regular-container"
          style={{ padding: "0px", overflow: "scroll", width: "83%" }}
        >
          <Row id="reports-scene-row">
            {!process.env.REACT_APP_SHOW_SERVICES_CHARTS && (
              <>
                <Col
                  xs={12}
                  md={12}
                  xl={8}
                  id="services-report-container"
                  className="card-with-shadow"
                >
                  <div className="services-report-header">
                    <div className="h1 reports-scene-statistical-component-heading">
                      Services Report
                    </div>

                    <div className="reports-scene-date-picker-container">
                      <DatePicker
                        selected={this.state.reportsGraphStartDate}
                        onChange={(date) => this.setReportsGraphStartDate(date)}
                        startDate={this.state.reportsGraphStartDate}
                        endDate={this.state.reportsGraphEndDate}
                        dateFormat={`dd/MM/yyyy`}
                        showYearDropdown
                        customInput={<DateInputButton />}
                        maxDate={new Date()}
                      />
                      <Text
                        className="date-input-separator"
                        style={{ fontSize: "24px" }}
                      >
                        -
                      </Text>
                      <DatePicker
                        selected={this.state.reportsGraphEndDate}
                        onChange={(date) => this.setReportsGraphEndDate(date)}
                        selectsEnd
                        endDate={this.state.reportsGraphEndDate}
                        minDate={this.state.reportsGraphStartDate}
                        maxDate={new Date()}
                        dateFormat={`dd/MM/yyyy`}
                        showYearDropdown
                        customInput={<DateInputButton />}
                      />
                    </div>
                  </div>

                  <div className="bar-chart-container">
                    {this.renderReportsBarChart(reportBarChartObject)}
                  </div>
                </Col>

                <Col xs={12} md={12} xl={4} className="regular-col">
                  <div className="card-with-shadow services-statistics-container">
                    <div className="services-report-header">
                      <div
                        className="h1 reports-scene-statistical-component-heading"
                        id="services-statistics-heading"
                      >
                        Monthly Service Statistics
                      </div>
                    </div>

                    <div id="services-statistics-table-container">
                      {this.renderMonthlyServicesStatistics(
                        monthlyServicesStatisticsObject
                      )}
                    </div>
                  </div>
                </Col>
              </>
            )}
            <Col
              md={12}
              className="card-with-shadow"
              id="transactions-table-container"
            >
              <div className="transactions-table-header">
                <div className="transactions-title">
                  <div className="reports-scene-statistical-component-heading">
                    All transactions
                  </div>
                </div>
                {/* {userSerializer.isAggregator && <Select
                          options={SampleAgentsResponse.content}
                          onChange={this.filterTransactionsByStatus}
                          value={agentOption}
                          className='select-input-container'
                          classNamePrefix="react-select"
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: 'rgb(178, 212, 255)',
                            }
                          })}
                        />} */}

                {
                  userSerializer && userSerializer.isAggregator && (
                    <Select
                      options={this.state.agents && this.state.agents}
                      onChange={(event) => {
                        this.filterTransactionsByAgent(event);
                      }}
                      value={agentList}
                      className="select-input-container"
                      classNamePrefix="react-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "rgb(178, 212, 255)",
                        },
                      })}
                    />
                  )
                  // <Form.Group controlId="formBasicEmail" style={{ padding: '0px' }}>
                  //   <FormControl onChange={(event) => {
                  //       const domainCode = event.tarF.value
                  //       this.filterTransactionsByAgent(domainCode)
                  //     }} style={{
                  //       backgroundColor: '#FFFFFF',
                  //       borderRadius: '20px',
                  //       border: '1px solid #DFE2E6',
                  //       boxSizing: 'border-box',
                  //       fontSize: '12px',
                  //       lineHeight: '17px',
                  //       width: '200px'}} as="select">
                  //     <option value={0}>Select Agent</option>
                  //     <option value={userSerializer.domainCode}>Self</option>
                  //     {this.state.agents && this.state.agents.map(
                  //       ({businessName, agentCode}) => <option value={agentCode}>{businessName}</option>
                  //     )}
                  //   </FormControl>
                  // </Form.Group>
                }

                <Select
                  options={ServiceType}
                  onChange={this.filterTransactionsByType}
                  value={selectedOption}
                  className="select-input-container"
                  classNamePrefix="react-select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "rgb(178, 212, 255)",
                    },
                  })}
                />

                <Select
                  options={StatusCode}
                  onChange={this.filterTransactionsByStatus}
                  value={statusOption}
                  className="select-input-container"
                  classNamePrefix="react-select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "rgb(178, 212, 255)",
                    },
                  })}
                />

                {!(userSerializer && userSerializer.isAggregator) && (
                  <div className="search-container">
                    <input
                      className="search-input"
                      value={searchTerm}
                      onChange={this.handleSearch}
                      placeholder="Search by TransRef..."
                    />
                  </div>
                )}

                <div className="reports-scene-date-picker-container">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.setStartDate(date)}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    dateFormat={`dd/MM/yyyy`}
                    customInput={<DateInputButton />}
                    maxDate={this.TodaysDate}
                    showYearDropdown
                  />
                  <Text
                    className="date-input-separator"
                    style={{ fontSize: "24px" }}
                  >
                    -
                  </Text>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.setEndDate(date)}
                    selectsEnd
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                    maxDate={this.TodaysDate}
                    dateFormat={`dd/MM/yyyy`}
                    customInput={<DateInputButton />}
                    showYearDropdown
                  />
                </div>
                <div>
                  <Button className="download-link-button">
                    <CSVLink
                      data={csvFilteredTransaction}
                      filename="QuickTellerPaypoint Transactions History.csv"
                      className="download-text"
                      headers={csvHeader}
                    >
                      Download
                    </CSVLink>
                  </Button>
                </div>
              </div>
              <div className="transactions-table-container">
                {this.state.didErrorOccurWhileFetchingAllTransactions ? (
                  this.renderRefreshTransactionMessage()
                ) : filteredTransactions.length > 0 ? (
                  <TransactionsReportTable
                    data={filteredTransactions}
                    dataCount={this.state.dataCount}
                    getAllTransactions={this.getAllTransactions}
                    getTransactionsByDateRange={this.getTransactionsByDateRange}
                    filterTransactionsByType={this.filterTransactionsByType}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    pages={this.state.pages}
                    pageSize={this.state.pageSize}
                    pageNum={this.state.pageNum}
                    loading={this.state.isLoading}
                    selectedOption={this.state.selectedOption}
                    statusOption={this.state.statusOption}
                    dismissErrorMessage={this.props.dismissErrorMessage}
                    showErrorMessage={this.props.showErrorMessage}
                    domainCode={this.state.domainCode}
                    userSerializer={userSerializer}
                  />
                ) : this.state.isLoading ? (
                  <p
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      padding: "20px 0 20px 0",
                    }}
                  >
                    <ActivityIndicator />
                  </p>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: process.env.REACT_APP_BLUE_COLOUR,
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      padding: "85px 0 85px 0",
                    }}
                  >
                    {"No record found, change filter"}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );

    const posReportComponent = (
      <div
        className={
          this.state.deviceIsMobile
            ? "container container-fluid reports-scene-container-mobile"
            : "reports-scene-container"
        }
      >
        <div className="h1 reports-scene-header">POS Reports</div>
        <Container
          fluid
          className="regular-container"
          style={{
            padding: "0px",
            overflow: "scroll",
            width: "83%",
            height: "70%",
            backgroundColor: "#fff",
          }}
        >
          <Row id="reports-scene-row" style={{ marginTop: "5px" }}>
            <Col
              md={12}
              className="card-with-shadow"
              id="transactions-table-container"
            >
              <div className="pos-report-table-header">
                <div className="transactions-title">Select Report:</div>{" "}
                <Select
                  options={posReportTableOptions}
                  onChange={(event) => {
                    this.handleSelections(event);
                  }}
                  value={this.state.showPosRequestOrRemap}
                  className="select-pos-report-component"
                  classNamePrefix="react-select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "rgb(178, 212, 255)",
                    },
                  })}
                />
                {/* <div>  
                                                           <Button className="download-link-button">
                                                           </Button>
                                                    </div> */}
              </div>

              <div
                className="transactions-table-container"
                style={{
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  marginTop: "0px",
                }}
              >
                {this.state.showPosRequestOrRemap.value === 1 &&
                  (this.state.didErrorOccurWhileFetchingAllPOSRequests ? (
                    this.renderRefreshPOSRequestMessage()
                  ) : this.state.posReportData.length > 0 ? (
                    <PosRequestTable
                      data={this.state.posReportData}
                      getPosRequestData={this.getPosRequestData}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      pages={this.state.pages}
                      pageSize={this.state.pageSize}
                      pageNum={this.state.pageNum}
                      loading={this.state.isLoading}
                      selectedOption={this.state.selectedOption}
                      statusOption={this.state.statusOption}
                      dismissErrorMessage={this.props.dismissErrorMessage}
                      showErrorMessage={this.props.showErrorMessage}
                      domainCode={this.state.domainCode}
                    />
                  ) : this.state.isLoading ? (
                    <p
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        padding: "20px 0 20px 0",
                      }}
                    >
                      <ActivityIndicator />
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: process.env.REACT_APP_BLUE_COLOUR,
                        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        padding: "85px 0 85px 0",
                      }}
                    >
                      {"No record found, change filter"}
                    </p>
                  ))}

                {this.state.showPosRequestOrRemap.value === 2 &&
                  (this.state.didErrorOccurWhileFetchingAllPOSRemap ? (
                    this.renderRefreshPOSRemapMessage()
                  ) : this.state.posRemapReport.length > 0 ? (
                    <PosRemapTable
                      data={this.state.posRemapReport}
                      getPosRemapReport={this.getPosRemapReport}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      pages={this.state.pages}
                      pageSize={this.state.pageSize}
                      pageNum={this.state.pageNum}
                      loading={this.state.isLoading}
                      selectedOption={this.state.selectedOption}
                      statusOption={this.state.statusOption}
                      dismissErrorMessage={this.props.dismissErrorMessage}
                      showErrorMessage={this.props.showErrorMessage}
                      domainCode={this.state.domainCode}
                    />
                  ) : this.state.isLoading ? (
                    <p
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        padding: "20px 0 20px 0",
                      }}
                    >
                      <ActivityIndicator />
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: process.env.REACT_APP_BLUE_COLOUR,
                        fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                        padding: "85px 0 85px 0",
                      }}
                    >
                      {"No record found, change filter"}
                    </p>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );

    const desktopContainer = (
      <Row
        style={{ /*height: '100vh',*/ width: "55vw", margin: 0, padding: 0 }}
      >
        {this.state.showServiceCategories && (
          <Col
            md={4}
            lg={3}
            style={{
              backgroundColor: "#eef2f4",
              borderRight: "0.6px solid #CCC",
              height: "100%",
              minHeight: "100vh",
              padding: "35px",
              paddingRight: "25px",
              paddingLeft: "0px",
              margin: 0,
            }}
          >
            <ReportNav
              selectedServiceCategory={this.state.selectedServiceCategory}
              deviceIsPC={this.state.deviceIsPC}
              showTransactionReportTable={this.showTransactionReportTable}
              showRegistrationReportTable={this.showRegistrationReportTable}
              showPosReportTable={this.showPosReportTable}
            />
          </Col>
        )}
        {
          //this.state.selectedServiceCategory &&
          <Col
            md={this.state.showServiceCategories ? 7 : 10}
            style={{ margin: "0px", padding: "0px" }}
          >
            {/* {this.state.showTransactionReportComponent ? transactionReportComponent : posReportComponent} */}
            {this.state.isTransactionReportComponent &&
              transactionReportComponent}
            {this.state.isPosReportComponent && posReportComponent}
            {this.state.isRegistrationReportComponent && <RegistrationScreen />}
            {/* {this.props.isTransactionReportComponent
              ? transactionReportComponent
              : posReportComponent} */}
          </Col>
        }
      </Row>
    );

    const mobileContainer = (
      <Row style={{ margin: 0, padding: 0 }}>
        {!this.state.selectedServiceCategory ? (
          <Col
            md={12}
            style={{
              backgroundColor: "#eef2f4",
              height: "100%",
              minHeight: "100vh",
              padding: "35px",
              paddingTop: 0,
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            {this.serviceCategories}
          </Col>
        ) : (
          <Col md={12} lg={9} style={{ margin: "0px", padding: "0px" }}>
            {transactionReportComponent}
          </Col>
        )}
      </Row>
    );

    return (
      <div
        style={{
          paddingLeft: "0px",
          paddingRight: !this.state.deviceIsMobile ? "0px" : "0px",
        }}
      >
        {true ? desktopContainer : mobileContainer}
      </div>
    );
  }
}
