import React, { useState } from "react";
import { firetoast, notify } from "../../../../components/extras";
import HeaderSignup from "../../../../components/header-signup";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { ReloadIcon } from "../../../../icons";
import Onboarding from "../../../../services/api/resources/onboarding";
import UserManagement from "../../../../services/api/resources/user-management";
import { saveAuthToken } from "../../../../utils/auth";
import { getDeviceInformation } from "../../../../utils/helpers";
import PrimaryButton from "../component/button";
import { isOTPValid } from "../component/extras";
import SignUp from "../component/signupText";
import Stepper from "../component/stepper";
import "../styles.scss";
import {
  formatPhoneNumber,
  specialDateRangeFormatter_StartDateDate,
} from "../../../../utils/formatters";
import BackButton from "../component/backButton";
import { Col } from "react-bootstrap";

export default function OTPScene({ setScreen }) {
  const userManagement = new UserManagement();
  const onboarding = new Onboarding();
  const applicationId = localStorage.getItem("applicationId");
  const application = JSON.parse(localStorage.getItem("application"));
  const updatedPersonaldetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );

  let bvnInformation = JSON.parse(localStorage.getItem("bvnInformation"));

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [error, setError] = useState(false);

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    phoneNumber: application
      ? application?.applicantDetails?.phoneNumber
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.phoneNumber
      : "",
    email: application
      ? application?.applicantDetails?.emailAddress
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.email
      : "",
    gender: application
      ? application?.applicantDetails?.gender
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.gender
      : "",
    bvn: application
      ? application?.applicantDetails?.bvn
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.bvn
      : "",
    password: application
      ? application?.applicantDetails?.password
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.password
      : "",
    confirmPassword: application
      ? application?.applicantDetails?.password
      : updatedPersonaldetailsForm
      ? updatedPersonaldetailsForm.confirmPassword
      : "",
    dob: application
      ? new Date(2005, 0, 1)
      : updatedPersonaldetailsForm
      ? new Date(updatedPersonaldetailsForm.dob)
      : new Date(2005, 0, 1),
  });

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const isFormValid = () => {
    return isOTPValid(otp.toString().replace(/[, ]+/g, "").trim());
  };

  const callLogin = async (domainTypeId = null) => {
    const deviceInformation = getDeviceInformation();
    const loginResponseObj = await userManagement.login(
      formatPhoneNumber(bvnInformation?.bvnPhoneNumber),
      personalDetailsForm.password,
      deviceInformation,
      domainTypeId
    );

    const { errorSource, status, code, response } = loginResponseObj;
    const authToken = loginResponseObj?.response?.data?.accessToken;

    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      saveAuthToken(authToken);
    }
  };

  function formatPhoneNumber(inputNumber) {
    const numStr = inputNumber.replace(/\D/g, "");
    if (numStr.length >= 10) {
      const last10Digits = numStr.slice(-10);

      const result = "234" + last10Digits;

      return result;
    }
  }

  const newDate = bvnInformation?.bvnDateOfBirth.split("-").reverse().join("-");
  const callCreateApplication = async () => {
    const payload = {
      applicantDetails: {
        phoneNumber: formatPhoneNumber(bvnInformation?.bvnPhoneNumber),
        emailAddress: personalDetailsForm.email,
        bvn: bvnInformation?.bvnNumber,
        dob: newDate,
        gender: bvnInformation?.gender,
      },
    };
    // const deviceInformation = getDeviceInformation();
    const applicationResponseObj =
      await onboarding.createPersonalDetailsAggregator(payload);
    const { status, response } = applicationResponseObj;

    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      localStorage.setItem("applicationId", response.applicationId);
      const applicantDetails = localStorage.setItem(
        "application",
        JSON.stringify(response)
      );
      notify("Saved", "success");

      setScreen(3);
    }
  };

  const onSubmitButtonClick = async () => {
    setIsLoading(true);
    const payload = {
      firstName: bvnInformation?.bvnFirstname,
      middleName: bvnInformation?.bvnMiddlename,
      lastName: bvnInformation?.bvnLastname,
      email: personalDetailsForm.email,
      mobileNo: bvnInformation?.bvnPhoneNumber,
      password: personalDetailsForm.password,
      confirmPassword: personalDetailsForm.confirmPassword,
      domainCode: "app",
      otp: otp.toString().replace(/[, ]+/g, "").trim(),
    };

    const { status, response } = await userManagement.signupUser(
      payload,
      false,
      true
    );
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
      setIsLoading(false);
    } else if (status === SUCCESS_STATUS) {
      try {
        await callLogin(
          parseInt(process.env.REACT_APP_APPLICANT_DOMAIN_TYPE_ID)
        );
        await callCreateApplication();
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const callResendOtp = async () => {
    setIsLoading(true);

    const responseObj = await userManagement.resendOtp(
      formatPhoneNumber(personalDetailsForm.phoneNumber)
    );
    const { status, response } = responseObj;
    const newResponse = response.toString().replace(/[, ]+/g, "").trim();

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${newResponse.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("OTP successfully sent!", "success");

      setScreen(3);
    }
  };

  return (
    <>
      <HeaderSignup />
      {/* <BackButton onClick={() => setScreen(2)} /> */}
      <main className="onboarding-container">
        <SignUp />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            gap: "10px",
            marginBottom: "30px",
          }}
        >
          <Stepper borderColor="#00425F" />
          <Stepper />
          <Stepper />
          <Stepper />
        </div>
        <div className="card-header">
          <div className="header-text">Verify OTP</div>
          <div className="header-subtext">
            Hello,{" "}
            <span style={{ color: "#00B8DE", fontWeight: "bold" }}>
              {bvnInformation?.bvnFirstname} {bvnInformation?.bvnLastname}
            </span>
          </div>
          <div style={{ color: "#1F2937" }}>
            Please enter the 6 digit code that has been sent to your phone
            number
          </div>
        </div>
        <form className="otp-conatiner">
          <div className="otp-subtext">Enter OTP</div>
          <div className="otp-inputs">
            {otp.map((data, index) => {
              return (
                <input
                  className="otp-input"
                  type="text"
                  name="otp"
                  inputMode="numeric"
                  autoComplete="new-password"
                  maxLength={1}
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
          <br />
          {error && (
            <Col lg={12}>
              <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                Invalid OTP! Please check the number and rety.
              </span>
            </Col>
          )}
          <p>
            {ReloadIcon()}{" "}
            <span
              style={{ cursor: "pointer" }}
              className="otp-resend"
              onClick={callResendOtp}
            >
              Resend
            </span>
          </p>

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={onSubmitButtonClick}
            // onClick={() => setScreen(3)}
          >
            {" "}
            {isLoading ? "Processing..." : "Submit"}
          </PrimaryButton>
        </form>
      </main>
      {/* </div> */}
    </>
  );
}
