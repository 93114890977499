import React, { Component } from "react";
// import { AppProvider } from "../context";

import ApplicationGrid from "../registrationReport/registrationGrid";
import { Form, InputGroup } from "react-bootstrap";
import MdSearch from "react-ionicons/lib/MdSearch";
import qs from "qs";
import H1 from "../../../../../components/h1";

class RegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      deviceIsPC:
        !qs.parse(window.location.search)["?mobile"] &&
        window.innerWidth >= 992,
    };
    this.handleChange = this.handleChange.bind(this);

    this.timeoutId = null;
  }

  handleChange(event) {
    this.setState({ searchText: event.target.value });
  }

  componentDidMount() {
    localStorage.removeItem("jumbScreen");
  }

  render() {
    return (
      <div className="aggregator-layout-cac-report">
        <H1
          style={{
            textAlign: "start",
            marginBottom: "35px",
          }}
        >
          CAC Registration reports
        </H1>
        <div
          style={{
            marginBottom: 25,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputGroup
            col
            lg={3}
            style={{
              background: "#F3F5F6",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: 5,
              width: this.state.deviceIsPC ? "25vw" : "40vw",
              padding: "0 10px",
            }}
          >
            <div
              style={{
                fontSize: 30,
                textAlign: "center",
                color: "#848F9F",
                cursor: "pointer",
              }}
              onClick={() => this.handleSearch()}
            >
              <MdSearch color="grey" />
            </div>
            <Form.Control
              onChange={this.handleChange}
              value={this.state.searchText}
              title="Search by business name or date created"
              style={{
                border: "none",
                background: "#F3F5F6",
                height: 40,
                borderRadius: 5,

                boxShadow: "none",
              }}
              placeholder="Search by business name or date created"
            />
          </InputGroup>
        </div>
        <section>
          <ApplicationGrid searchText={this.state.searchText} />
        </section>
      </div>
    );
  }
}

export default RegistrationScreen;
