import { SUCCESS_STATUS } from "../../constants/api";
import Platform from "../../services/api/resources/platform";

export function mockInitiateApiResponse(payload) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (payload.bvn === "12345678910") {
        resolve({
          status: "SUCCESS",
          response: {
            code: "200000",
            data: { jobId: "876YTH" },
            description: "jobID generated succesfully",
          },
        });
      } else if (payload.bvn !== "12345678910") {
        resolve({
          status: "ERROR",
          response: {
            code: "400000",
            description: "Validation failed",
          },
        });
      } else {
        resolve({
          status: "ERROR",
          response: {
            code: "500000",
            description: "An error occured, try again later",
          },
        });
      }
    }, 2000);
  });
}
export function mockUploadApiResponse(payload) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (payload.bvn === "12345678910") {
        resolve({
          status: "SUCCESS",
          response: {
            code: "200000",
            description: "Your face ID verification was succesfully",
          },
        });
      } else if (payload.bvn !== "12345678910") {
        resolve({
          status: "ERROR",
          response: {
            code: "400000",
            description: "Validation failed",
          },
        });
      } else {
        resolve({
          status: "ERROR",
          response: {
            code: "500000",
            description: "An error occured, try again later",
          },
        });
      }
    }, 2000);
  });
}
// export function mockStatusApiResponse(payload) {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       if (payload.bvn === "12345678910") {
//         resolve({
//           status: "SUCCESS",
//           response: {
//             code: "200000",
//             description: "Your face ID verification was succesfully",
//           },
//         });
//       } else if (payload.bvn !== "12345678910") {
//         resolve({
//           status: "ERROR",
//           response: {
//             code: "400000",
//             description: "Validation failed",
//           },
//         });
//       } else {
//         resolve({
//           status: "ERROR",
//           response: {
//             code: "500000",
//             description: "An error occured, try again later",
//           },
//         });
//       }
//     }, 2000);
//   });
// }
export const mockStatusApiResponse = async (payload) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Math.random() < 0.5) {
        resolve({ status: "ERROR", response: null });
      } else {
        resolve({ status: "SUCCESS", response: { jobId: payload.jobId } });
      }
    }, 1000);
  });
};

export const forceAgentToVerifyKyc = () => {
  const referenceDate = new Date("2024-07-10T00:00:00");
  const dateToCheck = new Date();

  return dateToCheck >= referenceDate;
};

export const loadAgent = async () => {
  const platform = new Platform();

  const getAgentResponseObj = await platform.getCurrentAgent();
  const { status, response } = getAgentResponseObj;

  if (status === SUCCESS_STATUS) {
    const currentAgent = response;
    localStorage.setItem("currentAgent", JSON.stringify(currentAgent));
  }
};
