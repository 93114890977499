import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import InputText from "../component/input";

import "react-datepicker/dist/react-datepicker.css";
import MdCheckmarkCircle from "react-ionicons/lib/MdCheckmarkCircle";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import H1 from "../../../../components/h1";
import {
  ERROR_STATUS,
  HTTP_NOT_FOUND_CODE,
  SUCCESS_CODE,
  SUCCESS_STATUS,
} from "../../../../constants/api";
import { MAX_BVN_LENGTH } from "../../../../constants/form-fields";
import phoneValidators from "../../../../utils/validators/phone-validators";
import PrimaryButton from "../component/button";
import { DatePickerComponent } from "../component/datePickerComponent";
import {
  isBvnValid,
  isEmailValid,
  isPasswordValid,
  isConfirmPasswordValid,
  stripNonNumbers,
  isBusinessNameValid,
} from "../component/extras";
import PhoneInput from "../component/phoneInput";

import { firetoast, notify } from "../../../../components/extras";
import FormLabel from "../../../../components/form-label";
import HeaderSignup from "../../../../components/header-signup";
import { NIGERIA_PHONE_PREFIX } from "../../../../constants";
import Onboarding from "../../../../services/api/resources/onboarding";
import UserManagement from "../../../../services/api/resources/user-management";
import { navigateTo } from "../../../../services/redux/actions/navigation";
import {
  formatEmailOrPhone,
  specialDateRangeFormatter_StartDateDate,
} from "../../../../utils/formatters";
import {
  isNumberValid,
  isPhoneValid,
} from "../../../../utils/validators/form-validators";
import SignUp from "../component/signupText";
import Stepper from "../component/stepper";
import { PasswordIcon } from "../../../../icons";
import SelectInput from "../component/select";

const PersonalDetailsScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();
  const bvnInformation = JSON.parse(localStorage.getItem("bvnInformation"));

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bvnData, setBvnData] = useState();
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [bvnResponse, setBvnResponse] = useState({
    success: "",
    error: "",
  });
  const [fieldsFocused, setFieldsFocused] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const updatedpersonalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );

  const convertDate = (date) => {
    const dateComponents = date?.split("-") || "";
    const year = parseInt(dateComponents[0], 10);
    const month = parseInt(dateComponents[1], 10);
    const day = parseInt(dateComponents[2], 10);

    return `${year}, ${month}, ${day + 1}`;
  };

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    email: application ? application?.personalDetails?.email : "",
    gender: bvnInformation ? bvnInformation?.gender : "",
    password: application ? application?.personalDetails?.password : "",
    confirmPassword: application
      ? application?.personalDetails?.confirmPassword
      : "",
    phonePrefix: NIGERIA_PHONE_PREFIX,
    dob: application
      ? new Date(2005, 0, 1)
      : updatedpersonalDetailsForm
      ? new Date(updatedpersonalDetailsForm.dob)
      : new Date(2005, 0, 1),
  });

  useEffect(() => {
    const updatedpersonalDetailsForm = JSON.parse(
      localStorage.getItem("personalDetailsForm")
    );

    if (updatedpersonalDetailsForm) {
      setPersonalDetailsForm({
        email: bvnInformation?.email || updatedpersonalDetailsForm.email || "",
        password:
          application?.personalDetails?.password ||
          updatedpersonalDetailsForm.password ||
          "",
        confirmPassword:
          application?.personalDetails?.confirmPassword ||
          updatedpersonalDetailsForm.confirmPassword ||
          "",
        //   phonePrefix: NIGERIA_PHONE_PREFIX,
        //   dob: application
        //     ? new Date(2005, 0, 1)
        //     : updatedpersonalDetailsForm
        //       ? new Date(updatedpersonalDetailsForm.dob)
        //       : new Date(2005, 0, 1),
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "personalDetailsForm",
      JSON.stringify(personalDetailsForm)
    );
  }, [personalDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  function formatPhoneNumber(inputNumber) {
    const numStr = inputNumber.replace(/\D/g, "");
    if (numStr.length >= 10) {
      const last10Digits = numStr.slice(-10);

      const result = "234" + last10Digits;

      return result;
    }
  }

  const stripSpacesFromString = (str) => {
    return str.replace(/ /g, "");
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || personalDetailsForm;
    const countryValidator = phoneValidators[personalDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      personalDetailsForm.email &&
      personalDetailsForm.password &&
      isEmailValid(personalDetailsForm) &&
      personalDetailsForm.confirmPassword &&
      personalDetailsForm.confirmPassword === personalDetailsForm.password &&
      isPasswordValid(personalDetailsForm?.password) &&
      isConfirmPasswordValid(personalDetailsForm?.confirmPassword)
    );
  };

  const onSubmitButtonClick = async () => {
    setIsLoading(true);
    const payload = {
      firstName: bvnInformation?.bvnFirstname,
      middleName: bvnInformation?.bvnMiddlename,
      lastName: bvnInformation?.bvnLastname,
      email: personalDetailsForm.email,
      gender: bvnInformation?.gender,
      mobileNo: bvnInformation?.bvnPhoneNumber,
      password: personalDetailsForm.password,
      confirmPassword: personalDetailsForm.confirmPassword,
      domainCode: "app",
    };

    const { status, response } = await userManagement.signupUser(
      payload,
      true,
      false
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      notify("Saved", "success");
      localStorage.setItem("applicationId", response.applicationId);

      setScreen(7);
    }
    // setScreen(7);
  };

  const gender = ["Male", "Female"];

  const validateBVN = async () => {
    const date = personalDetailsForm.dob;

    setBvnResponse({
      success: "",
      error: "",
    });
    if (personalDetailsForm.bvn && personalDetailsForm.dob) {
      setLoading(true);
      const payload = {
        bvnNumber: personalDetailsForm.bvn,
        bvnDateOfBirth: specialDateRangeFormatter_StartDateDate(date),
      };
      const { status, response } = await onboarding.validateBVNDetails(payload);
      setBvnData(response);
      localStorage.setItem("BVNData", JSON.stringify(response));

      setLoading(false);
      if (status === ERROR_STATUS) {
        setBvnResponse({ error: response.description });
      } else if (status === SUCCESS_STATUS) {
        setBvnResponse({ success: response.description });
      }
    }
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const NEW_PHONE = "phone is new";
  const NEW_EMAIL = "email is new";
  const USED_PHONE = "phone not new";
  const USED_EMAIL = "email not new";
  const USED_PASSWORD = "password not new";

  function isEmailValidAndNew() {
    return isEmailValid(personalDetailsForm) && isEmailExisting === NEW_EMAIL;
  }

  const userManagement = new UserManagement();

  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isExisting] = useState(false);
  const [isEmailExisting, setIsEmailExisting] = useState("");

  const validateEmail = async (email) => {
    if (!email) {
      return;
    }

    setIsEmailLoading(true);
    const validateUserObj = await userManagement.validateUserOnPassport(email);

    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsEmailExisting(USED_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsEmailExisting(NEW_EMAIL);
      setIsEmailLoading(false);
      setEmailError(false);
    } else {
      setIsEmailLoading(false);
      setEmailError(true);
    }
  };

  function isPhoneValidAndNew() {
    return (
      isPhoneValid(
        personalDetailsForm.phoneNumber,
        personalDetailsForm.phonePrefix
      ) && isPhoneExisting === NEW_PHONE
    );
  }

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);
  const [isPhoneExisting, setIsPhoneExisting] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const validatePhone = async (phone) => {
    if (!phone) {
      return;
    }

    setIsPhoneLoading(true);
    const formattedPhone = formatEmailOrPhone(phone);
    const validateUserObj = await userManagement.validateUserOnPassport(
      formattedPhone
    );
    const { code } = validateUserObj;
    if (code === SUCCESS_CODE) {
      setIsPhoneExisting(USED_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else if (code === HTTP_NOT_FOUND_CODE) {
      setIsPhoneExisting(NEW_PHONE);
      setIsPhoneLoading(false);
      setPhoneError(false);
    } else {
      setIsPhoneLoading(false);
      setPhoneError(true);
    }
  };

  return (
    <>
      <HeaderSignup />
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <SignUp />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper />
            <Stepper />
            <Stepper />
          </div>

          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
              fontSize: "2rem",
            }}
          >
            Personal Details
          </H1>
          <p style={{ marginBottom: "20px" }}>
            Please enter your details below
          </p>

          <InputText
            label={"First Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your First Name"}
            value={bvnInformation?.bvnFirstname}
            maxLength={200}
            disabled={true}
          />

          <InputText
            label={"Middle Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Middle Name"}
            value={bvnInformation?.bvnMiddlename}
            maxLength={200}
            disabled={true}
          />

          <InputText
            label={"Last Name"}
            onChange={(e) => {
              updateFormField({ lastName: e.target.value });
            }}
            labelColor={
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.businessName ||
              isBusinessNameValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Last Name"}
            value={bvnInformation?.bvnLastname}
            maxLength={200}
            disabled={true}
          />

          <InputText
            onBlur={() => {
              isNumberValid(personalDetailsForm) &&
                validatePhone(personalDetailsForm.phoneNumber);
            }}
            label={"BVN Phone Number"}
            placeholder={"Enter phone number"}
            onChange={(e) => {
              updateFormField({
                phoneNumber: stripSpacesFromString(e.target.value),
              });
            }}
            labelColor={
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.phoneNumber ||
              isNumberValid(personalDetailsForm.phoneNumber)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={bvnInformation?.bvnPhoneNumber}
            maxLength={11}
            disabled={true}
          />

          <div
            style={{
              color: process.env.REACT_APP_RED_COLOUR,
              fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

              position: "relative",
              top: "-10px",
            }}
          >
            {errMessage}
          </div>

          {isPhoneLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}
          {isPhoneValidAndNew() && !isPhoneLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.phoneNumber ||
          isPhoneValid(
            personalDetailsForm.phoneNumber,
            personalDetailsForm.phonePrefix
          ) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Phone number is not valid.
            </FormLabel>
          )}
          {isPhoneExisting === USED_PHONE &&
            personalDetailsForm.phoneNumber && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",
                  width: "100%",
                }}
              >
                Phone number already registered on Quickteller Paypoint. Try
                another number!
              </FormLabel>
            )}
          {phoneError && personalDetailsForm.phoneNumber && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Phone Number. Try again
            </FormLabel>
          )}

          <InputText
            onBlur={() => {
              isEmailValid(personalDetailsForm) &&
                validateEmail(personalDetailsForm.email);
            }}
            onChange={(e) => {
              updateFormField({ email: e.target.value.trim() });
            }}
            labelColor={
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#3A3B3B"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !personalDetailsForm.email || isEmailValid(personalDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            type={"email"}
            value={personalDetailsForm.email}
            label={"Email address"}
            placeholder={"Enter  email adress"}
            disabled={false}
          />

          {isEmailLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          {isEmailValidAndNew() && !isEmailLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.email ||
          isEmailValid(personalDetailsForm) ||
          !isExisting ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Email is not valid.
            </FormLabel>
          )}
          {isEmailExisting === USED_EMAIL && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",

                width: "100%",
              }}
            >
              Email already registered on Quickteller Paypoint. Try another
              email!
            </FormLabel>
          )}
          {emailError && personalDetailsForm.email && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Email. Try again
            </FormLabel>
          )}

          <SelectInput
            onChange={(e) => {
              updateFormField({ gender: e.target.value });
            }}
            placeholder={"gender"}
            value={bvnInformation ? bvnInformation?.gender : ""}
            option={gender}
            label={"Gender"}
            disabled={bvnInformation?.gender ? true : false}
          />
          <br />

          <div style={{ position: "relative" }}>
            <InputText
              onChange={(e) => {
                updateFormField({ password: e.target.value.trim() });
              }}
              labelColor={
                !personalDetailsForm.password ||
                isPasswordValid(personalDetailsForm)
                  ? "#3A3B3B"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !personalDetailsForm.email ||
                isPasswordValid(personalDetailsForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              type={visible ? "text" : "password"}
              value={personalDetailsForm.password}
              label={"Enter Password"}
              placeholder={"password"}
            />
            <span
              style={{
                position: "absolute",
                top: "40px",
                right: "4%",
                cursor: "pointer",
              }}
              onClick={() => setVisible(!visible)}
            >
              {PasswordIcon()}
            </span>
          </div>
          {isPasswordLoading && (
            <IosRefresh
              style={{
                marginTop: "-52pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              fontSize="60px"
              color="grey"
              rotate={true}
            />
          )}

          {isPasswordValid() && !isPasswordLoading ? (
            <MdCheckmarkCircle
              style={{
                marginTop: "-45pt",
                marginLeft: "85%",
                width: "20px",
                position: "absolute",
              }}
              color="green"
              fontSize="40px"
            />
          ) : (
            ""
          )}
          {!personalDetailsForm.password ||
          isPasswordValid(personalDetailsForm.password) ? (
            <React.Fragment />
          ) : (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Password is not valid.
            </FormLabel>
          )}

          {passwordError && personalDetailsForm.password && (
            <FormLabel
              style={{
                color: process.env.REACT_APP_RED_COLOUR,
                fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                lineHeight: "19px",
                position: "relative",
                top: "-15px",
                width: "100%",
              }}
            >
              Failed to validate Password. Try again
            </FormLabel>
          )}

          <div style={{ position: "relative" }}>
            <InputText
              // disabled={applicationId}
              onChange={(e) => {
                updateFormField({ confirmPassword: e.target.value.trim() });
              }}
              labelColor={
                !personalDetailsForm.confirmPassword ||
                isConfirmPasswordValid(personalDetailsForm)
                  ? "#3A3B3B"
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${
                !personalDetailsForm.confirmPassword ||
                isConfirmPasswordValid(personalDetailsForm)
                  ? "#DFE2E6"
                  : process.env.REACT_APP_RED_COLOUR
              }`}
              type={confirmVisible ? "text" : "password"}
              handleCopy={handleCopy}
              handlePaste={handlePaste}
              value={personalDetailsForm.confirmPassword}
              label={"Confirm Password"}
              placeholder={"confirm password"}
            />
            <span
              style={{
                position: "absolute",
                top: "40px",
                right: "4%",
                cursor: "pointer",
              }}
              onClick={() => setConfirmVisible(!confirmVisible)}
            >
              {PasswordIcon()}
            </span>
          </div>

          {personalDetailsForm.confirmPassword &&
            personalDetailsForm.confirmPassword !==
              personalDetailsForm.password && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: "19px",
                  position: "relative",
                  top: "-15px",

                  width: "100%",
                }}
              >
                Password does not match
              </FormLabel>
            )}

          <PrimaryButton
            disabled={!isFormValid() || isLoading}
            onClick={onSubmitButtonClick}
            // onClick={() => setScreen(7)}
          >
            {" "}
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

function mapStateToProps(state) {
  return {
    // isLoading: state.tunnel.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsScene);
