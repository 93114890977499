import React, { useEffect, useState } from "react";
import H1 from "../../../../../components/h1";
import BackButton from "../component/backButton";
import { Col, Form } from "react-bootstrap";
import PreviewAccordion from "../component/accordion";

import PrimaryButton from "../component/button";
import {
  firetoast,
  firetoastAggregator,
} from "../../../../../components/extras";
import Text from "../../../../../components/text";
import Onboarding from "../../../../../services/api/resources/onboarding";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import CountriesStatesLga from "../../../../../fixtures/countries_states_lgas.json";
import Lightbox from "react-image-lightbox";

const PersonalDetails = ({ setScreen, application, isLoading }) => {
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("jumbScreen", true);
          setScreen(2);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Preview
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Phone Number:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.phoneNumber}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Email Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.emailAddress}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Gender:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.gender}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          BVN:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.bvn}
        </Text>
      </div>
    </>
  );
};
const KycInformation = ({
  setScreen,
  application,
  isLoading,
  documentsList,
}) => {
  const getDocumentUrl = (documentLink) => {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const convertFileName = (fileName) => {
    if (fileName === "UTILITY_BILL") {
      return "Utility Bill";
    } else if (fileName === "PASSPORT_PHOTO") {
      return "Passport Photo";
    } else if (fileName === "ID_CARD") {
      return "Proof of Identity";
    }
  };

  const imgStyle = {
    borderRadius: "5px",
    height: "7rem",
    border: "1px solid grey",
    width: "7rem",
    cursor: "pointer",
    fontSize: "14px",
    objectFit: "cover",
  };

  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleDocumentClick = (documentName) => {
    setSelectedDocument(documentName);
    setShowLightbox(true);
  };
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("jumbScreen", true);
          setScreen(3);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Means of Identification:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.identificationType}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          ID number:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.identificationNumber}
        </Text>
      </div>
      {isLoading ? (
        ""
      ) : (
        <div
          style={{
            display: "flex",
            gap: "10px",

            flexWrap: "wrap",
          }}
        >
          {documentsList?.map((item, index) => {
            const {
              documentType,

              documentExtention,
              documentLink,
              documentName,
            } = item;

            return (
              <>
                <div onClick={() => handleDocumentClick(documentLink)}>
                  <Form.Label
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {convertFileName(documentType)}
                  </Form.Label>
                  <div style={imgStyle} key={index}>
                    {documentExtention === "png" ||
                    documentExtention === "jpeg" ||
                    documentExtention === "jpg" ||
                    documentExtention === "svg" ? (
                      <img
                        style={imgStyle}
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    ) : (
                      <embed
                        name="plugin"
                        style={imgStyle}
                        src={getDocumentUrl(documentLink)}
                        title={convertFileName(documentType)}
                        alt={convertFileName(documentType)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      {showLightbox && selectedDocument && (
        <>
          <Lightbox
            mainSrc={getDocumentUrl(selectedDocument)}
            onCloseRequest={() => setShowLightbox(false)}
          />
        </>
      )}
    </>
  );
};
const BusinessDetails = ({ setScreen, application, isLoading, state, lga }) => {
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("jumbScreen", true);
          setScreen(4);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Business Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.businessName}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Business Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.address}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          State:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : state}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Local Government Area:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : lga}
        </Text>
      </div>
    </>
  );
};
const NextOfKinDetails = ({ setScreen, application, isLoading }) => {
  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("jumbScreen", true);
          setScreen(5);
        }}
        style={{
          float: "right",
          cursor: "pointer",
          color: "#00425F",
          fontWeight: 600,
        }}
      >
        Edit
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Name:
        </Text>
        <Text style={{ color: "grey" }} mid>
          {isLoading ? "Loading..." : application?.firstName}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Home Address:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.address}
        </Text>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Phone Number:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.phoneNumber}
        </Text>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Text bold mid>
          Gender:
        </Text>
        <Text style={{ color: "grey" }} color="grey" mid>
          {isLoading ? "Loading..." : application?.gender}
        </Text>
      </div>
    </>
  );
};

const ApplicationReviewScene = ({ setScreen, props }) => {
  localStorage.setItem("jumbScreen", false);
  const applicationId = localStorage.getItem("applicationId");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(false);
  const [state, setState] = useState("");
  const [LocalGovernmentArea, setLocalGovernmentArea] = useState("");
  const onboarding = new Onboarding();

  const fetchApplication = async () => {
    setIsLoading(true);
    // applicationId
    const { status, response } = await onboarding.getApplicationAggregator(
      applicationId
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      const stateId = parseInt(response.businessDetails.state);
      setIsLoading(false);

      let state = CountriesStatesLga[0].states.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      const getActualStateObj = state.find((item) => item.id === stateId);
      const lga = CountriesStatesLga[0].states.find(
        (state) => state.id === getActualStateObj?.id
      );
      const lgaId = parseInt(response.businessDetails.localGovernmentArea);
      setLocalGovernmentArea(
        lga?.lgas?.find((item) => item.id === lgaId)?.name
      );
      setState(getActualStateObj?.name);
      setApplication(response);
    } else {
      setIsLoading(false);
    }
  };

  const navigateToOverview = () => {
    props.navigateTo("/agents/applications");
  };

  const handleSubmit = async () => {
    setLoading(true);
    // applicationId
    const payload = {};
    const { status, response } = await onboarding.saveAggregatorApplication(
      payload,
      applicationId
    );

    setLoading(false);
    if (status === ERROR_STATUS) {
      firetoast("error", `${response.description}`, "");
    } else if (status === SUCCESS_STATUS) {
      firetoastAggregator(
        "success",
        "Agent application has been successfully submitted",
        "",
        "View Application",
        navigateToOverview
      );
      localStorage.removeItem("applicationId");
      localStorage.removeItem("lga");
      localStorage.removeItem("passportPhoto");
      localStorage.removeItem("idCard");
      localStorage.removeItem("application");
      localStorage.removeItem("businessType");
      localStorage.removeItem("state");
      localStorage.removeItem("personalDetailsForm");
      localStorage.removeItem("kycDetailsForm");
      localStorage.removeItem("businessDetailsForm");
      localStorage.removeItem("nextOfKinDetailsForm");
      localStorage.removeItem("jumbScreen");
    }
  };

  useEffect(() => {
    fetchApplication();
  }, []);

  return (
    <>
      <BackButton onClick={() => setScreen(5)} />
      <main className="aggregator-container">
        <Col
          style={{
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Application Review
          </H1>
          <PreviewAccordion index="1" title={"Personal Details"}>
            <PersonalDetails
              application={application.applicantDetails}
              isLoading={isLoading}
              setScreen={setScreen}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"KYC Information"}>
            <KycInformation
              setScreen={setScreen}
              application={application.applicantDetails}
              documentsList={application.documentsList}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"Business Details"}>
            <BusinessDetails
              application={application.businessDetails}
              isLoading={isLoading}
              setScreen={setScreen}
              state={state}
              lga={LocalGovernmentArea}
            />
          </PreviewAccordion>
          <PreviewAccordion index={"0"} title={"Next of kin details"}>
            <NextOfKinDetails
              setScreen={setScreen}
              application={application.nextOfKin}
            />
          </PreviewAccordion>

          <PrimaryButton disabled={loading || isLoading} onClick={handleSubmit}>
            {loading ? "Processing..." : "Submit"}{" "}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default ApplicationReviewScene;
