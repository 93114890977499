import React, { useState } from "react";

import Platform from "../../../../services/api/resources/platform";
import { AppProvider } from "../../onboarding-setup/context";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import SetupNewAgentScene2 from "./setupNewAgentScene2";
import ApplicationReviewScene from "./applicationReviewScene";
import PersonalDetailsScene from "../upgradeAgentClass/personalDetailsScene";
import BusinessDetailsScene from "../upgradeAgentClass/businessDetailsScene";
import KycDocumentScene from "../upgradeAgentClass/kycDocumentScene";
import RefereeInfoScene from "./refereeDetailsScene";
import application from "../../../application";

class SetupAgent extends React.Component {
  constructor(props) {
    super(props);
    this.myElementRef = React.createRef();
    this.state = {
      screen: 1,
      // agentDetails: JSON.parse(localStorage.getItem('modifiedAgentDetails')),
      isLoading: false,
      application: null,
      movePage: false,
    };

    this.fetchApplication = this.fetchApplication.bind(this);
  }

  setScreen = (newScreen) => {
    this.setState({ screen: newScreen });
  };

  async fetchApplication() {
    const platform = new Platform();

    this.setState({ isLoading: true });
    const { status, response } = await platform.getActiveAgentsDetails(
      this.state.agentDetails.id
    );
    localStorage.setItem("agentInformation", JSON.stringify(response));
    this.setState({ isLoading: false });
    if (status === ERROR_STATUS) {
    } else if (status === SUCCESS_STATUS) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadApplication();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.loadApplication();
    }
  }

  loadApplication() {
    const agentInformation = JSON.parse(localStorage.getItem("currentAgent"));
    this.setState({ agentInformation });
  }

  render() {
    const { screen } = this.state;
    const application = JSON.parse(
      localStorage.getItem("modifiedAgentDetails")
    );

    return (
      <AppProvider>
        <div className="aggregator-layout">
          {screen === 1 && (
            <SetupNewAgentScene2
              application={this.state.agentDetails}
              setScreen={this.setScreen}
              props={this.props}
              isLoading={this.state.isLoading}
              fetchApplication={this.fetchApplication}
              movePage={this.movePage}
            />
          )}
          {screen === 2 && (
            <PersonalDetailsScene
              application={this.state.agentDetails}
              setScreen={this.setScreen}
              screen={screen}
              movePage={this.movePage}
            />
          )}
          {screen === 3 && (
            <BusinessDetailsScene
              application={this.state.agentDetails}
              setScreen={this.setScreen}
              screen={screen}
              movePage={this.movePage}
            />
          )}
          {screen === 4 && (
            <KycDocumentScene
              application={this.state.agentDetails}
              setScreen={this.setScreen}
              props={this.props}
              movePage={this.movePage}
            />
          )}
          {screen === 5 && (
            <RefereeInfoScene
              application={this.state.agentDetails}
              setScreen={this.setScreen}
              screen={screen}
              props={this.props}
              movePage={this.movePage}
            />
          )}
          {screen === 6 && (
            <ApplicationReviewScene
              application={this.state.agentDetails}
              setScreen={this.setScreen}
              screen={screen}
              // isLoading={this.state.isLoading}
              props={this.props}
              movePage={this.setState}
            />
          )}
        </div>
      </AppProvider>
    );
  }
}

export default SetupAgent;
