import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const notify = (message, state, position = "top-right") => {
  if (state === "warning") {
    toast.warning(message, {
      position: position,
    });
  } else if (state === "success") {
    toast.success(message, {
      position: position,
    });
  } else if (state === "error") {
    toast.error(message, {
      position: position,
    });
  }
};

export const firetoast = (status, title, desc) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    showConfirmButton: false,
    timer: 6000,
  });
};
export const firetoastAggregator = (
  status,
  title,
  desc,
  confirmButtonText,
  onClick
) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    // preConfirm: onClick,
    allowOutsideClick: false,

    confirmButtonText: confirmButtonText,
  }).then(() => onClick());
};
export const firetoastCustomButton = (
  status,
  title,
  desc,
  confirmButtonText,
  onClick
) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    buttonsStyling: false,
    customClass: {
      confirmButton: "custom-confirm-button-class",
    },

    allowOutsideClick: false,
    confirmButtonText: confirmButtonText,
  });
};
export const firetoastDynamic = (
  status,
  title,
  desc,
  confirmButtonText = "Close",
  onClick
) => {
  return Swal.fire({
    icon: status,
    title: title,
    html: `<div class="swal-desc">${desc}</div>`,
    confirmButtonText: confirmButtonText,
    showCancelButton: status === "error",
  }).then((e) => onClick(e));
};
