import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import InputText from "./input";
import { Link } from "react-router-dom";
import { Col } from 'react-bootstrap';
import { isBvnValid } from "./extras";
import { HandIcon } from "../../../../icons";

const CongratsModal = ({ show }) => {

    return (
        <Modal show={show}>
            <Modal.Body style={{ padding: "1rem" }}>
                <div style={{ padding: "4rem", alignContent: "center" }}>
                    <div style={{ display: "grid", placeContent: "center" }}>
                        {HandIcon()}
                    </div>
                    <h1
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginBottom: "1rem",
                            fontSize: "1.5rem",
                        }}
                    >
                        Congratulations!
                    </h1>
                    <p
                        style={{
                            color: "grey",
                            fontWeight: "200",
                            marginLeft: "1.5rem",
                        }}
                    >
                        Your account has been successfully setup
                    </p>
                    <Link to="/login">
                        <button
                            className="signupButton2"
                            style={{
                                marginTop: "3rem",
                                textAlign: "center",
                                color: "#479FC8",
                            }}
                        >
                            {" "}
                            Continue to Login{" "}
                        </button>
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CongratsModal;
