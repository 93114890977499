import { CATALOG_NEXT_API_BASE_URL, QUICKTELLER_TERMINAL_ID } from '../../../utils/api-properties'
import Requester from '../finch-requester'

const API_BASE_URL = CATALOG_NEXT_API_BASE_URL

export default class Catalog {
  constructor (props) {
    this.apiRequester = props ? props.apiRequester : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }

 
  getOptions (serviceId, terminalId) {
    return this.apiRequester.getWithoutToken({
      // endpoint: `${serviceId}`,
      endpoint: `services/${serviceId}`,
      args: {
        'withOptions': true,
      },
      headers: {
        terminalId
      },
      cache: false,
    })
  }

  getServices (categoryId, terminalId) {
    return this.apiRequester.getWithoutToken({
      endpoint: `services/categories/${categoryId}`,
      args: {
        'withservices': true
      },
      headers: {
        terminalId: QUICKTELLER_TERMINAL_ID
      },
      cache: true,
    })
  }


  search (searchTerm) {
    return this.apiRequester.getWithoutToken({
      // endpoint: `search`,
      endpoint: `services/search`,
      args: {
        s: searchTerm,
        countryCode: 'NG'
      },
      headers: { 
        terminalId: QUICKTELLER_TERMINAL_ID
      }
    })
  }


}
