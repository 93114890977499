import Numeral from 'numeral';
import { convertNgkToNgn } from './converters/currencies';

export function formatApprovalStatus(rawApprovalStatus) {
  return rawApprovalStatus.split('_').join(' ').toLowerCase();
}

export function formatAmount(amount) {
  return Numeral(amount).format('0,0.00');
}

export function basicAddition(valueA, valueB) {
  return parseInt(valueA) + parseInt(valueB);
}

export function settlementFormatter(value) {
  if (value) {
    return 'Settled';
  } else {
    return 'Unsettled';
  }
}

export function formatEmailOrPhone(emailOrPhone) {
  if (emailOrPhone.startsWith('080')) {
    return `234${emailOrPhone.slice(1)}`;
  }

  if (emailOrPhone.startsWith('070')) {
    return `234${emailOrPhone.slice(1)}`;
  }

  if (emailOrPhone.startsWith('081')) {
    return `234${emailOrPhone.slice(1)}`;
  }

  if (emailOrPhone.startsWith('090')) {
    return `234${emailOrPhone.slice(1)}`;
  }

  if (emailOrPhone.startsWith('234')) {
    return emailOrPhone;
  }

  return emailOrPhone;
}

export function formatPhoneNumber(phoneNumber, countryShortCode) {
  return '234' + phoneNumber.slice(-10);
}

export function formatPhoneNumberWithCountryCode(phoneNumber) {
  if (phoneNumber.startsWith('234')) {
    return `0${phoneNumber.slice(3)}`;
  }
  return phoneNumber;
}

export function handleLongNumber(value) {
  return '`' + value;
}

export function normalizeToTitleCase(rawString) {
  let str = rawString.toLowerCase().split('-');

  let final = [];

  for (let word of str) {
    final.push(word.charAt(0).toUpperCase() + word.slice(1));
  }

  return final.join(' ');
}

export function formatDate(date) {
  let newDate = new Date(date),
    month = '' + (newDate.getMonth() + 1),
    day = '' + newDate.getDate(),
    year = newDate.getFullYear();

  let strTime = formatAMPM(newDate);

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-') + ' ' + strTime;
}

export function formatWalletDate(date) {
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let newDate = new Date(date),
    month = '' + newDate.getMonth(),
    day = '' + newDate.getDate(),
    year = newDate.getFullYear();

  let strTime = formatAMPM(newDate);

  if (day.length < 2) day = '0' + day;

  return [day, monthNames[month], year, strTime].join(' ');
}

function formatAMPM(date) {
  var hours = '' + date.getHours();
  var minutes = '' + date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + '' + ampm;
  return strTime;
}

export function formatDateForDateRange(date) {
  let newDate = new Date(date),
    month = '' + (newDate.getMonth() + 1),
    day = '' + newDate.getDate(),
    year = newDate.getFullYear(),
    hours = '' + newDate.getHours(),
    minutes = '' + newDate.getMinutes(),
    seconds = '' + newDate.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hours.length < 2) hours = '0' + hours;
  if (minutes.length < 2) minutes = '0' + minutes;
  if (seconds.length < 2) seconds = '0' + seconds;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

//gets endDate with time 00:00:00
export function specialDateRangeFormatter_StartDate(date) {
  let newDate = new Date(date),
    month = '' + (newDate.getMonth() + 1),
    day = '' + newDate.getDate(),
    year = newDate.getFullYear(),
    hours = '' + newDate.getHours(),
    minutes = '' + newDate.getMinutes(),
    seconds = '' + newDate.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  hours = '00';
  minutes = '00';
  seconds = '01';

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function specialDateRangeFormatter_StartDateDate(date) {
  if (!date) return;
  let newDate = new Date(date),
    month = '' + (newDate.getMonth() + 1),
    day = '' + newDate.getDate(),
    year = newDate.getFullYear(),
    hours = '' + newDate.getHours(),
    minutes = '' + newDate.getMinutes(),
    seconds = '' + newDate.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  hours = '00';
  minutes = '00';
  seconds = '01';

  return `${year}-${month}-${day}`;
}

//gets endDate with time 23:59:00
export function specialDateRangeFormatter_EndDate(date) {
  let newDate = new Date(date),
    month = '' + (newDate.getMonth() + 1),
    day = '' + newDate.getDate(),
    year = newDate.getFullYear(),
    hours = '' + newDate.getHours(),
    minutes = '' + newDate.getMinutes(),
    seconds = '' + newDate.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  hours = '23';
  minutes = '59';
  seconds = '00';

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function handleStatusFormat(value) {
  if (value === 'PENDING_VALUE_PROVISIONING') {
    return (value = 'Pending');
  } else if (value === 'CLOSED_AND_SUCCESSFUL' || value === '20200') {
    return (value = 'Successful');
  } else if (value === 'CLOSED_BUT_FAILED') {
    return (value = 'Failed');
  } else {
    return `${value.charAt(0).toUpperCase()}${value.substr(1).toLowerCase()}`;
  }
}

export function handleReversalTransactionStatusFormat(status) {
  if (status === 'PENDING_REVERSAL') {
    return (status = 'Pending');
  } else if (status === 'CLOSED_AND_SUCCESSFUL') {
    return (status = 'Successful');
  } else if (status === 'CLOSED_BUT_FAILED' || status === 'CLOSE_BUT_RETRY') {
    return (status = 'Failed');
  } else {
    return `${status.charAt(0).toUpperCase()}${status.substr(1).toLowerCase()}`;
  }
}

export function handleTransactionTypeFormat(value) {
  if (value === 'CASH_IN' || value === 19) {
    return (value = 'Cash In');
  } else if (value === 'CASH_OUT') {
    return (value = 'Cash Out');
  } else if (value === 'PAYPOINT_FUND') {
    return (value = 'Paypoint Fund');
  } else if (value === 'COMMISSION_UNLOAD') {
    return (value = 'Commission Unload');
  } else if (value === 'ACCOUNT_OPENING') {
    return (value = 'Account Opening');
  } else if (value === 1) {
    return (value = 'Bills');
  } else if (value === 2) {
    return (value = 'Transfer');
  } else if (value === 3) {
    return (value = 'Recharge');
  } else if (value === 4) {
    return (value = 'Fund');
  } else if (value === 5) {
    return (value = 'Cash Out');
  } else if (value === 6) {
    return (value = 'Wallet To Wallet');
  } else if (value === 7) {
    return (value = 'Cash In');
  } else if (value === 8) {
    return (value = 'Commission Unload');
  } else if (value === 9) {
    return (value = 'Paypoint Fund');
  } else if (value === 10) {
    return (value = 'Reversal');
  } else if (value === 11) {
    return (value = 'Refund');
  } else if (value === 12) {
    return (value = 'Retrieval');
  } else if (value === 13) {
    return (value = 'Account Opening');
  } else if (value === 14) {
    return (value = 'Distribute');
  } else if (value === 16) {
    return (value = 'NIP Transfer');
  } else if (value === 17) {
    return (value = 'POS Purchase');
  } else return value;
}

export function handleCustomerIdFormat(value) {
  if (typeof value === 'undefined') return value;
  if (value.includes('+234')) {
    return `0${value.slice(4)}`;
  } else if (value.includes('234')) {
    return `0${value.slice(3)}`;
  } else return value;
}

export function handleCustomerIdFormatTwo(value) {
  if (typeof value === 'undefined') return value;
  if (value.includes('+234')) {
    return `0${value.slice(4)}`;
  } else return value;
}

export function calculatePercentage(count, totalCount) {
  let percent;
  typeof count === 'undefined' ||
  typeof totalCount === 'undefined' ||
  count === 0
    ? (percent = 0)
    : (percent = count / totalCount);
  let percentage = percent * 100;
  return Math.round(percentage);
}

export function percentageIncreaseOrDecrease(prevMonthValue, monthValue) {
  if (prevMonthValue === 0) {
    return 0;
  } else {
    if (monthValue > prevMonthValue) {
      const diff = monthValue - prevMonthValue;
      const percentageDiff = (diff / prevMonthValue) * 100;
      return Math.round(percentageDiff);
    } else {
      const diff = prevMonthValue - monthValue;
      const percentageDiff = (diff / prevMonthValue) * 100;
      return Math.round(percentageDiff);
    }
  }
}

export const refineGraphData = (graphData) => {
  if (!graphData) return;
  return graphData.map((data) => convertNgkToNgn(data.volume));
};

export const refineDistributeData = (distributeData) => {
  if (!distributeData) return;
  return distributeData.map((data) => parseInt(data.amount));
};

export const refineGraphDataForDate = (graphData) => {
  if (!graphData) return;
  return graphData.map((data) => {
    return isNaN(data.date) ? data.date : `Week ${data.date}`;
  });
};

export const findArrayIndexWithMaximumLength = (array) => {
  let max = 0;
  let index = 0;

  array.forEach(function (a, i) {
    if (a.length > max) {
      max = a.length;
      index = i;
    }
  });
  return index;
};

export const nameFormatter = (name) => {
  const nameArray = name.split(' ');
  return nameArray;
};

export const removeSpacesFromString = (stringWord) => {
  // Split the sentence into an array of words
  const words = stringWord.split(' ');

  // Join the words into a single string without spaces
  const sentenceWithoutSpaces = words.join('');

  return sentenceWithoutSpaces;
}

export const manageProfileDateFormatter = (date) => {
  const dateArray = date.split('-');
  return [`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`];
};

export function formatDate2(inputDate, slash = '/') {
  const date = new Date(inputDate);
  return `${('0' + date.getDate()).slice(-2)}${slash}${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}${slash}${date.getFullYear()}`;
}

export function formatDateTime2(inputDate) {
  const date = new Date(inputDate);
  return `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
}

export function formatDateTime3(inputDate) {
  const date = new Date(inputDate);
  return `${date.getFullYear()}-${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}-${('0' + date.getDate()).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
}

export function formatDateTime(inputDate) {
  const date = new Date(inputDate.replace(/\s/, 'T'));
  return `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
}

export function sentenceCapitalization(text) {
  if (!text) return;
  let sentence = text.toLowerCase().split(' ');
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1) + ' ';
  }
  return sentence;
}

export function handleTransactionStatus(value) {
  if (value === '00' || value === '20000') {
    return (value = 'Successful');
  } else if (value === '01') {
    return (value = 'Failed');
  } else if (value === '02') {
    return (value = 'Pending');
  } else if (value === '03') {
    return (value = 'Duplicate');
  } else if (value === '04') {
    return (value = 'Not Found');
  } else return 'Pending';
}

export function dateOrdinal(date) {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function processWebpayErrorResponse(value) {
  if (value === 'XS1') {
    return `Your payment has exceeded the time required to pay.`;
  }
  if (value === 'Z1') {
    return `Transaction Error.`;
  }
  if (value === 'Z5') {
    return `Payment already processed.`;
  }
  if (value === 'T1') {
    return `Cannot generate OTP.`;
  }
  if (value === 'X03') {
    return `Amount greater than daily transaction limit.`;
  }
  if (value === '54') {
    return `Expired Card.`;
  }
  if (value === '55') {
    return `Incorrect Pin.`;
  }
  if (value === '91') {
    return `Issuer or Switch Inoperative.`;
  }
  if (value === '56') {
    return `No card Record.`;
  }
  if (value === 'Z81') {
    return `No bin was found for this pan.`;
  }
  return value;
}

export function formatDomainTypeId(value) {
  if (value === 1) {
    return 'Platform Provider';
  }
  if (value === 2) {
    return 'Super Agent';
  }
  if (value === 3) {
    return 'Aggregator';
  }
  if (value === 4) {
    return 'Agent';
  }
}
