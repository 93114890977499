import Numeral from 'numeral';
import qs from 'qs';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import MdAdd from 'react-ionicons/lib/MdAdd';
import MdArrowDropdownCircle from 'react-ionicons/lib/MdArrowDropdownCircle';
import { SHOW_STATIC_ACCOUNT } from '../../../../../utils/api-properties';
import { copybox } from '../scenes/fund-wallet/fund-wallet';

const UNLOAD_COMMISSION = 'unload-commission';

const COLOUR = 'white';

export default (props) => {
  const deviceIsTab =
    qs.parse(window.location.search)['?mobile'] || window.innerWidth <= 992;
  const currentAgent = JSON.parse(localStorage.getItem('currentAgent')) || [];
  const [show, setShow] = useState(false);
  const handleCopyAccountNumber = (agentId) => {
    const filter = currentAgent.staticAccounts.filter(
      (data) => data.agentId === agentId
    );
    setShow(true);
    navigator.clipboard.writeText(filter[0].accountNumber);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };
  return (
    <>

      <Row
        style={{
          color: COLOUR,
          marginBottom: props.deviceIsMobile ? '10px' : '0px',
          width: props.deviceIsMobile ? '90vw' : '78vw',
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 'nowrap',
            justifyContent: 'flex-start',
            overflowX: 'auto',
            overflowY: 'hidden',
            padding: '15px',
            paddingBottom: props.deviceIsMobile ? '5px' : '5px',
            borderRadius: '4px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
              height: '110px',
              marginRight: '30pt',
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? '13px'
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: '5px',
              }}
            >
              LEDGER BALANCE
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: props.deviceIsMobile
                  ? '22px'
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: '0px',
                marginLeft: '0px',
              }}
            >
              ₦{Numeral(props.wallet.ledger_balance).format('0,0.00')}
              {/* <h6 style={{fontSize: '10px', opacity: !props.wallet.wallet_date ? 0.01 : 1}}><MdArrowRoundUp fontSize={process.env.REACT_APP_MID_FONT_SIZE} color='white' style={{marginRight: '5px'}} />Available balance: ₦{Numeral(props.wallet.current_balance).format('0,0.00')} </h6>
          <h6 style={{fontSize: '10px', opacity: !props.wallet.wallet_date ? 0.01 : 1}}><MdArrowRoundDown fontSize={process.env.REACT_APP_MID_FONT_SIZE} color='white' style={{marginRight: '5px'}}/>Ledger balance: ₦{Numeral(props.wallet.ledger_balance).format('0,0.00')} </h6> */}
            </div>
            <div
              className={`grow ${props.suggestFundWallet ? 'heart-beat' : ''}`}
              style={{
                alignItems: 'center',
                backgroundColor: props.isAgentNew
                  ? process.env.REACT_APP_YELLOW_COLOUR
                  : 'white',
                border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
                borderRadius: '4px',
                color: props.disabled
                  ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                  : process.env.REACT_APP_BLUE_COLOUR,
                cursor: props.disabled ? 'not-allowed' : 'pointer',
                display: 'flex',
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                fontWeight: 'bold',
                height: '40px',
                padding: '7.5px 10px 7.5px 10px',
                width: '150px',
              }}
              aria-controls='fund-wallet-tab'
              // aria-expanded={props.activeTab === FUND_WALLET}
              onClick={props.onFundWalletClick}
            // onClick={props.handleModalOpen}
            >
              <MdAdd
                color={
                  props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR
                }
                fontSize={process.env.REACT_APP_MID_FONT_SIZE}
                style={{ marginRight: '5px' }}
              />
              FUND WALLET
            </div>
          </div>

          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: '#46768B',
              right: '30px',
              position: 'relative',
              height: '90px',
              width: '1pt',
            }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
              height: '110px',
              marginRight: '30pt',
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? '13px'
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: '5px',
                // marginBottom: '17px'
              }}
            >
              AVAILABLE AMOUNT
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: props.deviceIsMobile
                  ? '22px'
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: '0px',
                marginLeft: '0px',
                width: props.deviceIsMobile && '150px',
              }}
            >
              ₦{Numeral(props.wallet.current_balance).format('0,0.00')}
            </div>
          </div>
          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: '#46768B',
              right: '30px',
              position: 'relative',
              height: '90px',
              width: '1px',
            }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
              height: '110px',
              marginRight: '30pt',
            }}
          >
            <div
              style={{
                cursor: !props.disabled ? 'pointer' : 'not-allowed',
                fontSize: props.deviceIsMobile
                  ? '13px'
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: '5px',
                textDecoration: 'underline',
              }}
              onClick={props.onSettlementHistoryClick}
            >
              COMMISSIONS EARNED
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: props.deviceIsMobile
                  ? '22px'
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: '0px',
                marginLeft: '0px',
                width: props.deviceIsMobile && '150px',
              }}
            >
              ₦{Numeral(props.wallet.commissions_earned).format('0,0.00')}
            </div>
            <div
              className={`grow`}
              style={{
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '4px',
                border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
                color: props.disabled
                  ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                  : process.env.REACT_APP_BLUE_COLOUR,
                cursor: props.disabled ? 'not-allowed' : 'pointer',
                display: 'flex',
                fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
                fontWeight: 'bold',
                height: '40px',
                padding: '7.5px 10px 7.5px 10px',
                width: '100px',
              }}
              aria-controls='unload-commission-tab'
              aria-expanded={props.activeTab === UNLOAD_COMMISSION}
              onClick={props.onUnloadCommissionClick}
            >
              <MdArrowDropdownCircle
                color={
                  props.disabled
                    ? process.env.REACT_APP_LIGHT_GREY_COLOUR
                    : process.env.REACT_APP_BLUE_COLOUR
                }
                fontSize={process.env.REACT_APP_MID_FONT_SIZE}
                style={{ marginRight: '5px' }}
              />
              UNLOAD
            </div>
          </div>
          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: '#46768B',
              right: '30px',
              position: 'relative',
              height: '90px',
              width: '1px',
            }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
              height: '110px',
              marginRight: '30pt',
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? '13px'
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: '5px',
              }}
            >
              UNSETTLED COMMISSION
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: props.deviceIsMobile
                  ? '22px'
                  : process.env.REACT_APP_BIG_FONT_SIZE,
                padding: 0,
                marginRight: '0px',
                marginLeft: '0px',
                width: props.deviceIsMobile && '150px',
              }}
            >
              ₦{Numeral(props.wallet.unsettled_commission).format('0,0.00')}
            </div>
          </div>

          <div
            hidden={props.deviceIsMobile}
            style={{
              backgroundColor: '#46768B',
              right: '30px',
              position: 'relative',
              height: '90px',
              width: '1pt',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
              height: '110px',
              marginRight: '30pt',
            }}
          >
            <div
              style={{
                fontSize: props.deviceIsMobile
                  ? '13px'
                  : process.env.REACT_APP_MID_FONT_SIZE,
                lineHeight: process.env.REACT_APP_MID_LINE_HEIGHT,
                marginTop: '5px',
              }}
            >
              ACCOUNT NUMBER
            </div>
            {SHOW_STATIC_ACCOUNT && currentAgent.staticAccounts && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <article className='fundwallet-copy-container-dashboard'>
                    <div
                      style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',

                        fontSize: deviceIsTab ? '10px' : '13px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span style={{ color: '#00B8DE' }}>
                          {currentAgent?.staticAccounts[0].bankName}
                        </span>
                        <span
                          title='Click to copy account number'
                          onClick={() =>
                            handleCopyAccountNumber(
                              currentAgent?.staticAccounts[0].agentId
                            )
                          }
                          style={{
                            fontSize: deviceIsTab ? '12px' : '14px',
                            cursor: 'pointer',
                          }}
                        >
                          {currentAgent?.staticAccounts[0].accountNumber}
                        </span>
                      </div>
                    </div>
                  </article>

                  {show ? <div style={copybox}>Copied!</div> : ''}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
