import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { notify } from "../../../../components/extras";
import H1 from "../../../../components/h1";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import PrimaryButton from "../component/button";

import { stopwatch } from "../../../../App";
import HeaderSignup from "../../../../components/header-signup";
import { CAC_REGISTRATION } from "../../../../constants";
import { TRANSACTION_INITIATE_CLICK } from "../../../../constants/analytics";
import { logEvent } from "../../../../core/logger";
import CountriesStatesLga from "../../../../fixtures/countries_states_lgas.json";
import Onboarding from "../../../../services/api/resources/onboarding";
import Transaction from "../../../../services/api/resources/transaction";
import { QUICKTELLER_TERMINAL_ID } from "../../../../utils/api-properties";
import { generateChecksum } from "../../../../utils/helpers";
import BackButton from "../component/backButton";
import {
  isBusinessAddressValid,
  isCityValid,
  isEmailValid,
  isNameValid,
  isStreetNumber,
} from '../component/extras';
import SignUp from '../component/signupText';
import Stepper from '../component/stepper';
import DocumentUploader from "../../../home/scenes/dashboard/fragments/onboarding-application-setup/component/customeImage4";
import { TEN_MB } from '../../../../constants';
import { DatePickerComponent } from '../component/datePickerComponent';
import InputText from "../component/input";
import { SelectInputState } from "../component/stateSelect";

const MAX_BUSINESS_NAME_LENGTH = 50;
const cacInitiateRequest = JSON.parse(localStorage.getItem('cacInitiateRequest'));

const parseDate = (dateStr) => {
  const date = new Date(dateStr);
  return isNaN(date.getTime()) ? null : date;
};

const BusinessDetailsScene = ({ setScreen, application }) => {
  const onboarding = new Onboarding();
  const transaction = new Transaction();

  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[  isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [showLightboxLocation, setShowLightboxLocation] = useState(false);
  const [location, setLocation] = useState(null);
  const [loadingLocationCancel, setLoadingLocationCancel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [fileFormatLocation, setFileFormatLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const cacRegistrationForm = JSON.parse(
    localStorage.getItem("cacRegistrationForm")
  );

  const personalDetailsForm = JSON.parse(
    localStorage.getItem("personalDetailsForm")
  );



  const initialFormState = {
    email: application ? application?.businessDetails?.email : "",
    businessLine: application ? application?.businessDetails?.businessLine : "",
    businessDate: application
      ? parseDate(application?.businessDetails?.businessDate)
      : "",
    companyState: application ? application?.businessDetails?.companyState : "",
    city: application ? application?.businessDetails?.city : "",
    street: application ? application?.businessDetails?.street : "",
    address: application ? application?.businessDetails?.address : "",
    businessLocation: "",
  };

  const [businessDetailsForm, setBusinessDetailsForm] = useState(() => {
    const savedForm = JSON.parse(localStorage.getItem("businessDetailsForm"));
    if (savedForm) {
      return {
        ...initialFormState,
        ...savedForm,
        businessDate: savedForm.businessDate
          ? parseDate(savedForm.businessDate)
          : "",
      };
    }
    return initialFormState;
  });

  useEffect(() => {
    localStorage.setItem(
      "businessDetailsForm",
      JSON.stringify({
        ...businessDetailsForm,
        businessDate: businessDetailsForm.businessDate
          ? businessDetailsForm.businessDate.toISOString()
          : "",
      })
    );
  }, [businessDetailsForm]);

  const updateFormField = (params) => {
    const newForm = {
      ...businessDetailsForm,
      ...params,
    };
    setBusinessDetailsForm(newForm);
  };

  const fetchAllStates = async () => {
    const stateArray = CountriesStatesLga[0].states.map((state) => ({
      name: state.name,
      id: state.id,
    }));
    setStates(stateArray);
  };

  const getDocumentUrl = (document) => {
    let convertedDocument = document?.documentLink?.replace(
      "/data",
      process.env.REACT_APP_ONBOARDING_BASE_URL
    );
    return convertedDocument;
  };

  const getDocumentTypeLocation = (file) => {
    const srcSplitted = file?.name
      ? file?.name?.split(".")
      : file?.documentName?.split(".");

    const slittedType = file
      ? srcSplitted[srcSplitted.length - 1].split("-")[0]
      : null;
    setFileFormatLocation(slittedType);
  };

  const fileInputRefLocation = useRef(null);

  const handleCancelLocation = async () => {
    setLoadingLocationCancel(true);

    setBusinessDetailsForm((prevForm) => {
      const updatedForm = { ...prevForm, businessLocation: "" };
      localStorage.setItem("businessDetailsForm", JSON.stringify(updatedForm));
      return updatedForm;
    });
  };

  const handleClickLocation = () => {
    fileInputRefLocation.current.click();
  };

  const handleLocationChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      if (file.size > TEN_MB) {
        setError(true);
        return;
      } else {
        setIsLoadingLocation(true);
        reader.onloadend = () => {
          const result = reader.result;
          setBusinessDetailsForm((prevForm) => ({
            ...prevForm,
            businessLocation: result,
          }));
          setIsLoadingLocation(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const congratsModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setIsLoading(false);
    setShowModal(false);
  };

  const isFormValid = () => {
    return (
      businessDetailsForm.city &&
      businessDetailsForm.email &&
      isEmailValid(businessDetailsForm) &&
      businessDetailsForm.businessDate &&
      businessDetailsForm.street &&
      isStreetNumber(businessDetailsForm.street) &&
      businessDetailsForm.businessAddress &&
      isBusinessAddressValid(businessDetailsForm) &&
      businessDetailsForm.businessLocation
    );
  };

  const onPayCacPaymentRequest = async (formData) => {
    setIsPaymentProcessing(true);
    const paymentItemCode = "IFISCAC";
    const amount = 0;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: CAC_REGISTRATION,
    });

    stopwatch.start();

    if (
      cacRegistrationForm.proposedName &&
      cacRegistrationForm.businessLine &&
      isNameValid(cacRegistrationForm.proposedName)
    ) {
      setErrorMessage("");

      try {
        const { status, response } =
          await transaction.processCacRegistrationPayment(
            {
              amount: 0,
              paymentInstrumentType: "CASH",
              businessName: cacRegistrationForm.proposedName,
              lineOfBusiness: cacRegistrationForm.lineOfBusiness,
              paymentItemCode: "IFISCAC",
              terminalId: QUICKTELLER_TERMINAL_ID,
              agentCommissionDue: "string",
              borneBy: "string",
              customerMsisdn: "string",
              customerType: "string",
              fee: "17500",
              gender: personalDetailsForm.gender,
              narration: "string",
              reference: "string",
              registrationPayload: {
                businessCommencementDate: businessDetailsForm.businessDate,
                companyAddress: businessDetailsForm.businessAddress,
                companyCity: businessDetailsForm.city,
                companyEmail:  businessDetailsForm.email,
                companyState: businessDetailsForm.state,
                companyStreetNumber: personalDetailsForm.streetNumber,
                lineOfBusiness: businessDetailsForm.lineBusiness,
                meansOfId: "string",
                passport: "string",
                proposedOption1: "string",
                proprietorCity: "string",
                proprietorDob: personalDetailsForm.dob,
                proprietorEmail: personalDetailsForm.email,
                proprietorFirstname: personalDetailsForm.firstname,
                proprietorGender: personalDetailsForm.gender,
                proprietorLga: personalDetailsForm.lga,
                proprietorNationality: personalDetailsForm.nationality,
                proprietorOthername: personalDetailsForm.nationality,
                proprietorPhonenumber: personalDetailsForm.phoneNumber,
                proprietorPostcode: personalDetailsForm.phoneNumber,
                proprietorServiceAddress: personalDetailsForm.phoneNumber,
                proprietorState: personalDetailsForm.state, 
                proprietorStreetNumber:personalDetailsForm.streetNumber,
                proprietorSurname: personalDetailsForm.surname,
                signature: personalDetailsForm.signature,
                supportingDoc: personalDetailsForm.signature,
                transactionRef: "string"
              },
            },
            generateChecksum(
              `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
            ),
            deviceId
          );
        if (status === ERROR_STATUS) {
          notify("Error occurred", "error");
        } else if (status === SUCCESS_STATUS) {
          notify("Proposed name registered successfully", "success");
        }
      } catch (error) {
        notify("An unexpected error occurred. Please try again.");
        console.error(error);
      }
    }
    setIsPaymentProcessing(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    congratsModal();
  };

  useEffect(() => {
    fetchAllStates();
  }, []);

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(3)} />
      </div>
      <main className="onboarding-container">
        <Col style={{ marginBottom: "20px", width: "80%", margin: "0 auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
          </div>
          <H1
            style={{ textAlign: "left", fontWeight: 600, marginBottom: "1rem" }}
          >
            Business Details
          </H1>
          <InputText
            label={"Company email Address"}
            onChange={(e) => updateFormField({ email: e.target.value })}
            labelColor={
              !businessDetailsForm.email || isEmailValid(businessDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.email || isEmailValid(businessDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company email address"}
            value={businessDetailsForm.email}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
            disabled={false}
          />
          <InputText
            disabled={true}
            label={"Line of Business"}
            placeholder={"Enter line of business"}
            onChange={(e) => updateFormField({ businessLine: e.target.value })}
            maxLength={200}
            value={cacRegistrationForm?.businessLine}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
          >
            <DatePickerComponent
              onChange={(date) => updateFormField({ businessDate: date })}
              selected={businessDetailsForm.businessDate}
              label={"Business Commencement Date"}
              placeholder="DD-MM-YYYY"
              size={`100%`}
              format={`dd-MM-yyyy`}
              isClearable={true}
              showTimeInput={true}
              textStyle={"1.0rem"}
              iconStyle={"0.2rem"}
              dateType={"startDate"}
              preloadDate={false}
            />
          </div>
          <SelectInputState
            label={"Company State"}
            option={states}
            getState={localStorage.getItem("companyState")}
            value={businessDetailsForm.state}
            placeholder={"state"}
            onChange={(e) => {
              const selectedState = states.find(
                (value) => value.name === e.target.value
              );
              localStorage.setItem("companyState", selectedState.name);
              setSelectedState(selectedState.id);
              updateFormField({ companyState: selectedState.id });
            }}
          />
          <InputText
            label={"Company City"}
            labelColor={
              !businessDetailsForm.city || isCityValid(businessDetailsForm.city)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.city || isCityValid(businessDetailsForm.city)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company city"}
            onChange={(e) => updateFormField({ city: e.target.value })}
            maxLength={200}
            value={businessDetailsForm.city}
          />
          <InputText
            label={"Company Street"}
            labelColor={
              !businessDetailsForm.street ||
              isStreetNumber(businessDetailsForm.street)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.street ||
              isStreetNumber(businessDetailsForm.street)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company street number"}
            onChange={(e) => updateFormField({ street: e.target.value })}
            maxLength={200}
            value={businessDetailsForm.street}
          />
          <InputText
            label={"Company Address"}
            labelColor={
              !businessDetailsForm.businessAddress ||
              isBusinessAddressValid(businessDetailsForm)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !businessDetailsForm.businessAddress ||
              isBusinessAddressValid(businessDetailsForm)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            placeholder={"Enter your Company address"}
            onChange={(e) =>
              updateFormField({ businessAddress: e.target.value })
            }
            maxLength={200}
            value={businessDetailsForm.businessAddress}
          />
          <DocumentUploader
            label={"Image of Business Location"}
            loading={isLoadingDoc || isLoadingLocation}
            loadingCancelDoc={loadingLocationCancel}
            showLightbox={showLightboxLocation}
            fileFormat={fileFormatLocation}
            state={
              businessDetailsForm?.businessLocation
                ? businessDetailsForm?.businessLocation
                : ""
            }
            setShowLightbox={setShowLightboxLocation}
            onChange={handleLocationChange}
            handleDocument={handleClickLocation}
            fileInputRef={fileInputRefLocation}
            handleCancel={handleCancelLocation}
            error={error}
          />
          <PrimaryButton
            onClick={handleSubmit}
            // onClick={onPayCacPaymentRequest}
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? "Processing..." : "Proceed to Payment"}
          </PrimaryButton>
        </Col>
      </main>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="true"
      >
        <Modal.Body style={{ padding: "1rem" }}>
          <div style={{ padding: "4rem", alignContent: "center" }}>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              }}
            >
              Payment Details
            </h1>
            <InputText
              disabled={true}
              label={"Amount"}
              placeholder={"amount"}
              maxLength={200}
              value={cacInitiateRequest?.amount}
            />
            <InputText
              disabled={true}
              label={"Reason"}
              placeholder={"reason"}
              maxLength={200}
              value={"CAC Registration"}
            />
            <button
              className="signupButton2"
              style={{
                marginTop: "3rem",
                textAlign: "center",
                color: "white",
                backgroundColor: "#00425f",
                width: "100%",
              }}
              onClick={onPayCacPaymentRequest}
              isPaymentProcessing={isPaymentProcessing}
            >
              {isPaymentProcessing ? "Processing..." : "Proceed"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessDetailsScene;
