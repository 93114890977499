import React, { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

import IosRefresh from 'react-ionicons/lib/IosRefresh';

import {
  isResidentialAddressValid,
  isBusinessLgaValid,
  isBvnValid,
  isEmailValid,
  isNameValid,
  stripNonNumbers,
} from '../../onboarding-setup/component/extras';

import Title from './title';
import CountriesStatesLga from '../../../../fixtures/countries_states_lgas.json';

import Platform from '../../../../services/api/resources/platform';
import BackButton from '../../onboarding-setup/component/backButton';
import PrimaryButton from '../../onboarding-setup/component/button';
import InputText2 from '../../onboarding-setup/component/input2';
import { SelectInputLga2, SelectInputState2 } from '../../onboarding-setup/component/stateSelect2';
import H1 from '../../../../components/h1';
import { NIGERIA_PHONE_PREFIX } from '../../../../constants';
import { SUCCESS_STATUS } from '../../../../constants/api';
import phoneValidators from '../../../../utils/validators/phone-validators';


const PersonalDetailsScene = ({ setScreen, application }) => {
  const moveScreen = localStorage.getItem('moveScreen');
  const platform = new Platform();
  const [loadingLgas, setLoadingLgas] = useState(false);
  const [fetchLga, setFetchLga] = useState([]);
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [fieldsFocused, setFieldsFocused] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const updatedPersonaldetailsForm = JSON.parse(
    localStorage.getItem('personalDetailsFormUpgrade')
  );

  let modifiedAgentDetails = JSON.parse(
    localStorage.getItem('modifiedAgentDetails')
  );

  const [personalDetailsForm, setPersonalDetailsForm] = useState({
    motherMaidenName:
      application?.businessContact?.motherMaidenName ||
      updatedPersonaldetailsForm?.motherMaidenName,

    localGovernmentArea:
      application?.homeAddressLgaId ||
      updatedPersonaldetailsForm?.localGovernmentArea,

    state: application?.homeAddressStateId || updatedPersonaldetailsForm?.state,

    residentialAddress:
      application?.residentialAddress ||
      updatedPersonaldetailsForm?.residentialAddress,

  });

  localStorage.setItem(
    'personalDetailsFormUpgrade',
    JSON.stringify(personalDetailsForm)
  );
  const updateFormField = (params) => {
    const newForm = {
      ...personalDetailsForm,
      ...params,
    };

    setPersonalDetailsForm(newForm);
  };

  const isFormValid = (type) => {
    return (
      isNameValid(personalDetailsForm.motherMaidenName) &&
      personalDetailsForm.state &&
      personalDetailsForm.localGovernmentArea &&
      personalDetailsForm.residentialAddress &&
      isResidentialAddressValid(personalDetailsForm)
    );

    // if (type === 'prestige') {
    //   return (
    //     isNameValid(personalDetailsForm.motherMaidenName) &&
    //     personalDetailsForm.state &&
    //     personalDetailsForm.localGovernmentArea &&
    //     personalDetailsForm.residentialAddress
    //   );
    // } else if (type === 'Standard') {
    //   return isNameValid(personalDetailsForm.motherMaidenName);
    // }

    // return false;
  };

  const handleSubmit = async () => {
    moveScreen ? setScreen(6) : setScreen(3);
  };

  const fetchAllStates = async (countryId) => {
    const stateArray = CountriesStatesLga[0].states.map((state) => {
      return {
        name: state.name,
        id: state.id,
      };
    });

    setStates(stateArray);
  };

  const getLga = async (stateId) => {
    setLoadingLgas(true);
    const lgasResponseObj = await platform.retrieveLgas(
      personalDetailsForm.state || selectedState
    );
    const lgasResponseStatus = lgasResponseObj.status;
    const lgasResponse = lgasResponseObj.response;
    setLoadingLgas(false);
    if (lgasResponseStatus === SUCCESS_STATUS) {
      setFetchLga(lgasResponse);
    }
  };

  useEffect(() => {
    getLga();
  }, [personalDetailsForm.state, selectedState]);

  const upgradeTo = localStorage.getItem('upgradeTo');

  useEffect(() => {
    fetchAllStates();
  }, []);

  return (
    <>
      <BackButton
        onClick={() => {
          localStorage.removeItem('personalDetailsFormUpgrade');
          // localStorage.removeItem('state');
          // localStorage.removeItem('lga');
          setScreen(1);
        }}
      />
      <main className='aggregator-container'>
        <Col
          style={{
            marginBottom: '20px',
            margin: '0 auto',
          }}
        >
          <Title upgradeTo={upgradeTo} />
          <H1
            style={{
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: '1rem',
            }}
          >
            Personal Information
          </H1>

          <InputText2
            label={`Mother's Maiden Name`}
            onChange={(e) => {
              const inputValue = e.target.value;
              // const onlyLetters = inputValue.replace(/[^A-Za-z]/g, '');
              updateFormField({ motherMaidenName: inputValue });
            }}
            labelColor={
              !personalDetailsForm.motherMaidenName ||
                isNameValid(personalDetailsForm.motherMaidenName)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!personalDetailsForm.motherMaidenName ||
              isNameValid(personalDetailsForm.motherMaidenName)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={`Enter Mother's Maiden Name`}
            value={personalDetailsForm.motherMaidenName}
            maxLength={200}
          />

          {upgradeTo === 'Standard' && (
            <>
              <SelectInputState2
                label={'State'}
                option={states}
                border={`1px solid ${!isBusinessLgaValid(localStorage.getItem('state')) &&
                  !personalDetailsForm.state
                  ? process.env.REACT_APP_RED_COLOUR
                  : '#DFE2E6'
                  }`}
                getState={localStorage.getItem('state')}
                value={personalDetailsForm.state}
                placeholder={'state'}
                onChange={(e) => {
                  localStorage.setItem(
                    'state',
                    states.find((value) => value.name === e.target.value).name
                  );
                  setPersonalDetailsForm({ ...personalDetailsForm, lga: '' });
                  localStorage.removeItem('lga');
                  setSelectedState(
                    states.find((value) => value.name === e.target.value).id
                  );
                  updateFormField({
                    state: states.find((value) => value.name === e.target.value)
                      .id,
                  });
                }}
              />
              {!personalDetailsForm.state ||
                !isBusinessLgaValid(localStorage.getItem('state')) &&
                (
                  <div
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                      position: 'relative',
                      top: '-10px',
                    }}
                  >
                    Select state
                  </div>
                )}

              <SelectInputLga2
                border={`1px solid ${!isBusinessLgaValid(localStorage.getItem('lga')) &&
                  personalDetailsForm.state
                  ? process.env.REACT_APP_RED_COLOUR
                  : '#DFE2E6'
                  }`}
                label={'Local Government Area'}
                option={fetchLga}
                value={personalDetailsForm?.localGovernmentArea}
                getLga={localStorage.getItem('lga')}
                placeholder={'LGA'}
                onChange={(e) => {
                  localStorage.setItem(
                    'lga',
                    fetchLga.find((value) => value.name === e.target.value).name
                  );
                  updateFormField({
                    localGovernmentArea: fetchLga.find(
                      (value) => value.name === e.target.value
                    ).id,
                  });
                }}
              />
              {loadingLgas && (
                <IosRefresh
                  style={{
                    marginTop: '-52pt',
                    marginLeft: '85%',
                    width: '20px',
                    position: 'absolute',
                  }}
                  fontSize='60px'
                  color='grey'
                  rotate={true}
                />
              )}
              {!personalDetailsForm.state ||
                !isBusinessLgaValid(localStorage.getItem('lga')) &&
                (
                  <div
                    style={{
                      color: process.env.REACT_APP_RED_COLOUR,
                      fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,

                      position: 'relative',
                      top: '-10px',
                    }}
                  >
                    Select Local Government Area
                  </div>
                )}
            </>
          )}

          <InputText2
            label={'Residential Address'}
            labelColor={
              !personalDetailsForm.residentialAddress ||
                isResidentialAddressValid(personalDetailsForm)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!personalDetailsForm.residentialAddress ||
              isResidentialAddressValid(personalDetailsForm)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your Residential Address'}
            onChange={(e) => {
              updateFormField({ residentialAddress: e.target.value });
            }}
            maxLength={200}
            value={personalDetailsForm.residentialAddress}
          />

          <PrimaryButton
            onClick={handleSubmit}
            disabled={!isFormValid() || isLoading}
          >
            {" "}
            {isLoading ? "Processing..." : "Proceed"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default PersonalDetailsScene;