import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import IosCloseCircleOutline from "react-ionicons/lib/IosCloseCircleOutline";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import {
  ArrowIcon,
  cashOutHeaderIcon,
  closeIcon,
} from "../../../../../../icons";
import {
  formatAmount,
  handleCustomerIdFormat,
  normalizeToTitleCase
} from "../../../../../../utils/formatters";
import {
  isEmailValid,
  isNumberValid,
} from "../../../../../../utils/validators/form-validators";

import MdSync from "react-ionicons/lib/MdSync";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import ActivityIndicator from "../../../../../../components/activity-indicator";
import FormLabel from "../../../../../../components/form-label";
import H1 from "../../../../../../components/h1";
import HR from "../../../../../../components/hr";
import Tabs from "../../../../../../components/tabs";
import Text from "../../../../../../components/text";
import VerticallyCenteredModal from "../../../../../../components/vertically-centered-modal";
import {
  CASH_IN,
  DISTRIBUTE,
  NIGERIA_PHONE_PREFIX,
  PAY_A_BILL,
  REQUEST_POS,
  SELL_AIRTIME,
  SEND_MONEY,
  WITHDRAWAL,
} from "../../../../../../constants";
import { ERROR_STATUS } from "../../../../../../constants/api";
import Banks from "../../../../../../fixtures/banks";
import AllNetworkPaymentCodes from "../../../../../../fixtures/network-payment-codes";
import AllNetworkPaymentCodesEpin from "../../../../../../fixtures/network-payment-codes-epin";
import Services from "../../../../../../fixtures/services";
import Catalog from "../../../../../../services/api/resources/catalog";
import Quickteller from "../../../../../../services/api/resources/quickteller";
import { QUICKTELLER_TERMINAL_ID } from "../../../../../../utils/api-properties";
import { convertNgkToNgn } from "../../../../../../utils/converters/currencies";
import { RegexChecker } from "../../../../../../utils/regex-checker";
import phoneValidators from "../../../../../../utils/validators/phone-validators";
import {
  CashoutModalContent,
  NewCashoutReceipt,
  UsedCashoutModal,
} from "../../components/cashoutModalContent";
import CashInScene from "../../scenes/cash-in";

import Button from "../../../../../../components/button";
import FormControl from "../../../../../../components/form-control";
import { CashInForm } from "./forms/cashInForm";
import { PosRequestForm } from "./forms/posRequestForm";
import { SendMoneyForm } from "./forms/sendMoneyForm";
import { TransferCashinForm } from "./forms/transferCashinForm";

const NetworkPaymentCodes =
  AllNetworkPaymentCodes[process.env.REACT_APP_ENVIRONMENT];
const NetworkPaymentCodesEpin =
  AllNetworkPaymentCodesEpin[process.env.REACT_APP_ENVIRONMENT];

const customStylesBanks = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
  control: (base) => ({
    ...base,
    minHeight: 48,
    backgroundColor: "#F3F5F6",
  }),
  singleValue: (provided, state) => {
    const fontSize = 14;
    return { ...provided, fontSize };
  },
};

export class PayBillTab extends React.Component {
  quickteller = new Quickteller();
  catalog = new Catalog();
  subAccounts = [];
  showSubAccount = false;

  constructor(props) {
    super(props);

    this.state = {
      banks: Banks,
      form: {
        accountNumber: null,
        amount: props.amount,
        agentAmount: props.amount,
        countryShortCode: "NG",
        customerId: null,
        senderName: null,
        beneficiaryName: null,
        senderNumber: null,
        beneficiaryPhone: null,
        walletToWalletbeneficiaryPhone: null,
        bank: null,
        phonePrefix: NIGERIA_PHONE_PREFIX,
        narration: props.service.name,
        gender: "male",
      },
      fieldsFocused: [],
      formattedAmount: props.amount ? props.amount : null,
      isAmountFixed: props.isAmountFixed,
      isDisabled: true,
      errorDescription: "",
      networks: Services["sell-airtime"],
      networksEpin: Services["sell-epin"],
      validationLoading: false,
      selectedAgents: [],
      agentList: { label: "Select Agent", value: "0" },
      showSubAccount: false,
      cashoutFormTab: 1,
      cashinFormTab: 1,
      selectedPosModel: null,

      posRequestForm: {
        quantity: 1,
        amount: 0,
        posUnitPrice: 0,
        posTotalPrice: 0,
        termsCondition: false,
        requestType: "",
        file: null,
        fileInput: Date.now(),
      },
      isFreePos: true,
      requestTypeValues: [
        { value: "free", label: "free" },
        { value: "paid", label: "paid" },
      ],
    };

    this.handleChangeImagePhoto = this.handleChangeImagePhoto.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
    this.validateNipAccountDetails = this.validateNipAccountDetails.bind(this);
    this.handlePosTypeSelection = this.handlePosTypeSelection.bind(this);
    this.handlePosModelSelection = this.handlePosModelSelection.bind(this);
    this.handlePosRequestType = this.handlePosRequestType.bind(this);
    this.clearPOSFormFields = this.clearPOSFormFields.bind(this);
    this.getTransferBanks();
  }

  componentDidUpdate(prevProps) {
    if (this.props.amount !== prevProps.amount) {
      this.updateFormField({
        amount: convertNgkToNgn(this.props.amount),
      });
    }
  }

  componentDidMount() {

    this.setState({
      form: {},
    });
    //this.props.getPosTypes();
    // const unit_price= (Array.isArray(pos_models) && pos_models.length) ?  10:  0; //this.props.posUnitPrice(pos_models[0]);
    // this.setState({form:{...this.state.form ,posUnitprice : unit_price}});
  }

  isAmountValid() {
    if (this.state.form.amount >= 0) {
      return this.state.form.amount >= 0;
    }
    if (this.state.form.agentAmount > 0) {
      return this.state.form.agentAmount > 0;
    }
  }

  isFormValid() {
    if (this.customerIdFieldIsEmail) {
      const isAddressRequired =
        this.props.service.urlName === "ODIRSLAND" ||
        this.props.service.urlName === "odirs" || this.props.service.urlName === "Evreg" || this.props.service.urlName === "ondostatejudiciary";

      return (
        isEmailValid(this.state.form.email) &&
        this.isPhoneValid() &&
        this.isAmountValid() &&
        (isAddressRequired ? this.state.form.address : true)
      );
    }

    if (this.customerIdFieldIsPhone) {
      return this.isPhoneValid() && this.isAmountValid();
    }

    if (this.props.showSubAccount) {
      return this.state.customerSubAccNo;
    }

    const isAddressRequired =
      this.props.service.urlName === "ODIRSLAND" ||
      this.props.service.urlName === "odirs" || this.props.service.urlName === "Evreg" || this.props.service.urlName === "ondostatejudiciary";

    return (
      this.state.form.customerId &&
      this.isPhoneValid() &&
      this.isAmountValid() &&
      (isAddressRequired ? this.state.form.address : true)
    );
  }

  isGetAmountFormValid() {
    if (this.customerIdFieldIsEmail) {
      return isEmailValid(this.state.form.email);
    }

    if (this.customerIdFieldIsPhone) {
      return this.isPhoneValid();
    }

    return this.state.form.customerId && this.isPhoneValid();
  }

  isPosRequestFormValid() {
    return (
      this.state.posRequestForm.posType &&
      this.state.posRequestForm.posModel &&
      this.state.posRequestForm.quantity > 0 &&
      this.state.posRequestForm.termsCondition
    );
    // && this.state.posRequestForm.file
  }

  isBuyAirtimeFormValid() {
    return (
      this.state.form.paymentCode &&
      this.isPhoneValid() &&
      this.state.form.amount > 0
    );
  }

  isBuyDataFormValid() {
    return (
      this.state.form.paymentCode &&
      this.isBuyDataPhoneValid() &&
      this.state.form.buyDataAmount > 0
    );
  }

  isEPinFormValid() {
    return (
      this.state.form.paymentCode &&
      this.isEPinPhoneValid() &&
      this.state.form.ePinAmount > 0 &&
      this.state.form.ePinGender
    );
  }

  isPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.phone && (countryValidator ? countryValidator(form.phone) : true)
    );
  }

  isBuyDataPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.buyDataPhone &&
      (countryValidator ? countryValidator(form.buyDataPhone) : true)
    );
  }

  isEPinPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.ePinPhone &&
      (countryValidator ? countryValidator(form.ePinPhone) : true)
    );
  }

  isBeneficiaryPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.beneficiaryPhone &&
      (countryValidator ? countryValidator(form.beneficiaryPhone) : true)
    );
  }

  isAmountFixedAndAmountIsZero() {
    return this.props.amount === 0 && this.props.isAmountFixed;
  }

  iswWalletToWalletbeneficiaryPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.walletToWalletbeneficiaryPhone &&
      (countryValidator
        ? countryValidator(form.walletToWalletbeneficiaryPhone)
        : true)
    );
  }

  isSenderPhoneValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];
    return (
      form.senderPhone &&
      (countryValidator ? countryValidator(form.senderPhone) : true)
    );
  }

  isCustomerNumberValid(newForm) {
    const form = newForm || this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];
    return (
      form.customerNumber &&
      (countryValidator ? countryValidator(form.customerNumber) : true)
    );
  }

  //paycode cashout utils
  isCustomerDataValid() {
    const form = this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      form.phoneNumber &&
      (countryValidator ? countryValidator(form.phoneNumber) : true) &&
      form.gender
    );
  }

  isCashoutPinValid(pin) {
    return pin && pin.length === 4;
  }

  isPaycodeValid(paycode) {
    return paycode && paycode.length >= 10;
  }

  isPaycodeDataValid() {
    const form = this.state.form;
    const countryValidator = phoneValidators[this.state.form.phonePrefix];

    return (
      this.isPaycodeValid(form.payCode) &&
      isNumberValid(form.payCode) &&
      this.isCashoutPinValid(form.cashOutPin) &&
      (countryValidator ? countryValidator(form.initiatorPhone) : true) &&
      isNumberValid(form.amount)
    );
  }

  get customerIdFieldIsEmail() {
    return (
      this.props.customerIdField &&
      this.props.customerIdField.toLowerCase().includes("email")
    );
  }

  get customerIdFieldIsPhone() {
    return (
      this.props.customerIdField &&
      (this.props.customerIdField.toLowerCase().includes("phone") ||
        this.props.customerIdField.toLowerCase().includes("mobile") ||
        this.props.customerIdField.toLowerCase().includes("msisdn"))
    );
  }

  async getTransferBanks() {
    const transferBanks = await this.props.transaction.getUnifiedBanks();
    const transferBanksStatus = transferBanks.status;
    if (transferBanksStatus === ERROR_STATUS || !transferBanks.response) {
      this.setState({
        errorDescription: "",
        banks: Banks,
        form: {
          ...this.state.form,
        },
      });
      return;
    }

    this.setState({
      errorDescription: "",
      banks: transferBanks.response,
      form: {
        ...this.state.form,
      },
    });
  }

  async getNetworkBundlePlan(network) {
    this.setState({
      isGetNetworkBundlePlanLoading: true,
    });

    const { status, response } = await this.catalog.getOptions(
      network,
      QUICKTELLER_TERMINAL_ID
    );
    const networkPlan = response.options;

    if (status === ERROR_STATUS) {
      this.setState({
        errorLoading: false,
        isGetNetworkBundlePlanLoading: false,
      });

      return;
    }
    const transformNetworkPlan = (networkPlan) => {
      const newNetworkPlanArray = networkPlan?.map(
        ({ name: label, name: value, name, ...rest }) => ({
          label,
          value,
          name,
          ...rest,
        })
      );
      return newNetworkPlanArray;
    };

    this.setState({
      isGetNetworkBundlePlanLoading: false,
      networkBundlePlan: transformNetworkPlan(networkPlan),
    });
  }

  renderRefreshDataServices() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() => this.props.getServices()}
        >
          <MdSync
            rotate={this.props.isFetchingData}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.props.isFetchingData ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  renderRefreshFetchagents() {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "top",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Text blue bold small>
          Oops!
        </Text>
        <Text mid>Something went wrong. Please, try again.</Text>
        <div
          style={{
            alignItems: "center",
            border: `1pt solid ${process.env.REACT_APP_BLUE_COLOUR}`,
            borderRadius: "4px",
            color: process.env.REACT_APP_BLUE_COLOUR,
            cursor: "pointer",
            display: "flex",
            fontSize: process.env.REACT_APP_MID_FONT_SIZE,
            fontStyle: process.env.REACT_APP_MID_FONT_SIZE,
            fontWeight: "bold",
            height: "40px",
            justifyContent: "center",
            marginTop: "16px",
            width: "160px",
          }}
          onClick={() => this.props.getServices()}
        >
          <MdSync
            rotate={this.props.isFetchingData}
            color={process.env.REACT_APP_BLUE_COLOUR}
            fontSize={"24px"}
            style={{ marginRight: "10px" }}
          />
          {this.props.isFetchingData ? "Loading..." : "RETRY"}
        </div>
      </div>
    );
  }

  handleAirtimeUpdate(event) {
    const network = event.name;
    const paymentCode = NetworkPaymentCodes[network];
    this.updateFormField({ paymentCode, network });
  }

  handleChangeImagePhoto = (evt) => {
    let self = this;
    //  let reader = new FileReader()
    const rawFile = evt.target.files[0]; //alert(JSON.stringify(rawFile))
    //    reader.onload = function(upload) {
    //     self.setState({
    //       posRequestForm : {  ...self.state.posRequestForm,file : upload.target.result}
    //     });
    //   };
    //   reader.readAsDataURL(rawFile);

    this.setState({
      posRequestForm: { ...self.state.posRequestForm, file: rawFile },
    });
  };
  handlePosTypeSelection(event) {
    const posType = event.value;
    //set unit price and total price to 0
    this.props.setPosUnitPrice(0);
    this.props.setPosTotalPrice(0);
    this.setState({
      selectedPosModel: null,
      posRequestForm: { ...this.state.posRequestForm, posType: posType },
    });
    this.props.getPosModels(posType);
  }

  handlePosModelSelection(event) {
    const posModel = event.value;
    this.setState({ selectedPosModel: { value: posModel, label: posModel } });
    const qty =
      this.state.posRequestForm.quantity <= 0 ||
      this.state.posRequestForm.quantity === undefined
        ? 1
        : this.state.posRequestForm.quantity;
    this.props.getPosUnitPrice(posModel, qty);
    this.setState({
      posRequestForm: {
        ...this.state.posRequestForm,
        posModel: posModel,
        posUnitPrice: this.props.posUnitPrice,
      },
      posValue: posModel,
    });
  }

  handlePosRequestType(param) {
    const isFreePos = param === "free" ? true : false;
    this.setState({
      isFreePos: isFreePos,
      posRequestForm: {
        ...this.state.posRequestForm,
        requestType: { value: param, label: param },
      },
    });
  }

  clearPOSFormFields() {
    this.setState({
      selectedPosModel: null,
      requestTypeValues: this.state.requestTypeValues,
      posRequestForm: {
        posModel: "",
        posType: "",
        quantity: 1,
        requestType: "",
        amount: 0,
        posUnitPrice: 0,
        file: null,
        termsCondition: false,
        fileInput: Date.now(),
      },
    });

    this.props.setPosTotalPrice(0);
    this.props.setPosUnitPrice(0);
  }

  updatePosRequestFormField(params) {
    const newForm = {
      ...this.state.posRequestForm,
      ...params,
    };

    this.setState({
      posRequestForm: newForm,
    });
  }

  get cashin() {
    return (
      <CashInScene
        hideServiceCategories={() =>
          this.setState({ showServiceCategories: false })
        }
        showServiceCategories={() =>
          this.setState({ showServiceCategories: true })
        }
        onSelect={(value) => this.setState({ showServiceCategories: true })}
        onClose={() =>
          this.setState({
            showServiceCategories: true,
            selectedServiceCategory: null,
          })
        }
        showErrorMessage={this.props.showErrorMessage}
        dismissErrorMessage={this.props.dismissErrorMessage}
      />
    );
  }

  get payABillForm() {
    return (
      <Form>
        {this.isAmountFixedAndAmountIsZero() &&
        this.props.showGetAmountField ? (
          <>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color:
                        (this.customerIdFieldIsEmail &&
                          this.state.form.email &&
                          !isEmailValid(this.state.form.email)) ||
                        (this.customerIdFieldIsPhone &&
                          this.state.form.phone &&
                          !this.isPhoneValid())
                          ? process.env.REACT_APP_RED_COLOUR
                          : "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    {this.props.customerIdField}:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>

                  {this.customerIdFieldIsPhone ? (
                    <ReactPhoneInput
                      buttonStyle={{
                        borderTopLeftRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : "20px",
                        borderBottomLeftRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : "20px",
                      }}
                      containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                      defaultCountry={"ng"}
                      disableCountryCode={true}
                      inputStyle={{
                        backgroundColor: "#FFFFFF",
                        borderRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : "20px",
                        border: `1px solid ${
                          (!this.state.form.phone &&
                            !this.state.propagateFormErrors) ||
                          this.isPhoneValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                        width: "100%",
                      }}
                      value={this.state.form.phone || ""}
                      onlyCountries={["ng", "ke", "ug"]}
                      onBlur={() =>
                        this.setState({
                          fieldsFocused: [...this.state.fieldsFocused, "phone"],
                        })
                      }
                      onChange={(value, data) => {
                        let formattedValue = value;
                        const customerId = formattedValue;
                        const fieldsToUpdate = { customerId };

                        if (this.customerIdFieldIsPhone) {
                          fieldsToUpdate.phone = customerId;
                          fieldsToUpdate.countryShortCode =
                            data.countryCode?.toUpperCase();
                        }

                        this.updateFormField(fieldsToUpdate);
                      }}
                      disabled={this.props.showSubAccount}
                      placeholder="08012345678"
                    />
                  ) : this.customerIdFieldIsEmail ? (
                    <FormControl
                      onChange={(event) => {
                        const email = event.target.value;
                        const customerId = email;
                        const fieldsToUpdate = {
                          customerId,
                          email,
                        };
                        this.updateFormField(fieldsToUpdate);
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          (!this.state.form.email &&
                            !this.state.propagateFormErrors) ||
                          isEmailValid(this.state.form.email)
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <FormControl
                      onChange={(event) => {
                        const customerId = event.target.value;
                        const fieldsToUpdate = { customerId };
                        this.updateFormField(fieldsToUpdate);
                      }}
                      disabled={
                        !this.props.showGetAmountField ||
                        this.props.showSubAccount
                      }
                      value={this.state.form.customerId}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid #DFE2E6`,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        width: "100%",
                      }}
                    />
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Col} controlId="amount">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Amount:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroupPrepend"
                        style={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                        }}
                      >
                        NGN
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      readOnly={this.props.isAmountFixed}
                      type="tel"
                      value={this.state.form.amount}
                      onBlur={() =>
                        this.setState({
                          fieldsFocused: [
                            ...this.state.fieldsFocused,
                            "amount",
                          ],
                        })
                      }
                      onChange={(event) => {
                        const amount = event.target.value;
                        this.updateFormField({ amount });
                      }}
                      className="form-control form-control-amount"
                      disabled={this.props.showSubAccount}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col hidden={this.customerIdFieldIsPhone} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        (!this.state.form.phone &&
                          !this.state.propagateFormErrors) ||
                        this.isPhoneValid()
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Phone Number:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <ReactPhoneInput
                    buttonStyle={{
                      borderTopLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      borderBottomLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                    }}
                    containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                    defaultCountry={"ng"}
                    disableCountryCode={true}
                    inputStyle={{
                      backgroundColor: "#FFFFFF",
                      borderRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      border: `1px solid ${
                        (!this.state.form.phone &&
                          !this.state.propagateFormErrors) ||
                        this.isPhoneValid()
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "17px",
                      width: "100%",
                    }}
                    value={this.state.form.phone || ""}
                    maxLength={11}
                    onlyCountries={["ng", "ke", "ug"]}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "phone"],
                      })
                    }
                    // disabled={!this.props.showGetAmountField}
                    onChange={(value, data) => {
                      this.updateFormField({ phone: value });
                    }}
                    disabled={this.props.showSubAccount}
                    placeholder="08012345678"
                  />
                </Form.Group>
              </Col>

              <Col hidden={this.customerIdFieldIsEmail} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        (!this.state.form.email &&
                          !this.state.propagateFormErrors) ||
                        isEmailValid(this.state.form.email)
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Email Address:
                  </FormLabel>
                  <FormControl
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "email"],
                      })
                    }
                    onChange={(event) => {
                      const email = event.target.value;
                      this.updateFormField({ email });
                    }}
                    disabled={
                      !this.props.showGetAmountField ||
                      this.props.showSubAccount
                    }
                    //disabled={!this.props.showGetAmountField}
                    value={this.state.form.email || ""}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "20px",
                      border: `1px solid ${
                        (!this.state.form.email &&
                          !this.state.propagateFormErrors) ||
                        isEmailValid(this.state.form.email)
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: "12px",
                      lineHeight: "17px",
                      width: "100%",
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="gender">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Gender:{" "}
                  </FormLabel>
                  <Row>
                    <Col md={6}>
                      <input
                        type="radio"
                        name="gender"
                        value="Male"
                        id="male"
                        onChange={(event) => {
                          const gender = event.target.value;
                          this.updateFormField({ gender });
                        }}
                        disabled={this.props.showSubAccount}
                      />{" "}
                      <label for="male">Male</label>
                    </Col>
                    <Col md={6}>
                      <input
                        type="radio"
                        name="gender"
                        value="Female"
                        id="female"
                        onChange={(event) => {
                          const gender = event.target.value;
                          this.updateFormField({ gender });
                        }}
                        disabled={this.props.showSubAccount}
                      />{" "}
                      <label for="female">Female</label>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} hidden={!this.props.showSubAccount}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        !this.state.customerSubAccNo &&
                        !this.state.propagateFormErrors
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Sub Account:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <Select
                    options={this.props.subAccounts.map((details) => {
                      return {
                        label: `${details.accountName} (${details.accNo})`,
                        value: details.accNo,
                      };
                    })}
                    onChange={(event) => {
                      const customerSubAccNo = event.value;
                      this.updateSubAccount({ customerSubAccNo });
                    }}
                    styles={customStylesBanks}
                    className="select-input-container-banks"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
                  <span
                    hidden={this.state.customerSubAccNo}
                    style={{ color: process.env.REACT_APP_RED_COLOUR }}
                  >
                    Select a sub account
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="proceed-button-col">
                <Button
                  className="services-proceed-button"
                  disabled={this.props.isLoading || !this.isFormValid()}
                  onClick={(e) => {
                    e.preventDefault();
                    this.state.formWithSubAccount
                      ? this.props.onPayBillProceedWithSubAccount(
                          e,
                          this.state.formWithSubAccount
                        )
                      : this.props.onPayBillGetAmount(this.state.form);
                      // : this.props.onPayBillProceed(this.state.form);
                  }}
                >
                  {this.props.isLoading ? "Loading..." : "Continue"}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color:
                        (this.customerIdFieldIsEmail &&
                          this.state.form.email &&
                          !isEmailValid(this.state.form.email)) ||
                        (this.customerIdFieldIsPhone &&
                          this.state.form.phone &&
                          !this.isPhoneValid())
                          ? process.env.REACT_APP_RED_COLOUR
                          : "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    {this.props.customerIdField}:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>

                  {this.customerIdFieldIsPhone ? (
                    <ReactPhoneInput
                      buttonStyle={{
                        borderTopLeftRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : "20px",
                        borderBottomLeftRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : "20px",
                      }}
                      containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                      defaultCountry={"ng"}
                      disableCountryCode={true}
                      inputStyle={{
                        backgroundColor: "#FFFFFF",
                        borderRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : "20px",
                        border: `1px solid ${
                          (!this.state.form.phone &&
                            !this.state.propagateFormErrors) ||
                          this.isPhoneValid()
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                        lineHeight: "17px",
                        width: "100%",
                      }}
                      value={this.state.form.phone || ""}
                      onlyCountries={["ng", "ke", "ug"]}
                      onBlur={() =>
                        this.setState({
                          fieldsFocused: [...this.state.fieldsFocused, "phone"],
                        })
                      }
                      onChange={(value, data) => {
                        let formattedValue = value;
                        const customerId = formattedValue;
                        const fieldsToUpdate = { customerId };

                        if (this.customerIdFieldIsPhone) {
                          fieldsToUpdate.phone = customerId;
                          fieldsToUpdate.countryShortCode =
                            data.countryCode?.toUpperCase();
                        }

                        this.updateFormField(fieldsToUpdate);
                      }}
                      disabled={this.props.showSubAccount}
                      placeholder="08012345678"
                    />
                  ) : this.customerIdFieldIsEmail ? (
                    <FormControl
                      onChange={(event) => {
                        const email = event.target.value;
                        const customerId = email;
                        const fieldsToUpdate = {
                          customerId,
                          email,
                        };
                        this.updateFormField(fieldsToUpdate);
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid ${
                          (!this.state.form.email &&
                            !this.state.propagateFormErrors) ||
                          isEmailValid(this.state.form.email)
                            ? "#DFE2E6"
                            : process.env.REACT_APP_RED_COLOUR
                        }`,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <FormControl
                      onChange={(event) => {
                        const customerId = event.target.value;
                        const fieldsToUpdate = { customerId };
                        this.updateFormField(fieldsToUpdate);
                      }}
                      disabled={
                        !this.props.showGetAmountField ||
                        this.props.showSubAccount
                      }
                      value={this.state.form.customerId}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "20px",
                        border: `1px solid #DFE2E6`,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        width: "100%",
                      }}
                    />
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Col} controlId="amount">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Amount:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroupPrepend"
                        style={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                        }}
                      >
                        NGN
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      readOnly={this.props.isAmountFixed}
                      type="tel"
                      value={this.state.form.amount}
                      onBlur={() =>
                        this.setState({
                          fieldsFocused: [
                            ...this.state.fieldsFocused,
                            "amount",
                          ],
                        })
                      }
                      onChange={(event) => {
                        const amount = event.target.value;
                        this.updateFormField({ amount });
                      }}
                      className="form-control form-control-amount"
                      disabled={this.props.showSubAccount}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col hidden={this.customerIdFieldIsPhone} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        (!this.state.form.phone &&
                          !this.state.propagateFormErrors) ||
                        this.isPhoneValid()
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Phone Number:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <ReactPhoneInput
                    buttonStyle={{
                      borderTopLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      borderBottomLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                    }}
                    containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                    defaultCountry={"ng"}
                    disableCountryCode={true}
                    inputStyle={{
                      backgroundColor: "#FFFFFF",
                      borderRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      border: `1px solid ${
                        (!this.state.form.phone &&
                          !this.state.propagateFormErrors) ||
                        this.isPhoneValid()
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "17px",
                      width: "100%",
                    }}
                    value={this.state.form.phone || ""}
                    maxLength={11}
                    onlyCountries={["ng", "ke", "ug"]}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "phone"],
                      })
                    }
                    // disabled={!this.props.showGetAmountField}
                    onChange={(value, data) => {
                      this.updateFormField({ phone: value });
                    }}
                    disabled={this.props.showSubAccount}
                    placeholder="08012345678"
                  />
                </Form.Group>
              </Col>

              <Col hidden={this.customerIdFieldIsEmail} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        (!this.state.form.email &&
                          !this.state.propagateFormErrors) ||
                        isEmailValid(this.state.form.email)
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Email Address:
                  </FormLabel>
                  <FormControl
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "email"],
                      })
                    }
                    onChange={(event) => {
                      const email = event.target.value;
                      this.updateFormField({ email });
                    }}
                    disabled={
                      !this.props.showGetAmountField ||
                      this.props.showSubAccount
                    }
                    //disabled={!this.props.showGetAmountField}
                    value={this.state.form.email || ""}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "20px",
                      border: `1px solid ${
                        (!this.state.form.email &&
                          !this.state.propagateFormErrors) ||
                        isEmailValid(this.state.form.email)
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: "12px",
                      lineHeight: "17px",
                      width: "100%",
                    }}
                  />
                </Form.Group>
              </Col>

              {
              (this.props.service.urlName ==="ODIRSLAND" || this.props.service.urlName ==="odirs" || this.props.service.urlName === "Evreg" || this.props.service.urlName === "ondostatejudiciary") && 
              (<Col md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        (!this.state.form.address &&
                          !this.state.propagateFormErrors) ||
                        this.state.form.address
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Address:
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <FormControl
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "address"],
                      })
                    }
                    onChange={(event) => {
                      const address = event.target.value;
                      this.updateFormField({ address });
                    }}
                    disabled={
                      !this.props.showGetAmountField ||
                      this.props.showSubAccount
                    }
                    value={this.state.form.address || ""}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "20px",
                      border: `1px solid ${
                        (!this.state.form.address &&
                          !this.state.propagateFormErrors) ||
                        (this.state.form.address)
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: "12px",
                      lineHeight: "17px",
                      width: "100%",
                    }}
                  />
                </Form.Group>
              </Col>)
  }
              <Col md={6}>
                <Form.Group controlId="gender">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Gender:{" "}
                  </FormLabel>
                  <Row>
                    <Col md={6}>
                      <input
                        type="radio"
                        name="gender"
                        value="Male"
                        id="male"
                        onChange={(event) => {
                          const gender = event.target.value;
                          this.updateFormField({ gender });
                        }}
                        disabled={this.props.showSubAccount}
                      />{" "}
                      <label for="male">Male</label>
                    </Col>
                    <Col md={6}>
                      <input
                        type="radio"
                        name="gender"
                        value="Female"
                        id="female"
                        onChange={(event) => {
                          const gender = event.target.value;
                          this.updateFormField({ gender });
                        }}
                        disabled={this.props.showSubAccount}
                      />{" "}
                      <label for="female">Female</label>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} hidden={!this.props.showSubAccount}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        !this.state.customerSubAccNo &&
                        !this.state.propagateFormErrors
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Sub Account:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <Select
                    options={this.props.subAccounts.map((details) => {
                      return {
                        label: `${details.accountName} (${details.accNo})`,
                        value: details.accNo,
                      };
                    })}
                    onChange={(event) => {
                      const customerSubAccNo = event.value;
                      this.updateSubAccount({ customerSubAccNo });
                    }}
                    styles={customStylesBanks}
                    className="select-input-container-banks"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
                  <span
                    hidden={this.state.customerSubAccNo}
                    style={{ color: process.env.REACT_APP_RED_COLOUR }}
                  >
                    Select a sub account
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="proceed-button-col">
                <Button
                  className="services-proceed-button"
                  disabled={this.props.isLoading || !this.isFormValid()}
                  onClick={(e) => {
                    e.preventDefault();
                    // console.log('Form Data:', this.state.formWithSubAccount || this.state.form);
                    this.state.formWithSubAccount
                      ? this.props.onPayBillProceedWithSubAccount(
                          e,
                          this.state.formWithSubAccount
                        )
                      : this.props.onPayBillProceed(this.state.form);
                  }}
                >
                  {this.props.isLoading ? "Loading..." : "Continue"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }

  get walletToWalletForm() {
    const walletForm = {
      ...this.state.form,
      amount: this.state.form.agentAmount,
    };
    return (
      <Form>
        <>
          <Row>
            <Col md={6}>
              <Form.Group>
                <FormLabel
                  style={{
                    color:
                      (this.customerIdFieldIsEmail &&
                        this.state.form.email &&
                        !isEmailValid(this.state.form.email)) ||
                      (this.customerIdFieldIsPhone &&
                        this.state.form.phone &&
                        !this.isPhoneValid())
                        ? process.env.REACT_APP_RED_COLOUR
                        : "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  {"Beneficiary Phone "}
                  <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                    *
                  </span>
                </FormLabel>

                {this.customerIdFieldIsPhone ? (
                  <ReactPhoneInput
                    buttonStyle={{
                      borderTopLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      borderBottomLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                    }}
                    containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                    defaultCountry={"ng"}
                    disableCountryCode={true}
                    inputStyle={{
                      backgroundColor: "#FFFFFF",
                      borderRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      border: `1px solid ${
                        (!this.state.form.phone &&
                          !this.state.propagateFormErrors) ||
                        this.isPhoneValid()
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "17px",
                      width: "100%",
                    }}
                    value={this.state.form.beneficiaryPhone || ""}
                    onlyCountries={["ng", "ke", "ug"]}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "phone"],
                      })
                    }
                    onChange={(value, data) => {
                      let formattedValue = value;
                      const customerId = formattedValue;
                      const fieldsToUpdate = { customerId };

                      if (this.customerIdFieldIsPhone) {
                        fieldsToUpdate.phone = customerId;
                        fieldsToUpdate.countryShortCode =
                          data.countryCode.toUpperCase();
                      }

                      this.updateFormField(fieldsToUpdate);
                    }}
                    placeholder="08012345678"
                  />
                ) : this.customerIdFieldIsEmail ? (
                  <FormControl
                    onChange={(event) => {
                      const email = event.target.value;
                      const customerId = email;
                      const fieldsToUpdate = {
                        customerId,
                        email,
                      };

                      this.updateFormField(fieldsToUpdate);
                    }}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "20px",
                      border: `1px solid ${
                        (!this.state.form.email &&
                          !this.state.propagateFormErrors) ||
                        isEmailValid(this.state.form.email)
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: "12px",
                      lineHeight: "17px",
                      width: "100%",
                    }}
                  />
                ) : (
                  <FormControl
                    onChange={(event) => {
                      const customerId = event.target.value;
                      const fieldsToUpdate = { customerId };

                      this.updateFormField(fieldsToUpdate);
                    }}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "20px",
                      border: `1px solid #DFE2E6`,
                      boxSizing: "border-box",
                      fontSize: "12px",
                      lineHeight: "17px",
                      width: "100%",
                    }}
                  />
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="gender">
                <FormLabel
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Gender:{" "}
                </FormLabel>
                <Row>
                  <Col md={6}>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      id="male"
                      onChange={(event) => {
                        const gender = event.target.value;
                        this.updateFormField({ gender });
                      }}
                    />{" "}
                    <label for="male">Male</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      id="female"
                      onChange={(event) => {
                        const gender = event.target.value;
                        this.updateFormField({ gender });
                      }}
                    />{" "}
                    <label for="female">Female</label>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group as={Col} controlId="amount">
                <FormLabel
                  style={{
                    color: "#3A3B3B",
                    fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                    lineHeight: "19px",
                  }}
                >
                  Amount:{" "}
                  <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                    *
                  </span>
                </FormLabel>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="inputGroupPrepend"
                      style={{
                        borderTopLeftRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : 20,
                        borderBottomLeftRadius:
                          process.env.REACT_APP_BORDER_STYLE === "square"
                            ? "4px"
                            : 20,
                      }}
                    >
                      NGN
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    readOnly={this.props.isAmountFixed}
                    type="tel"
                    value={this.state.form.agentAmount}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [...this.state.fieldsFocused, "amount"],
                      })
                    }
                    onChange={(event) => {
                      const agentAmount = event.target.value;
                      this.updateFormField({ agentAmount });
                    }}
                    className="form-control form-control-amount"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="proceed-button-col">
              <Button
                className="services-proceed-button"
                disabled={
                  this.props.isLoading ||
                  !(this.state.form.customerId && this.isAmountValid())
                }
                onClick={() => this.props.onWalletTransferProceed(walletForm)}
              >
                {this.props.isLoading ? "Loading..." : "Continue"}
              </Button>
            </Col>
          </Row>
        </>
      </Form>
    );
  }

  get sendMoneyNipForm() {
    return (
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <FormLabel
                className={`form-label-style ${
                  this.state.form.accountNumber &&
                  !this.isAccountNumberComplete()
                    ? "isNotValid"
                    : "isValid"
                }`}
              >
                Account Number:
                <span className="asterisk-style">*</span>
              </FormLabel>
              <FormControl
                className="form-control-text-input"
                maxLength={10}
                onBlur={() => {
                  this.setState({
                    fieldsFocused: [
                      ...this.state.fieldsFocused,
                      "accountNumber",
                    ],
                  });
                }}
                onChange={(event) => {
                  const accountNumber = event.target.value;
                  this.updateFormField({ accountNumber });
                }}
                style={{
                  borderColor:
                    this.state.form.accountNumber &&
                    !this.isAccountNumberComplete()
                      ? process.env.REACT_APP_RED_COLOUR
                      : "#DFE2E6",
                }}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6} style={{ zIndex: 3 }}>
            <Form.Group>
              <FormLabel
                style={{
                  color: "#3A3B3B",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Bank:{" "}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <Select
                options={
                  this.props.nipBanks &&
                  this.props.nipBanks.map((bank) => {
                    return { label: bank.institutionName, value: bank.alias };
                  })
                }
                onChange={(event) => {
                  const bank = event;
                  this.updateFormField({ bank });
                  this.state.form.accountNumber &&
                    this.validateNipAccountDetails(bank);
                }}
                styles={customStylesBanks}
                className="select-input-container-banks"
                classNamePrefix="react-select"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "rgb(178, 212, 255)",
                  },
                })}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6} style={{ paddingBottom: "30px" }}>
            <Form.Group>
              <FormLabel
                className={`form-label-style ${
                  this.state.form.beneficiaryName &&
                  !this.isBeneficiaryNameValid()
                    ? "isNotValid"
                    : "isValid"
                }`}
              >
                Beneficiary's Name:
                <span className="asterisk-style">*</span>
              </FormLabel>
              <InputGroup style={{ height: "10px", width: "100%" }}>
                <FormControl
                  className="form-control-text-input"
                  onChange={(event) => {
                    const beneficiaryName = event.target.value;
                    this.setState({
                      errorDescription: "",
                    });
                    this.updateFormField({ beneficiaryName });
                  }}
                  value={this.state.form.beneficiaryName}
                  disabled={
                    this.state.isDisabled || this.state.validationLoading
                  }
                />
                {this.state.validationLoading && (
                  <IosRefresh
                    style={{
                      marginTop: "-19pt",
                      marginLeft: "45%",
                      width: "40px",
                      position: "absolute",
                    }}
                    fontSize="100px"
                    color="grey"
                    rotate={true}
                  />
                )}
                {!this.state.isDisabled && !this.state.form.beneficiaryName && (
                  <InputGroup.Append>
                    <Button
                      id="beneficiary-name-retry-button"
                      onClick={() => this.validateNipAccountDetails()}
                      disabled={this.state.validationLoading}
                    >
                      Retry
                    </Button>
                  </InputGroup.Append>
                )}
              </InputGroup>
              {
                <span
                  style={{
                    color: "red",
                    fontSize: "11px",
                    position: "absolute",
                    bottom: this.props.deviceIsMobile ? "-15px" : "-5px",
                  }}
                >
                  {this.state.errorDescription ||
                  !this.state.form.beneficiaryName
                    ? this.state.errorDescription
                    : ""}
                </span>
              }
            </Form.Group>
          </Col>

          <Col xs={12} md={6} style={{ zIndex: 2 }}>
            <Form.Group>
              <FormLabel
                style={{
                  color:
                    this.state.form.beneficiaryPhone &&
                    !this.isBeneficiaryPhoneValid()
                      ? process.env.REACT_APP_RED_COLOUR
                      : "#3A3B3B",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Beneficiary's Number:{" "}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <ReactPhoneInput
                buttonStyle={{
                  borderTopLeftRadius:
                    process.env.REACT_APP_BORDER_STYLE === "square"
                      ? "4px"
                      : "20px",
                  borderBottomLeftRadius:
                    process.env.REACT_APP_BORDER_STYLE === "square"
                      ? "4px"
                      : "20px",
                }}
                containerStyle={{
                  margin: 0,
                  padding: 0,
                  width: "100%",
                }}
                defaultCountry={"ng"}
                disableCountryCode={true}
                inputStyle={{
                  backgroundColor: "#FFFFFF",
                  borderRadius:
                    process.env.REACT_APP_BORDER_STYLE === "square"
                      ? "4px"
                      : "20px",
                  border: `1px solid ${
                    (!this.state.form.beneficiaryPhone &&
                      !this.state.propagateFormErrors) ||
                    this.isBeneficiaryPhoneValid()
                      ? "#DFE2E6"
                      : process.env.REACT_APP_RED_COLOUR
                  }`,
                  boxSizing: "border-box",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "17px",
                  width: "100%",
                }}
                maxLength={11}
                value={this.state.form.beneficiaryPhone || ""}
                onlyCountries={["ng", "ke", "ug"]}
                onBlur={() =>
                  this.setState({
                    fieldsFocused: [
                      ...this.state.fieldsFocused,
                      "beneficiaryPhone",
                    ],
                  })
                }
                onChange={(value, data) => {
                  this.updateFormField({ beneficiaryPhone: value });
                }}
                placeholder="08012345678"
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group as={Col} md="12" controlId="amount">
              <FormLabel
                style={{
                  color: "#3A3B3B",
                  fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Amount:{" "}
                <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                  *
                </span>
              </FormLabel>
              <InputGroup style={{ borderRadius: 10 }}>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    style={{
                      borderTopLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : 20,
                      borderBottomLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : 20,
                    }}
                  >
                    NGN
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  type="tel"
                  value={this.state.form.amount}
                  onChange={(event) => {
                    const amount = event.target.value;
                    this.updateFormField({ amount });
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: `1px solid #DFE2E6`,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    boxSizing: "border-box",
                    fontSize: "12px",
                    lineHeight: "17px",
                    padding: "20px",
                  }}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <FormLabel
                style={{
                  color: "#3A3B3B",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Remark:
              </FormLabel>
              <FormControl
                className="form-control-text-input"
                placeholder="Transfer to ABC"
                onChange={(event) => {
                  const remark = event.target.value;
                  this.updateFormField({ remark });
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="gender">
              <FormLabel
                style={{
                  color: "#3A3B3B",
                  fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                  lineHeight: "19px",
                }}
              >
                Gender:{" "}
              </FormLabel>
              <Row>
                <Col md={6}>
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    id="male"
                    onChange={(event) => {
                      const gender = event.target.value;
                      this.updateFormField({ gender });
                    }}
                  />{" "}
                  <label for="male">Male</label>
                </Col>
                <Col md={6}>
                  <input
                    type="radio"
                    name="gender"
                    id="female"
                    value="Female"
                    onChange={(event) => {
                      const gender = event.target.value;
                      this.updateFormField({ gender });
                    }}
                  />{" "}
                  <label for="female">Female</label>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="proceed-button-col">
            <Button
              disabled={
                this.props.isLoading ||
                !this.isSendMoneyNIPFormValid() ||
                !this.state.form.beneficiaryName
              }
              className="services-proceed-button"
              onClick={() => this.props.onSendNIPMoneyProceed(this.state.form)}
            >
              {this.props.isLoading ? "Loading..." : "Continue"}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  get distributeForm() {
    return (
      <>
        {this.props.isFetchingData ? (
          <ActivityIndicator />
        ) : (
          <Form>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group as={Col} md="12" controlId="agent">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Agent:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <FormControl
                    onChange={(event) => {
                      const currentAgent = JSON.parse(event.target.value);
                      this.props.updateDistributeFormField({ currentAgent });
                    }}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "20px",
                      border: "1px solid #DFE2E6",
                      boxSizing: "border-box",
                      fontSize: "12px",
                      lineHeight: "17px",
                    }}
                    as="select"
                  >
                    <option value={0}>Select Agent</option>
                    {this.props.agents &&
                      this.props.agents.map((agent) => {
                        if (!agent.selected) {
                          return (
                            <option value={JSON.stringify(agent)}>
                              {agent.businessName}
                            </option>
                          );
                        }
                      })}
                  </FormControl>
                </Form.Group>
              </Col>

              <Col xs={12} md={4}>
                <Form.Group as={Col} md="12" controlId="amount">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Amount:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <InputGroup style={{ borderRadius: 10 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroupPrepend"
                        style={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                        }}
                      >
                        NGN
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      type="tel"
                      value={this.state.form.amount}
                      onChange={(event) => {
                        const amount = event.target.value;
                        this.props.updateDistributeFormField({ amount });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: `1px solid #DFE2E6`,
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        padding: "20px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} style={{ paddingTop: "35px" }}>
                <Button
                  className="services-proceed-button"
                  disabled={
                    this.props.isLoading ||
                    !this.isDistributeMoneyToAgentValid()
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.setDistributeAgent(this.props.distributeForm);
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>

            {this.props.selectedAgents.length >= 1 && (
              <Row style={{ padding: "15px" }}>
                <Col md={4}>
                  <div
                    style={{
                      color: process.env.REACT_APP_BLUE_COLOUR,
                      fontSize: "17px",
                    }}
                  >
                    <b>Agent Business Name</b>
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    style={{
                      color: process.env.REACT_APP_BLUE_COLOUR,
                      fontSize: "17px",
                    }}
                  >
                    <b>Phone</b>
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    style={{
                      color: process.env.REACT_APP_BLUE_COLOUR,
                      fontSize: "17px",
                    }}
                  >
                    <b>Amount (₦)</b>
                  </div>
                </Col>
                <Col md={2}></Col>
              </Row>
            )}

            {this.props.selectedAgents.map((agent) => {
              return (
                <Row style={{ padding: "15px" }}>
                  <Col md={4}>
                    <div
                      style={{
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      }}
                    >
                      {agent.businessName}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div
                      style={{
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      }}
                    >
                      {handleCustomerIdFormat(agent.beneficiaryPhone)}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div
                      style={{
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                      }}
                    >
                      <b>{`₦${formatAmount(convertNgkToNgn(agent.amount))}`}</b>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      style={{
                        fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                        color: process.env.REACT_APP_RED_COLOUR,
                      }}
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.removeDistributeAgent(agent)}
                      >
                        <IosCloseCircleOutline
                          color={process.env.REACT_APP_RED_COLOUR}
                          height="250px"
                          width="250px"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              );
            })}

            {this.props.selectedAgents.length >= 1 && (
              <>
                <Row>
                  <Col md={12} className="proceed-button-col">
                    <Button
                      disabled={
                        this.props.isLoading ||
                        !this.isDistributeMoneyToAgentContinueValid()
                      }
                      className="services-proceed-button"
                      onClick={() =>
                        this.props.onDistributeProceed(
                          this.props.selectedAgents
                        )
                      }
                    >
                      {this.props.isLoading ? "Loading..." : "Continue"}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        )}
      </>
    );
  }

  get buyAirtimeForm() {
    return (
      <div>
        {this.props.isFetchingData ? (
          <ActivityIndicator />
        ) : !this.props.serviceOptions ? (
          this.renderRefreshDataServices()
        ) : (
          <Tabs>
            <div label="Airtime">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color:
                            this.state.form.phone && !this.isPhoneValid()
                              ? process.env.REACT_APP_RED_COLOUR
                              : "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Phone Number:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <ReactPhoneInput
                        buttonStyle={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                        }}
                        containerStyle={{
                          margin: 0,
                          padding: 0,
                          width: "100%",
                        }}
                        defaultCountry={"ng"}
                        disableCountryCode={true}
                        inputStyle={{
                          backgroundColor: "#FFFFFF",
                          borderRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          border: `1px solid ${
                            (!this.state.form.phone &&
                              !this.state.propagateFormErrors) ||
                            this.isPhoneValid()
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "17px",
                          width: "100%",
                        }}
                        value={this.state.form.phone || ""}
                        onlyCountries={["ng", "ke", "ug"]}
                        onBlur={() =>
                          this.setState({
                            fieldsFocused: [
                              ...this.state.fieldsFocused,
                              "phone",
                            ],
                          })
                        }
                        onChange={(value, data) => {
                          this.updateFormField({ phone: value });
                        }}
                        placeholder="08012345678"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Select a Network:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <Select
                        options={this.state.networks}
                        onChange={(event) => {
                          const network = event.name;
                          const paymentCode = NetworkPaymentCodes[network];
                          this.updateFormField({ paymentCode, network });
                        }}
                        className="select-input-container-airtime"
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "rgb(178, 212, 255)",
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group as={Col} md="12" controlId="amount">
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Amount:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <InputGroup style={{ borderRadius: 10 }}>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="inputGroupPrepend"
                            style={{
                              borderTopLeftRadius: process.env
                                .REACT_APP_BORDER_STYLE
                                ? "4px"
                                : 20,
                              borderBottomLeftRadius: process.env
                                .REACT_APP_BORDER_STYLE
                                ? "4px"
                                : 20,
                            }}
                          >
                            NGN
                          </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          type="tel"
                          value={this.state.form.amount}
                          onChange={(event) => {
                            const amount = event.target.value;
                            this.updateFormField({ amount });
                          }}
                          style={{
                            backgroundColor: "#FFFFFF",
                            border: `1px solid #DFE2E6`,
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            boxSizing: "border-box",
                            fontSize: "12px",
                            lineHeight: "17px",
                            padding: "20px",
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="gender">
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Gender:{" "}
                      </FormLabel>
                      <Row>
                        <Col md={6}>
                          <input
                            type="radio"
                            name="gender"
                            value="Male"
                            id="male"
                            onChange={(event) => {
                              const gender = event.target.value;
                              this.updateFormField({ gender });
                            }}
                          />{" "}
                          <label for="male">Male</label>
                        </Col>
                        <Col md={6}>
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            id="female"
                            onChange={(event) => {
                              const gender = event.target.value;
                              this.updateFormField({ gender });
                            }}
                          />{" "}
                          <label for="female">Female</label>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="proceed-button-col">
                    <Button
                      className="services-proceed-button"
                      disabled={
                        this.props.isLoading || !this.isBuyAirtimeFormValid()
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onBuyAirtimeProceed(this.state.form);
                      }}
                    >
                      {this.props.isLoading ? "Loading..." : "Continue"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div label="Data">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color:
                            this.state.form.buyDataPhone &&
                            !this.isBuyDataPhoneValid()
                              ? process.env.REACT_APP_RED_COLOUR
                              : "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Phone Number:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <ReactPhoneInput
                        buttonStyle={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                        }}
                        containerStyle={{
                          margin: 0,
                          padding: 0,
                          width: "100%",
                        }}
                        defaultCountry={"ng"}
                        disableCountryCode={true}
                        inputStyle={{
                          backgroundColor: "#FFFFFF",
                          borderRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          border: `1px solid ${
                            (!this.state.form.phone &&
                              !this.state.propagateFormErrors) ||
                            this.isPhoneValid()
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "17px",
                          width: "100%",
                        }}
                        value={this.state.form.buyDataPhone || ""}
                        onlyCountries={["ng", "ke", "ug"]}
                        onBlur={() =>
                          this.setState({
                            fieldsFocused: [
                              ...this.state.fieldsFocused,
                              "phone",
                            ],
                          })
                        }
                        onChange={(value, data) => {
                          this.updateFormField({ buyDataPhone: value });
                        }}
                        placeholder="08012345678"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Select a Network:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <Select
                        options={
                          this.props.serviceOptions &&
                          this.props.serviceOptions.filter((value) => {
                            return value.name.toLowerCase().includes("data");
                          })
                        }
                        onChange={(event) => {
                          const paymentDescription = event.name;
                          const urlName = event.urlName;
                          this.updateFormField({ paymentDescription });
                          this.getNetworkBundlePlan(urlName);
                        }}
                        className="select-input-container-airtime"
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "rgb(178, 212, 255)",
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Data Plan:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      {this.state.isGetNetworkBundlePlanLoading ? (
                        <IosRefresh
                          style={{
                            marginLeft: "5%",
                            width: "40px",
                            position: "absolute",
                          }}
                          fontSize="100px"
                          color="grey"
                          rotate={true}
                        />
                      ) : (
                        <Select
                          options={
                            this.state.networkBundlePlan &&
                            this.state.networkBundlePlan
                          }
                          onChange={(event) => {
                            const buyDataAmount = convertNgkToNgn(event.amount);
                            const paymentCode = event.paymentCode;
                            this.updateFormField({
                              buyDataAmount,
                              paymentCode,
                            });
                          }}
                          className="select-input-container-airtime"
                          classNamePrefix="react-select"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "rgb(178, 212, 255)",
                            },
                          })}
                        />
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Col} md="12" controlId="amount">
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Amount:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <InputGroup style={{ borderRadius: 10 }}>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="inputGroupPrepend"
                            style={{
                              borderTopLeftRadius: process.env
                                .REACT_APP_BORDER_STYLE
                                ? "4px"
                                : 20,
                              borderBottomLeftRadius: process.env
                                .REACT_APP_BORDER_STYLE
                                ? "4px"
                                : 20,
                            }}
                          >
                            NGN
                          </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          type="tel"
                          value={this.state.form.buyDataAmount}
                          onChange={(event) => {
                            const buyDataAmount = event.target.value;
                            this.updateFormField({ buyDataAmount });
                          }}
                          style={{
                            backgroundColor: "#FFFFFF",
                            border: `1px solid #DFE2E6`,
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            boxSizing: "border-box",
                            fontSize: "12px",
                            lineHeight: "17px",
                            padding: "20px",
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="gender">
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Gender:{" "}
                      </FormLabel>
                      <Row>
                        <Col md={6}>
                          <input
                            type="radio"
                            name="gender"
                            value="Male"
                            id="male"
                            onChange={(event) => {
                              const buyDataGender = event.target.value;
                              this.updateFormField({ buyDataGender });
                            }}
                          />{" "}
                          <label for="male">Male</label>
                        </Col>
                        <Col md={6}>
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            id="female"
                            onChange={(event) => {
                              const buyDataGender = event.target.value;
                              this.updateFormField({ buyDataGender });
                            }}
                          />{" "}
                          <label for="female">Female</label>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="proceed-button-col">
                    <Button
                      className="services-proceed-button"
                      disabled={
                        this.props.isLoading || !this.isBuyDataFormValid()
                      }
                      onClick={() =>
                        this.props.onBuyDataProceed(this.state.form)
                      }
                    >
                      {this.props.isLoading ? "Loading..." : "Continue"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div label="EPin">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color:
                            this.state.form.phone && !this.isPhoneValid()
                              ? process.env.REACT_APP_RED_COLOUR
                              : "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Phone Number:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <ReactPhoneInput
                        buttonStyle={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                        }}
                        containerStyle={{
                          margin: 0,
                          padding: 0,
                          width: "100%",
                        }}
                        defaultCountry={"ng"}
                        disableCountryCode={true}
                        inputStyle={{
                          backgroundColor: "#FFFFFF",
                          borderRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : "20px",
                          border: `1px solid ${
                            (!this.state.form.phone &&
                              !this.state.propagateFormErrors) ||
                            this.isPhoneValid()
                              ? "#DFE2E6"
                              : process.env.REACT_APP_RED_COLOUR
                          }`,
                          boxSizing: "border-box",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "17px",
                          width: "100%",
                        }}
                        value={this.state.form.phone || ""}
                        onlyCountries={["ng", "ke", "ug"]}
                        onBlur={() =>
                          this.setState({
                            fieldsFocused: [
                              ...this.state.fieldsFocused,
                              "phone",
                            ],
                          })
                        }
                        onChange={(value, data) => {
                          this.updateFormField({ phone: value });
                        }}
                        placeholder="08012345678"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Select a Network:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <Select
                        options={this.state.networksEpin}
                        onChange={(event) => {
                          const network = event.name;
                          const paymentCode = NetworkPaymentCodesEpin[network];
                          this.updateFormField({ paymentCode, network });
                        }}
                        className="select-input-container-airtime"
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "rgb(178, 212, 255)",
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group as={Col} md="12" controlId="amount">
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Amount:{" "}
                        <span
                          style={{ color: process.env.REACT_APP_RED_COLOUR }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <InputGroup style={{ borderRadius: 10 }}>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="inputGroupPrepend"
                            style={{
                              borderTopLeftRadius: process.env
                                .REACT_APP_BORDER_STYLE
                                ? "4px"
                                : 20,
                              borderBottomLeftRadius: process.env
                                .REACT_APP_BORDER_STYLE
                                ? "4px"
                                : 20,
                            }}
                          >
                            NGN
                          </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          type="tel"
                          value={this.state.form.amount}
                          onChange={(event) => {
                            const amount = event.target.value;
                            this.updateFormField({ amount });
                          }}
                          style={{
                            backgroundColor: "#FFFFFF",
                            border: `1px solid #DFE2E6`,
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            boxSizing: "border-box",
                            fontSize: "12px",
                            lineHeight: "17px",
                            padding: "20px",
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="gender">
                      <FormLabel
                        style={{
                          color: "#3A3B3B",
                          fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                          lineHeight: "19px",
                        }}
                      >
                        Gender:{" "}
                      </FormLabel>
                      <Row>
                        <Col md={6}>
                          <input
                            type="radio"
                            name="gender"
                            value="Male"
                            id="male"
                            onChange={(event) => {
                              const gender = event.target.value;
                              this.updateFormField({ gender });
                            }}
                          />{" "}
                          <label for="male">Male</label>
                        </Col>
                        <Col md={6}>
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            id="female"
                            onChange={(event) => {
                              const gender = event.target.value;
                              this.updateFormField({ gender });
                            }}
                          />{" "}
                          <label for="female">Female</label>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="proceed-button-col">
                    <Button
                      className="services-proceed-button"
                      disabled={
                        this.props.isLoading || !this.isBuyAirtimeFormValid()
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onBuyAirtimeProceed(this.state.form);
                      }}
                    >
                      {this.props.isLoading ? "Loading..." : "Continue"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Tabs>
        )}
      </div>
    );
  }

  get ussdWithdrawalForm() {
    return (
      <Form>
        {this.props.ussdBanksLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: `${
                        !this.state.form.senderPhone ||
                        this.isSenderPhoneValid()
                          ? "#3A3B3B"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Phone:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <ReactPhoneInput
                    buttonStyle={{
                      borderTopLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      borderBottomLeftRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                    }}
                    containerStyle={{ margin: 0, padding: 0, width: "100%" }}
                    defaultCountry={"ng"}
                    disableCountryCode={true}
                    inputStyle={{
                      backgroundColor: "#FFFFFF",
                      borderRadius:
                        process.env.REACT_APP_BORDER_STYLE === "square"
                          ? "4px"
                          : "20px",
                      border: `1px solid ${
                        (!this.state.form.beneficiaryPhone &&
                          !this.state.propagateFormErrors) ||
                        this.isBeneficiaryPhoneValid()
                          ? "#DFE2E6"
                          : process.env.REACT_APP_RED_COLOUR
                      }`,
                      boxSizing: "border-box",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "17px",
                      width: "100%",
                    }}
                    value={this.state.form.senderPhone || ""}
                    onlyCountries={["ng", "ke", "ug"]}
                    onBlur={() =>
                      this.setState({
                        fieldsFocused: [
                          ...this.state.fieldsFocused,
                          "beneficiaryPhone",
                        ],
                      })
                    }
                    onChange={(value) => {
                      this.updateFormField({ senderPhone: value });
                    }}
                    placeholder="08012345678"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Bank:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <Select
                    options={
                      this.props.ussdCashoutBanks && this.props.ussdCashoutBanks
                    }
                    onChange={(event) => {
                      const bank = event;
                      this.updateFormField({ bank });
                      this.state.form.accountNumber &&
                        this.validateAccountDetails(bank);
                    }}
                    styles={customStylesBanks}
                    className="select-input-container-banks"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "rgb(178, 212, 255)",
                      },
                    })}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group as={Col} md="12" controlId="amount">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_BIG_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Amount:{" "}
                    <span style={{ color: process.env.REACT_APP_RED_COLOUR }}>
                      *
                    </span>
                  </FormLabel>
                  <InputGroup style={{ borderRadius: 10 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroupPrepend"
                        style={{
                          borderTopLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                          borderBottomLeftRadius:
                            process.env.REACT_APP_BORDER_STYLE === "square"
                              ? "4px"
                              : 20,
                        }}
                      >
                        NGN
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      type="tel"
                      value={this.state.form.amount}
                      onChange={(event) => {
                        const amount = event.target.value;
                        this.updateFormField({ amount });
                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: `1px solid #DFE2E6`,
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                        boxSizing: "border-box",
                        fontSize: "12px",
                        lineHeight: "17px",
                        padding: "20px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={6} md={6}>
                <Form.Group controlId="gender">
                  <FormLabel
                    style={{
                      color: "#3A3B3B",
                      fontSize: process.env.REACT_APP_MID_FONT_SIZE,
                      lineHeight: "19px",
                    }}
                  >
                    Gender:
                  </FormLabel>
                  <Row>
                    <Col xs={6} md={6}>
                      <input
                        type="radio"
                        name="gender"
                        value="Male"
                        id="male"
                        onChange={(event) => {
                          const gender = event.target.value;
                          this.updateFormField({ gender });
                        }}
                      />{" "}
                      <label for="male">Male</label>
                    </Col>
                    <Col xs={6} md={6}>
                      <input
                        type="radio"
                        name="gender"
                        id="female"
                        value="Female"
                        onChange={(event) => {
                          const gender = event.target.value;
                          this.updateFormField({ gender });
                        }}
                      />{" "}
                      <label for="female">Female</label>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="proceed-button-col">
                <Button
                  disabled={
                    this.props.isLoading || !this.isUssdWithdrawalFormValid()
                  }
                  className="services-proceed-button"
                  onClick={(e) =>
                    this.props.onUssdWithdrawalProceed(this.state.form, e)
                  }
                >
                  {this.props.isLoading ? "Loading..." : "Continue"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }

  get paycodeWithdrawalForm() {
    return (
      <>
        {this.props.showReceiptModal && (
          <VerticallyCenteredModal
            onHide={this.props.handleUsedModalClose}
            show={this.props.showReceiptModal}
            body={
              <NewCashoutReceipt
                payment={this.props.payment}
                code={this.props.code}
                currentUser={this.props.currentUser}
              />
            }
            receipt
            hideFooter
            deviceIsMobile={this.props.deviceIsMobile}
          />
        )}
        {/* <VerticallyCenteredModal 
            onHide={handleClose}
            show={show}
            title={this.props.paycodeError}
            body={<CashoutSuccessfullModal />}
            payment
            hideFooter
            deviceIsMobile={this.props.deviceIsMobile}
          /> */}
        {this.props.showErrorModal && (
          <VerticallyCenteredModal
            onHide={this.props.handleUsedModalClose}
            show={this.props.showErrorModal}
            title={`Error`}
            body={
              <UsedCashoutModal
                errorMessage={this.props.paycodeError}
                paycode={this.state.form.payCode}
                cancelClick={this.props.handleUsedModalClose}
              />
            }
            payment
            hideFooter
            deviceIsMobile={this.props.deviceIsMobile}
          />
        )}
        {this.props.showProceedModal && (
          <VerticallyCenteredModal
            onHide={this.props.handleUsedModalClose}
            show={this.props.showProceedModal}
            title={
              <>
                <p>Transaction Details</p>
                <span>Please confirm the cash out details below</span>
              </>
            }
            body={
              <CashoutModalContent
                payment={this.props.payment}
                form={this.state.form}
                onPaycodeCashoutConfirmation={
                  this.props.onPayCodeWithdrawalConfirmation
                }
                isLoading={this.props.isLoading}
              />
            }
            payment
            hideFooter
            deviceIsMobile={this.props.deviceIsMobile}
          />
        )}
        {this.state.cashoutFormTab === 2 ? (
          <Form>
            <>
              {/* <Row className="header-cashout-row">
                <Col xs={12} md={12} className="about-cashout">
                  <div className="cashout-row">
                    <p>{cashOutHeaderIcon()}{" "}Why we collect customer data</p>
                    {closeIcon()}
                  </div>
                  <p>To help us measure the impact of financial inclusion services in Nigeria</p>
                </Col>
              </Row> */}

              <Row className="main-cashout-row">
                <Col xs={12} md={12}>
                  <Form.Group controlId="phoneNumber">
                    <FormLabel>Phone number</FormLabel>
                    <FormControl
                      value={this.state.form.phoneNumber}
                      onChange={(event) => {
                        const phoneNumber = event.target.value;
                        this.updateFormField({ phoneNumber });
                      }}
                      placeholder="08012345678"
                    />
                    {/* <span className="error-text">{dangerIcon()}{"  "}Please enter a paycode</span> */}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="gender-radio-button">
                <Col md={6} className="">
                  <Button
                    className={"services-proceed-button"}
                    id={this.state.form.gender === "female" && "clicked"}
                    onClick={(e) => {
                      e.preventDefault();
                      const gender = "female";
                      this.updateFormField({ gender });
                    }}
                  >
                    <label for="female">Female</label>
                  </Button>
                </Col>
                <Col md={6} className="">
                  <Button
                    className={"services-proceed-button"}
                    id={this.state.form.gender === "male" && "clicked"}
                    onClick={(e) => {
                      e.preventDefault();
                      const gender = "male";
                      this.updateFormField({ gender });
                    }}
                  >
                    <label for="male">Male</label>
                  </Button>
                </Col>
              </Row>

              <Row className="button-cashout-row">
                <Button
                  className="cashout-next-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      cashoutFormTab: 2,
                    });
                  }}
                  disabled={this.props.isLoading || !this.isCustomerDataValid()}
                >
                  {`Next`}
                  {ArrowIcon()}
                </Button>
              </Row>
            </>
          </Form>
        ) : (
          <Form>
            <>
              <Row className="header-cashout-row">
                <Col xs={12} md={12} className="about-cashout">
                  <div className="cashout-row">
                    <p>{cashOutHeaderIcon()} About Cash Out</p>
                    {closeIcon()}
                  </div>
                  <p>
                    Dispense cash to customers with Cash Out. Simply enter the
                    customers paycode and cashout PIN and confirm the
                    transaction.
                    {/* <span>See the step by step guide</span> */}
                  </p>
                </Col>
              </Row>

              <Row className="main-cashout-row">
                {/* hack to prevent prefilled input filed */}
                <Col xs={12} md={12}>
                  <Form.Group controlId="paycodeHidden">
                    <FormControl
                      value={this.state.payCode}
                      onChange={(event) => {}}
                      placeholder="1234567890"
                      defaultValue={""}
                      type="hidden"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group controlId="paycode">
                    <FormLabel>Paycode</FormLabel>
                    <FormControl
                      value={this.state.payCode}
                      onChange={(event) => {
                        const payCode = event.target.value;
                        this.updateFormField({ payCode });
                      }}
                      placeholder="1234567890"
                      defaultValue={""}
                      autocomplete="off"
                    />
                    <span>Paycode is a 10-14 digit code</span>
                    <br />
                    {/* <span className="error-text">{dangerIcon()}{"  "}Please enter a paycode</span> */}
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group controlId="pin">
                    <FormLabel>Cash out PIN</FormLabel>
                    <FormControl
                      value={this.state.cashOutPin}
                      onChange={(event) => {
                        const cashOutPin = event.target.value;
                        this.updateFormField({ cashOutPin });
                      }}
                      type="password"
                      placeholder="****"
                      autocomplete="new-password"
                    />
                    <span>4 digit PIN</span>
                    <br />
                    {/* <span className="error-text">{dangerIcon()}{"  "}Please enter the cashout PIN</span> */}
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group controlId="amount">
                    <FormLabel>Amount</FormLabel>
                    <FormControl
                      value={this.state.amount}
                      onChange={(event) => {
                        const amount = event.target.value;
                        this.updateFormField({ amount });
                      }}
                      placeholder="1000"
                    />
                    {/* <span className="error-text">{dangerIcon()}{"  "}Please enter an amount</span> */}
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group controlId="phone">
                    <FormLabel>Initiator phone number</FormLabel>
                    <FormControl
                      value={this.state.initiatorPhone}
                      onChange={(event) => {
                        const initiatorPhone = event.target.value;
                        this.updateFormField({ initiatorPhone });
                      }}
                      placeholder="08000000000"
                    />
                    <span>Phone number of the paycode generator</span>
                    <br />
                    {/* <span className="error-text">{dangerIcon()}{"  "}Please enter the initiator phone number</span> */}
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group controlId="phone">
                    <FormLabel>Phone number</FormLabel>
                    <FormControl
                      value={this.state.phoneNumber}
                      onChange={(event) => {
                        const phoneNumber = event.target.value;
                        this.updateFormField({ phoneNumber });
                      }}
                      placeholder="08000000000"
                    />
                    <span>Customer phone number</span>
                    <br />
                    {/* <span className="error-text">{dangerIcon()}{"  "}Please enter the initiator phone number</span> */}
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Row className="gender-radio-button">
                    <Col md={6} className="">
                      <Button
                        className={"services-proceed-button"}
                        id={this.state.form.gender === "female" && "clicked"}
                        onClick={(e) => {
                          e.preventDefault();
                          const gender = "female";
                          this.updateFormField({ gender });
                        }}
                      >
                        <label for="female">Female</label>
                      </Button>
                    </Col>
                    <Col md={6} className="">
                      <Button
                        className={"services-proceed-button"}
                        id={this.state.form.gender === "male" && "clicked"}
                        onClick={(e) => {
                          e.preventDefault();
                          const gender = "male";
                          this.updateFormField({ gender });
                        }}
                      >
                        <label for="male">Male</label>
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Row style={{ margin: "auto" }}>
                  <Col xs={12} md={12} className="">
                    <Button
                      className="services-proceed-button"
                      onClick={(e) => {
                        this.props.onPayCodeWithdrawalProceed(
                          this.state.form,
                          e
                        );
                      }}
                      disabled={
                        this.props.isLoading || !this.isPaycodeDataValid()
                      }
                    >
                      Verify paycode
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          </Form>
        )}
      </>
    );
  }

  isAccountNumberComplete() {
    return RegexChecker.accountNumber.test(this.state.form.accountNumber);
  }

  isSenderNameValid() {
    return RegexChecker.nameChecker.test(this.state.form.senderName);
  }

  isBeneficiaryEmailValid() {
    return RegexChecker.emailChecker.test(this.state.form.beneficiaryEmail);
  }

  isSenderEmailValid() {
    return RegexChecker.emailChecker.test(this.state.form.senderEmail);
  }

  isBeneficiaryNameValid() {
    return (
      this.state.form.beneficiaryName &&
      this.state.form.beneficiaryName.length > 2
    );
  }

  isSendMoneyFormValid() {
    return (
      // this.isSenderNameValid() &&
      this.isBeneficiaryNameValid() &&
      this.isAccountNumberComplete() &&
      this.state.form.bank &&
      this.isBeneficiaryPhoneValid() &&
      this.state.form.amount
    );
  }

  isSendMoneyNIPFormValid() {
    return (
      this.isBeneficiaryNameValid() &&
      this.isAccountNumberComplete() &&
      this.state.form.bank &&
      this.isBeneficiaryPhoneValid() &&
      this.state.form.amount
    );
  }

  isSendMoneyToAgentValid() {
    return (
      this.state.form.walletToWalletbeneficiaryPhone &&
      this.state.form.amount &&
      this.iswWalletToWalletbeneficiaryPhoneValid()
    );
  }

  isUssdWithdrawalFormValid() {
    return (
      this.state.form.senderPhone &&
      this.state.form.amount &&
      this.state.form.bank &&
      this.isSenderPhoneValid()
    );
  }

  isPaycodeWithdrawalFormValid() {
    return (
      this.isPaycodeComplete() &&
      this.isSenderPhoneValid() &&
      this.isCustomerNameValid() &&
      this.isCustomerNumberValid() &&
      this.state.form.email &&
      this.state.form.amount
    );
  }

  isPaycodeComplete() {
    return true;
    // return RegexChecker.payCode.test(this.state.form.payCode)
  }

  isCustomerNameValid() {
    return RegexChecker.nameChecker.test(this.state.form.customerName);
  }

  compareAgents(currentAgent, selectedAgents) {
    if (Object.keys(currentAgent).length === 0) {
      return true;
    }

    const agent = selectedAgents.some((agent) => {
      if (currentAgent.agentCode === agent.beneficiaryAgentCode) {
        return true;
      } else {
        return false;
      }
    });
    return agent;
  }

  isDistributeMoneyToAgentValid() {
    return (
      this.props.distributeForm.currentAgent &&
      !this.compareAgents(
        this.props.distributeForm.currentAgent,
        this.props.selectedAgents
      ) &&
      this.props.distributeForm.amount
    );
  }

  isDistributeMoneyToAgentContinueValid() {
    return (
      this.props.distributeForm.currentAgent && this.props.distributeForm.amount
    );
  }

  async validateAccountDetails(bank) {
    if (!this.isAccountNumberComplete) {
      this.setState({
        form: {
          ...this.state.form,
          beneficiaryName: "",
        },
      });
    }
    this.setState({
      validationLoading: true,
    });
    const bankValue = bank ? bank : this.state.form.bank;
    const deviceUuid = localStorage.getItem("deviceUid");
    const nameInquiry = await this.props.transaction.nameInquiry(
      this.state.form.accountNumber,
      bankValue.value,
      "",
      deviceUuid
    );
    const nameInquiryStatus = nameInquiry.status;
    const nameInquiryResponse = nameInquiry.response;
    this.props.updateLoading(false);

    if (nameInquiryStatus === ERROR_STATUS) {
      const beneficiaryName = "";
      this.setState({
        isDisabled: false,
        errorDescription:
          "Account name confirmation failed. Enter beneficiary name or click retry",
        validationLoading: false,
        form: {
          ...this.state.form,
          beneficiaryName,
        },
      });
      return;
    }
    const beneficiaryName = nameInquiryResponse.accountName;

    const bankObject = this.state.banks.find(
      (bank) => bank.cbnCode === bankValue.value
    );

    this.setState({
      validationLoading: false,
      errorDescription: "",
      isDisabled: true,
      form: {
        ...this.state.form,
        beneficiaryName,
        shortBankName: bankObject.shortBankName,
        institutionCode: bankObject.nipCode,
        bankName: bankObject.bankName,
      },
    });
  }

  async validateNipAccountDetails(bank) {
    if (!this.isAccountNumberComplete) {
      this.setState({
        form: {
          ...this.state.form,
          beneficiaryName: "",
        },
      });
    }
    this.setState({
      validationLoading: true,
    });
    const bankValue = bank ? bank : this.state.form.bank;

    const payload = {
      channelCode: "6",
      currencyCode: "NGN",
      accountNumber: this.state.form.accountNumber,
      institutionCode: bankValue.value,
      channel: "NIP",
    };

    const nameInquiry = await this.props.nipTransaction.validateAccountNumber(
      payload,
      localStorage.getItem("deviceUid")
    );

    const nameInquiryStatus = nameInquiry.status;
    const nameInquiryResponse = nameInquiry.response;
    this.props.updateLoading(false);

    if (nameInquiryStatus === ERROR_STATUS) {
      const beneficiaryName = "";
      this.setState({
        isDisabled: false,
        errorDescription:
          "Account name confirmation failed. Enter beneficiary name or click retry",
        validationLoading: false,
        form: {
          ...this.state.form,
          beneficiaryName,
        },
      });
      return;
    }
    const beneficiaryName = nameInquiryResponse.beneficiaryName;

    this.setState({
      validationLoading: false,
      errorDescription: "",
      isDisabled: true,
      form: {
        ...this.state.form,
        beneficiaryName,
      },
    });
  }

  updateFormField(params) {
    const newForm = {
      ...this.state.form,
      ...params,
    };

    this.setState({
      form: newForm,
    });
  }

  updateSubAccount(params) {
    const payment = this.props.payment;
    payment.billsPaymentRequest.parentCustomerId =
      payment.billsPaymentRequest.customerId;
    payment.billsPaymentRequest.customerId = params.customerSubAccNo;

    this.setState({
      formWithSubAccount: payment,
      customerSubAccNo: params.customerSubAccNo,
    });
  }

  get toHideBackButton() {
    return (
      this.props.category === PAY_A_BILL || this.props.category === CASH_IN
    );
  }

  render() {
    var headerCaption = normalizeToTitleCase(this.props.category);
    return (
      <div
        style={{
          backgroundColor:
            this.props.category === SEND_MONEY && this.props.service.id === 5
              ? ""
              : "white",
          borderRadius: "8px",
          padding:
            this.props.category === SEND_MONEY && this.props.service.id === 4
              ? ""
              : "30px",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {this.props.category === SEND_MONEY &&
          this.props.service.id === 5 ? null : (
            <H1
              style={{
                fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
                color: process.env.REACT_APP_BLACK_COLOUR,
                fontWeight: "normal",
              }}
            >
              {headerCaption.indexOf("Pos") !== -1
                ? headerCaption.replace("Pos", "POS")
                : this.props.service.id === 7
                ? "Cash In"
                : headerCaption}
            </H1>
          )}
          <Button
            hidden={!this.toHideBackButton}
            outline={true}
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: process.env.REACT_APP_LINK_COLOUR,
              fontFamily: process.env.REACT_APP_FONT_FAMILY_BODY,
              fontWeight: "normal",
              padding: 0,
            }}
            onClick={() => this.props.onCancelProduct()}
          >
            Back to All
          </Button>
        </div>

        {this.props.category === SEND_MONEY &&
        this.props.service.id === 5 ? null : (
          <HR style={{ marginTop: "10px", width: "100%" }} />
        )}
        {this.props.category === PAY_A_BILL && this.payABillForm}
        {this.props.category === SEND_MONEY && this.props.service.id === 1 && (
          <SendMoneyForm
            setState={this.setState.bind(this)}
            props={this.props}
            state={this.state}
            isAccountNumberComplete={this.isAccountNumberComplete}
            customStylesBanks={customStylesBanks}
            isBeneficiaryNameValid={this.isBeneficiaryNameValid}
            validateAccountDetails={this.validateAccountDetails}
            isBeneficiaryPhoneValid={this.isBeneficiaryPhoneValid}
            isSendMoneyFormValid={this.isSendMoneyFormValid}
          />
        )}
        {this.props.category === SEND_MONEY && this.props.service.id === 7 && (
          <TransferCashinForm
            setState={this.setState.bind(this)}
            props={this.props}
            state={this.state}
            isAccountNumberComplete={this.isAccountNumberComplete}
            customStylesBanks={customStylesBanks}
            isBeneficiaryNameValid={this.isBeneficiaryNameValid}
            validateAccountDetails={this.validateAccountDetails}
            isBeneficiaryPhoneValid={this.isBeneficiaryPhoneValid}
            isSendMoneyFormValid={this.isSendMoneyFormValid}
          />
        )}
        {this.props.category === SEND_MONEY &&
          this.props.service.id === 5 &&
          this.cashin}
        {this.props.category === SEND_MONEY &&
          this.props.service.id === 4 &&
          this.sendMoneyNipForm}
        {this.props.category === SEND_MONEY &&
          this.props.service.id === 2 &&
          this.walletToWalletForm}
        {this.props.category === SELL_AIRTIME && this.buyAirtimeForm}
        {this.props.category === CASH_IN && (
          <CashInForm
            setState={this.setState.bind(this)}
            props={this.props}
            state={this.state}
            customerIdFieldIsEmail={this.customerIdFieldIsEmail}
            customerIdFieldIsPhone={this.customerIdFieldIsPhone}
            isEmailValid={isEmailValid}
            isPhoneValid={this.isPhoneValid}
            isFormValid={this.isFormValid}
            updateFormField={this.updateFormField}
          />
        )}
        {this.props.category === REQUEST_POS && (
          <PosRequestForm
            handlePosTypeSelection={this.handlePosTypeSelection}
            handlePosModelSelection={this.handlePosModelSelection}
            setState={this.setState.bind(this)}
            handlePosRequestType={this.handlePosRequestType}
            handleQuantityChange={this.handleQuantityChange}
            updatePosRequestFormField={this.updatePosRequestFormField}
            handleChangeImagePhoto={this.handleChangeImagePhoto}
            props={this.props}
            state={this.state}
          />
        )}
        {/* {this.props.category === CASH_IN && this.payABillForm} */}
        {this.props.category === DISTRIBUTE && this.distributeForm}
        {this.props.category === WITHDRAWAL &&
          this.props.service.id === 1 &&
          this.ussdWithdrawalForm}
        {this.props.category === WITHDRAWAL &&
          this.props.service.id === 2 &&
          this.paycodeWithdrawalForm}
      </div>
    );
  }
}
