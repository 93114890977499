import React from 'react';
import { Col } from 'react-bootstrap';
import H1 from '../../../../../components/h1';
import MdArrowDropright from 'react-ionicons/lib/MdArrowDropright';

const Title = ({ pageNum }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <H1
          style={{
            textAlign: 'left',
            marginBottom: '.7rem',
          }}
        >
          Agent Pre-Setup
        </H1>
        <p style={{ fontSize: '1rem' }}>{pageNum} of 4</p>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '5px',
          //   alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        <p
          style={{
            color: '#6B7280',
            fontSize: '0.75rem',
            marginBottom: '3rem',
          }}
        >
          Application
        </p>
        <MdArrowDropright fontSize={'15px'} color={'#6B7280'} />
        <p
          style={{
            color: '#6B7280',
            fontSize: '0.75rem',
            marginBottom: '3rem',
          }}
        >
          Add New Agent
        </p>
      </div>
    </div>
  );
};

export default Title;
