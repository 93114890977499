import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { firetoast, notify } from "../../../../components/extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import { CloseIcon, InfoIcon } from "../../../../icons";
import Onboarding from "../../../../services/api/resources/onboarding";
import BVNModal from "./bvnModal";

const AccountTypeModal = ({ application, show, handleClose }) => {
  const history = useHistory();
  const onboarding = new Onboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [bvnDetailsForm, setBVNDetailsForm] = useState({
    bvn: application?.applicantDetails?.bvnDetails?.bvn || "",
  });
  const [showUnregisteredBusinessModal, setShowUnregisteredBusinessModal] =
    useState(false);
  const [showRegisteredBusinessModal, setShowRegisteredBusinessModal] = useState(false);

  const confirmBVN = async () => {
    setIsLoading(true);
    if (bvnDetailsForm.bvn) {
      const { status, response } = await onboarding.bvnConfirmation(
        bvnDetailsForm.bvn
      );

      setIsLoading(false);
      if (status === ERROR_STATUS) {
        firetoast("error", response.description, "");
      } else if (status === SUCCESS_STATUS) {
        if (response?.code === "20000") {
          notify("success");
          localStorage.setItem("bvnInformation", JSON.stringify(response));
          history.push("/signup/onboarding-new-agent");
        } else {
         
        }
      }
    }
  };

  const iconStyle = {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  };

  useEffect(() => {
    if (bvnDetailsForm.bvn.length === 11) {
      confirmBVN();
    }
  }, [bvnDetailsForm.bvn]);

  const handleUnregisteredBusinessClick = () => {
    
    localStorage.setItem("accountType", "unregistered");
    setShowUnregisteredBusinessModal(true);
  };

  const handleProceedClick = () => {
    
    localStorage.setItem("accountType", "unregistered");
    setShowUnregisteredBusinessModal(false);
    history.push("/signup/onboarding-new-agent");
  };

  const handleRegisteredBusinessClick = () => {

    localStorage.setItem("accountType", "registered");
    setShowRegisteredBusinessModal(true);  
  };

  const handleCloseBVNModal = () => {
    setShowRegisteredBusinessModal(false);
  };

  return (
    <>
      {/* Main Modal for Selecting Account Type */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          backdropFilter: "blur(8px)",
          backgroundColor: "rgba(16, 24, 40, 0.3)",
        }}
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <h1
              style={{
                flex: 1,
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "1.2rem",
                color: "#1F2937",
              }}
            >
              Select Account Type
            </h1>
            <CloseIcon
              onClick={handleClose}
              style={{ ...iconStyle, cursor: "pointer" }}
            />
          </div>

          <span
            style={{
              color: "#5F738C",
              letterSpacing: "0.3px",
              fontSize: "0.9rem"
            }}
          >
            Please select account type to proceed
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
              marginBottom: "2rem",
              gap: "1rem",
            }}
          >
            <button
              disabled={isLoading}
              onClick={handleRegisteredBusinessClick}
              className="signupButton5"
              style={{
                color: "#072F40",
                width: "100%",
                minWidth: "0",
                fontWeight: "600",
                boxSizing: "border-box",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#072F40" }}>
                I am a Registered Business
              </span>
            </button>

            <button
              disabled={isLoading}
              onClick={handleUnregisteredBusinessClick}
              className="signupButton5"
              style={{
                color: "#072F40",
                fontWeight: "600",
                boxSizing: "border-box",
                width: "100%",
                minWidth: "0",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#072F40" }}>
                I am an Unregistered Business
              </span>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Secondary Modal for Unregistered Business */}
      <Modal
        show={showUnregisteredBusinessModal}
        onHide={() => setShowUnregisteredBusinessModal(false)}
        aria-labelledby="unregistered-business-modal"
        centered
        style={{ backdropFilter: "blur(8px)" }}
      >
        <Modal.Body style={{ padding: "2rem" }}>
          <InfoIcon
            style={{ ...iconStyle }}
          />
          <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <p
              style={{
                color: "#5F738C",
                fontWeight: "500",
                fontSize: "0.8rem",
                letterSpacing: "0.5px",
              }}
            >
              Please note that an account will be created for you. However, you
              will have to complete your CAC registration to start transacting.
            </p>
          </div>
          <button
            onClick={handleProceedClick}
            className="signupButton2"
            style={{
              marginTop: "1rem",
              color: "white",
              backgroundColor: "#00425f",
              width: "100%",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Proceed
          </button>
          <button
            onClick={() => setShowUnregisteredBusinessModal(false)}
            className="signupButton1"
            style={{
              marginTop: "1rem",
              color: "#353F50",
              backgroundColor: "white",
              width: "100%",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>

      {/* BVN Modal for Registered Business */}
      <BVNModal
        show={showRegisteredBusinessModal}
        handleClose={handleCloseBVNModal}
      />
    </>
  );
};

export default AccountTypeModal;
