import Requester from "../finch-requester";

const API_BASE_URL = process.env.REACT_APP_CAC_API_BASE_URL;

export default class CAC {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getCacRegistrationReport(pageSize = null, pageNum = null, agentCode) {
    return this.apiRequester.get({
      endpoint: `cac/reports`,
      args: {
        pageSize,
        pageNum,
        agentCode,
      },
    });
  }
  updateCacRegistrationReport(payload) {
    return this.apiRequester.put({
      endpoint: `cac/register`,
      body: payload,
    });
  }
  requeryCacRegistrationReport(reference) {
    return this.apiRequester.get({
      endpoint: `cac/status?reference=${reference}`,
    });
  }
}
