import React from "react";

import { Button } from "react-bootstrap";
const PrimaryButton2 = ({ children, onClick, disabled }) => {
    return (
        <Button
            disabled={disabled}
            style={{
                fontSize: "14px",
                borderRadius: "8px",
                width: "100%",
                height: "48px",
                cursor: disabled ? "not-allowed" : "pointer",
                margin: "-20px 0 40px 0",
                backgroundColor: "#fff",
                color: "#00425f",
            }}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

export default PrimaryButton2;
