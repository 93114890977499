import Requester from '../finch-requester';

const API_BASE_URL = process.env.REACT_APP_TRANSACTION_API_BASE_URL;
const API_BASE_URL_IKEJA = process.env.REACT_APP_TRANSACTION_API_BASE_URL_IKEJA;
const ikedcCodes = process.env.REACT_APP_DISTRIBUTE_PAYMENT_IKEDC_CODES;

export default class Transaction {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
      new Requester({
        apiBaseUrl: API_BASE_URL,
      })
      : new Requester({
        apiBaseUrl: API_BASE_URL,
      });
    this.apiRequesterikeja = props
      ? props.apiRequesterikeja ||
      new Requester({
        apiBaseUrl: API_BASE_URL_IKEJA,
      })
      : new Requester({
        apiBaseUrl: API_BASE_URL_IKEJA,
      });
  }

  ping() {
    return this.apiRequester.get({
      endpoint: 'actuator/health',
    });
  }

  getWalletBalance() {
    return this.apiRequester.getIgnore401({
      endpoint: 'v2/finch-transaction/wallet/balance',
    });
  }

  getWalletBalanceByPost(data) {
    return this.apiRequester.postIgnore401({
      endpoint: 'v2/finch-transaction/wallet/balance',
      body: data,
    });
  }

  getPaymentMethods(businessPhoneNumber) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/wallet/cards/${businessPhoneNumber}`,
    });
  }

  unloadCommission(amount) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/wallet/commissions/unload',
      body: {
        amount,
      },
    });
  }

  addPaymentMethod(body) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/wallet/addCard',
      body,
    });
  }

  initiateBillPayment(billsPaymentRequest, checksum, deviceUuid) {
    const billerTypeRaw = JSON.parse(localStorage.getItem('services'));
    const billerType = billerTypeRaw[0].billerType;
    if (
      billerType === 'BP' ||
      ikedcCodes?.includes(billsPaymentRequest?.paymentItemCode)
    ) {
      return this.apiRequester.post({
        endpoint: 'v3/finch-transaction/payments/initialize',
        body: {
          transactionType: 'bills',
          billsPaymentRequest,
          checksum,
        },
        headers: {
          deviceUuid,
        },
      });
    } else {
      return this.apiRequester.post({
        endpoint: 'v2/finch-transaction/payments/initialize',
        body: {
          transactionType: 'bills',
          billsPaymentRequest,
          checksum,
        },
        headers: {
          deviceUuid,
        },
      });
    }
  }

  processBillPayment(paymentRequest, deviceUuid) {
    const billerTypeRaw = JSON.parse(localStorage.getItem('services'));
    const billerType = billerTypeRaw[0].billerType;
    if (
      billerType === 'BP' ||
      ikedcCodes?.includes(paymentRequest.billsPaymentRequest.paymentItemCode)
    ) {
      return this.apiRequester.post({
        // return this.apiRequesterikeja.post({
        endpoint: 'v3/finch-transaction/payments/proceed',
        body: paymentRequest,
        headers: {
          deviceUuid,
        },
      });
    } else {
      return this.apiRequester.post({
        endpoint: 'v2/finch-transaction/payments/proceed',
        body: paymentRequest,
        headers: {
          deviceUuid,
        },
      });
    }
  }

  initiateCashInPayment(billsPaymentRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/initialize',
      body: {
        transactionType: 'cash_in',
        billsPaymentRequest,
        checksum,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processCashInPayment(cashInRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/proceed',
      body: cashInRequest,
      headers: {
        deviceUuid,
      },
    });
  }

  initiateAirtimePayment(rechargeRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/initialize',
      body: {
        transactionType: 'recharge',
        rechargeRequest,
        checksum,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processAirtimePayment(rechargeRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/proceed',
      body: rechargeRequest,
      headers: {
        deviceUuid,
      },
    });
  }


  initiateTransfer(transferRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/initialize',
      body: {
        checksum,
        transactionType:
          transferRequest['unifiedTransferRequest'].transactionType,
        ...transferRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processTransfer(transferRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/proceed',
      body: transferRequest,
      headers: {
        deviceUuid,
      },
    });
  }

  initiateNIPTransfer(nipTransferRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/initialize',
      body: {
        checksum,
        transactionType: 'NIP',
        nipTransferRequest,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processNIPTransfer(nipTransferRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/proceed',
      body: nipTransferRequest,
      headers: {
        deviceUuid,
      },
    });
  }

  initiateWalletToWallet(w2wRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/initialize',
      body: {
        transactionType: 'W2W',
        w2wRequest,
        checksum,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processWalletToWallet(w2WRequest, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-transaction/payments/proceed',
      body: w2WRequest,
      headers: {
        deviceUuid,
      },
    });
  }

  nameInquiry(accountNumber, bankCode, deviceUuid) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/enquiry/banks/${bankCode}/accounts/${accountNumber}`,
      headers: {
        deviceUuid,
      },
    });
  }

  customerInquiry(customerId, paymentCode) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/enquiry/customers`,
      args: {
        customerId,
        paymentCode,
      },
    });
  }

  getTransactionReversal(transactionReference) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/system/reversals/${transactionReference}`,
    });
  }

  validateAccountNumber(payload, deviceUuid) {
    return this.apiRequester.post({
      endpoint: `v2/finch-transaction/payments/validate`,
      body: payload,
      headers: {
        deviceUuid,
      },
    });
  }

  getNipBanks(deviceUuid) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/payments/v2/alias`,
      headers: {
        deviceUuid,
      },
    });
  }

  getUnifiedBanks(deviceUuid) {
    return this.apiRequester.get({
      endpoint: `v1/finch-transaction/union/banks`,
      headers: {
        deviceUuid,
      },
    });
  }

  initiateCacRegPayment(cacInitiateRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'v1/finch-transaction/cac-registration/initialize',
      body: {
        transactionType: 'CAC_REGISTRATION',
        cacInitiateRequest,
        checksum,
      },
      headers: {
        deviceUuid,
      },
    });
  }

  processCacRegistrationPayment(cacRegistrationRequest, checksum, deviceUuid) {
    return this.apiRequester.post({
      endpoint: 'api/v1/finch-transaction/cac-registration/proceed',
      body: {
        transactionType: 'CAC_REGISTRATION',
        cacRegistrationRequest,
        checksum,
      },
      headers: {
        deviceUuid,
      },
    });
  }
}
