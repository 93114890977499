import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { firetoast, notify } from '../../../../components/extras';
import H1 from '../../../../components/h1';
import HeaderSignup from '../../../../components/header-signup';
import { NIGERIA_PHONE_PREFIX } from '../../../../constants';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../constants/api';
import Onboarding from '../../../../services/api/resources/onboarding';
import { formatPhoneNumber } from '../../../../utils/formatters';
import phoneValidators from '../../../../utils/validators/phone-validators';
import BackButton from '../component/backButton';
import PrimaryButton from '../component/button';
import { isBusinessAddressValidNOK, isEmailAddressValid, isNameValid } from '../component/extras';
import InputText from '../component/input';
import SelectInput from '../component/select';
import SignUp from '../component/signupText';
import Stepper from '../component/stepper';
import { isNumberValid } from '../../../../utils/validators/form-validators';
import PrimaryButton2 from '../component/button2';
import CongratsModal from '../component/congratsModal';


const MAX_BUSINESS_NAME_LENGTH = 50;
const NextOfKinScene = ({ setScreen, application }) => {
  const applicationId = localStorage.getItem('applicationId');
  const onboarding = new Onboarding();
  const [propagateFormErrors, setPropagateFormErrors] = useState(false);
  const [fieldsFocused, setFieldsFocused] = useState([]);
  const [showCongratsModal, setShowCongratsModal] = useState(false);


  const updatednextOfKinDetailsForm = JSON.parse(
    localStorage.getItem('nextOfKinDetailsForm')
  );

  const [nextOfKinDetailsForm, setNextOfKinDetailsForm] = useState({
    emailAddress: application ?
      application?.applicantDetails?.nextOfKin?.emailAddress
      : '',
    firstName: application ?
      application?.applicantDetails?.nextOfKin?.firstName
      : '',
    phoneNumber: application ?
      application?.applicantDetails?.nextOfKin?.phoneNumber
      : '',
    relationship: application ?
      application?.applicantDetails?.nextOfKin?.relationship
      : '',
    phonePrefix: NIGERIA_PHONE_PREFIX,
    address: application ?
      application?.applicantDetails?.nextOfKin?.address
      : '',
  });

  useEffect(() => {
    const updatednextOfKinDetailsForm = JSON.parse(
      localStorage.getItem('nextOfKinDetailsForm')
    );

    if (updatednextOfKinDetailsForm) {
      setNextOfKinDetailsForm({
        emailAddress:
          application?.applicantDetails?.nextOfKin?.emailAddress ||
          updatednextOfKinDetailsForm.emailAddress ||
          '',
        firstName:
          application?.applicantDetails?.nextOfKin?.firstName ||
          updatednextOfKinDetailsForm.firstName ||
          '',
        phoneNumber:
          application?.applicantDetails?.nextOfKin?.phoneNumber ||
          updatednextOfKinDetailsForm.phoneNumber ||
          '',
        relationship:
          application?.applicantDetails?.nextOfKin?.relationship ||
          updatednextOfKinDetailsForm.relationship ||
          '',
        address:
          application?.applicantDetails?.nextOfKin?.address ||
          updatednextOfKinDetailsForm.address ||
          '',
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem('nextOfKinDetailsForm', JSON.stringify(nextOfKinDetailsForm));
  }, [nextOfKinDetailsForm]);

  const [isLoading, setIsLoading] = useState(false);

  const updateFormField = (params) => {
    const newForm = {
      ...nextOfKinDetailsForm,
      ...params,
    };

    setNextOfKinDetailsForm(newForm);
  };

  const isSenderPhoneValid = (newForm) => {
    const Form = newForm || nextOfKinDetailsForm;
    const countryValidator = phoneValidators[nextOfKinDetailsForm.phonePrefix];
    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isBeneficiaryPhoneValid = (newForm) => {
    const Form = newForm || nextOfKinDetailsForm;
    const countryValidator = phoneValidators[nextOfKinDetailsForm.phonePrefix];

    return (
      Form.phoneNumber &&
      (countryValidator ? countryValidator(Form.phoneNumber) : true)
    );
  };

  const isFormValid = () => {
    return (
      nextOfKinDetailsForm.relationship &&
      nextOfKinDetailsForm.address &&
      nextOfKinDetailsForm.phoneNumber &&
      nextOfKinDetailsForm.emailAddress &&
      // nextOfKinDetailsForm.gender &&
      nextOfKinDetailsForm.firstName
      &&
      isEmailAddressValid(nextOfKinDetailsForm)
    )
  };

  const stripSpacesFromString = (str) => {
    return str.replace(/ /g, "");
  }

  const congratsModal = () => {
    setShowCongratsModal(true);
  };

  const handleSubmit = async () => {
    // setIsLoading(true);
    // const payload = {
    //   nextOfKin: {
    //     emailAddress: nextOfKinDetailsForm.emailAddress,
    //     firstName: nextOfKinDetailsForm.firstName,
    //     // gender: nextOfKinDetailsForm.gender,
    //     middleName: nextOfKinDetailsForm.middleName,
    //     phoneNumber: formatPhoneNumber(nextOfKinDetailsForm.phoneNumber),
    //     relationship: nextOfKinDetailsForm.relationship,
    //     // surname: nextOfKinDetailsForm.surname,
    //     address: nextOfKinDetailsForm.address,
    //   },
    // };

    // const { status, response } = await onboarding.putApplication(
    //   payload,

    //   applicationId
    // );

    // setIsLoading(false);
    // if (status === ERROR_STATUS) {
    //   firetoast('error', `${response.description}`, '');
    //   console.log(response.description, 'response');
    // } else if (status === SUCCESS_STATUS) {
    //   console.log(response, 'response');
    //   notify('Saved', 'success');
    //   // setScreen(6);
    //   congratsModal();

    // }
    congratsModal();
  };

  const relationship = [
    'Aunt',
    'Brother',
    'Cousin',
    'Daughter',
    'Father',
    'Guardian',
    'Mother',
    'Nephew/Niece',
    'Sister',
    'Spouse',
    'Son',
    'Uncle',
  ];

  // const gender = ['Male', 'Female'];
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '40px'
      }}>
        <HeaderSignup />
        <BackButton onClick={() => setScreen(4)} />
      </div>
      <main className='onboarding-container'>
        <Col
          style={{
            marginBottom: '20px',
          }}
        >
          <SignUp />
          <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            gap: "10px",
            marginBottom: '30px',
          }}>
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
            <Stepper borderColor="#00425F" />
          </div>
          <H1
            style={{
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: '1rem',
            }}
          >
            Next of Kin Details
          </H1>
          {/* <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          > */}
          <InputText
            label={'Full Name'}
            placeholder={'Enter Name'}
            onChange={(e) => {
              updateFormField({ firstName: e.target.value });
            }}
            labelColor={
              !nextOfKinDetailsForm.firstName ||
                isNameValid(nextOfKinDetailsForm.firstName)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!nextOfKinDetailsForm.firstName ||
              isNameValid(nextOfKinDetailsForm.firstName)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            value={nextOfKinDetailsForm.firstName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />

          <InputText
            label={'Phone Number'}
            placeholder={'Enter phone number'}
            onChange={(e) => {
              updateFormField({ phoneNumber: stripSpacesFromString(e.target.value) });
            }}
            labelColor={
              !nextOfKinDetailsForm.phoneNumber ||
                isNumberValid(nextOfKinDetailsForm.phoneNumber)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!nextOfKinDetailsForm.phoneNumber ||
              isNumberValid(nextOfKinDetailsForm.phoneNumber)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            value={nextOfKinDetailsForm.phoneNumber}
            maxLength={11}
          />

          <div
            style={{
              marginTop: '15px',
            }}
          >
            <InputText
              // disabled={applicationId}
              onChange={(e) => {
                updateFormField({ emailAddress: e.target.value.trim() });
              }}
              labelColor={
                !nextOfKinDetailsForm.emailAddress || isEmailAddressValid(nextOfKinDetailsForm)
                  ? '#3A3B3B'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!nextOfKinDetailsForm.emailAddress || isEmailAddressValid(nextOfKinDetailsForm)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
                }`}
              type={'email'}
              value={nextOfKinDetailsForm.emailAddress}
              label={'Email address'}
              placeholder={'Enter  email adress'}
            />
          </div>

          <div
            style={{
              marginTop: '15px',
            }}
          >
            <InputText
              label={'Home Address'}
              labelColor={
                !nextOfKinDetailsForm.address ||
                  isBusinessAddressValidNOK(nextOfKinDetailsForm)
                  ? 'black'
                  : process.env.REACT_APP_RED_COLOUR
              }
              border={`1px solid ${!nextOfKinDetailsForm.address ||
                isBusinessAddressValidNOK(nextOfKinDetailsForm)
                ? '#DFE2E6'
                : process.env.REACT_APP_RED_COLOUR
                }`}
              placeholder={'Enter your address'}
              onChange={(e) => {
                updateFormField({ address: e.target.value });
              }}
              maxLength={200}
              value={nextOfKinDetailsForm.address}
            />
          </div>

          <SelectInput
            label={'Relationship'}
            onChange={(e) => {
              updateFormField({ relationship: e.target.value });
            }}
            placeholder={'relationship'}
            value={nextOfKinDetailsForm.relationship}
            option={relationship}
          />

          <PrimaryButton
            // disabled={!isFormValid() || isLoading}
            onClick={handleSubmit}
          // onClick={() => setScreen(6)}
          >
            {isLoading ? 'Processing...' : 'Submit'}
          </PrimaryButton >

          <PrimaryButton2 onClick={() => setScreen(6)}>
            {isLoading ? 'Processing...' : 'Preview'}
          </PrimaryButton2>
        </Col>
      </main>

      <CongratsModal show={showCongratsModal} />

    </>
  );
};

export default NextOfKinScene;
