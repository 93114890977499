import React, { useEffect, useState } from 'react';
import PrimaryButton from '../component/button';

import { Col } from 'react-bootstrap';
import H1 from '../../../../components/h1';
import CountriesStatesLga from '../../../../fixtures/countries_states_lgas.json';
import BackButton from '../component/backButton';
import InputText from '../component/input';
import { SelectInputLga, SelectInputState } from '../component/stateSelect';

import { firetoast, notify } from '../../../../components/extras';
import FormLabel from '../../../../components/form-label';
import HeaderSignup from '../../../../components/header-signup';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../constants/api';
import Onboarding from '../../../../services/api/resources/onboarding';
import {
  isBusinessAddressValid,
  isBusinessLgaValid,
  isBusinessNameValid,
} from '../component/extras';
import SignUp from '../component/signupText';
import Stepper from '../component/stepper';

const MAX_BUSINESS_NAME_LENGTH = 50;
const BusinessDetailsScene = ({ setScreen, application }) => {
  const applicationId = localStorage.getItem('applicationId');
  const applicationDetail = JSON.parse(localStorage.getItem('application'))
  const onboarding = new Onboarding();
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const surname = applicationDetail?.applicantDetails?.surname;
  const firstName = applicationDetail?.applicantDetails?.firstName;
  const updatedState = localStorage.getItem('state');
  const updatedLga = localStorage.getItem('lga');

  const updatedbusinessDetailsForm = JSON.parse(
    localStorage.getItem('businessDetailsForm')
  );

  const [businessDetailsForm, setBusinessDetailsForm] = useState({
    businessAddress: application ?
      application?.businessDetails?.address
      : '',
    businessName: applicationDetail ? `${firstName} ${surname}` : "",
    state: application ?
      application?.businessDetails?.state
      : '',
    localGovernmentArea: application ?
      application?.businessDetails?.localGovernmentArea
      : '',
  });

  useEffect(() => {
    const updatedbusinessDetailsForm = JSON.parse(
      localStorage.getItem('businessDetailsForm')
    );

    if (updatedbusinessDetailsForm) {
      setBusinessDetailsForm({
        businessAddress:
          application?.businessDetails?.address ||
          updatedbusinessDetailsForm.businessAddress ||
          '',
        businessName:
          applicationDetail?.businessDetails?.businessName ||
          updatedbusinessDetailsForm.businessName ||
          `${applicationDetail?.applicantDetails?.firstName || ''} ${applicationDetail?.applicantDetails?.surname || ''}`,
        state:
          application?.businessDetails?.state ||
          updatedbusinessDetailsForm.state ||
          '',
        localGovernmentArea:
          application?.businessDetails?.localGovernmentArea ||
          updatedbusinessDetailsForm.localGovernmentArea ||
          '',
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem('businessDetailsForm', JSON.stringify(businessDetailsForm));
  }, [businessDetailsForm]);

  const updateFormField = (params) => {
    if (params.state) {
      const newForm = {
        ...businessDetailsForm,
        localGovernmentArea: '',

        ...params,
      };
      setBusinessDetailsForm(newForm);
    } else {
      const newForm = {
        ...businessDetailsForm,

        ...params,
      };
      setBusinessDetailsForm(newForm);
    }
  };
  const fetchAllStates = async (countryId) => {
    const stateArray = CountriesStatesLga[0].states.map((state) => {
      return {
        name: state.name,
        id: state.id,
      };
    });

    setStates(stateArray);
  };

  const isFormValid = () => {
    return (
      businessDetailsForm.state &&
      businessDetailsForm.businessAddress &&
      businessDetailsForm.businessName &&
      businessDetailsForm.localGovernmentArea &&
      isBusinessAddressValid(businessDetailsForm) &&
      isBusinessNameValid(businessDetailsForm)
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      businessDetails: {
        state: businessDetailsForm.state,
        address: businessDetailsForm.businessAddress,
        businessName: businessDetailsForm.businessName,
        localGovernmentArea: businessDetailsForm.localGovernmentArea,
      },
    };

    const { status, response } = await onboarding.putApplication(
      payload,
      applicationId
    );

    setIsLoading(false);
    if (status === ERROR_STATUS) {
      firetoast('error', `${response.description}`, '');
      setIsLoading(false);
      console.log(response.description, 'response');
    } else if (status === SUCCESS_STATUS) {
      notify('Saved', 'success');
      setScreen(5);
    }
  };

  // console.log(businessDetailsForm.state, "state")

  const selectedStateData = CountriesStatesLga[0].states.find(
    (state) => state.id === businessDetailsForm.state
  );
  // console.log(selectedStateData.lgas, "selectedLga")

  useEffect(() => {
    fetchAllStates();
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '40px',
        }}
      >
        <HeaderSignup />
        <BackButton onClick={() => setScreen(3)} />
      </div>
      <main className='onboarding-container'>
        <Col
          style={{
            marginBottom: '20px',
            width: '80%',
            margin: '0 auto',
          }}
        >
          <SignUp />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: '10px',
              marginBottom: '30px',
            }}
          >
            <Stepper borderColor='#00425F' />
            <Stepper borderColor='#00425F' />
            <Stepper borderColor='#00425F' />
            <Stepper />
          </div>
          <H1
            style={{
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: '1rem',
            }}
          >
            Business Details
          </H1>
          <InputText
            label={'Business Name'}
            onChange={(e) => {
              updateFormField({ businessName: e.target.value });
            }}
            labelColor={
              !businessDetailsForm.businessName ||
                isBusinessNameValid(businessDetailsForm)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!businessDetailsForm.businessName ||
              isBusinessNameValid(businessDetailsForm)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your Business Name'}
            value={businessDetailsForm.businessName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
            disabled={true}
          />
          <InputText
            label={'Shop Address'}
            labelColor={
              !businessDetailsForm.businessAddress ||
                isBusinessAddressValid(businessDetailsForm)
                ? 'black'
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${!businessDetailsForm.businessAddress ||
              isBusinessAddressValid(businessDetailsForm)
              ? '#DFE2E6'
              : process.env.REACT_APP_RED_COLOUR
              }`}
            placeholder={'Enter your Business Address'}
            onChange={(e) => {
              updateFormField({ businessAddress: e.target.value });
            }}
            maxLength={200}
            value={businessDetailsForm.businessAddress}
          />

          <SelectInputState
            label={'State'}
            option={states}
            getState={localStorage.getItem('state')}
            value={businessDetailsForm.state}
            placeholder={'state'}
            onChange={(e) => {
              localStorage.setItem(
                'state',
                states.find((value) => value.name === e.target.value).name
              );
              setBusinessDetailsForm({ ...businessDetailsForm, lga: '' });
              localStorage.removeItem('lga');
              setSelectedState(
                states.find((value) => value.name === e.target.value).id
              );
              updateFormField({
                state: states.find((value) => value.name === e.target.value).id,
              });
            }}
          />

          <SelectInputLga
            border={`3px solid ${!isBusinessLgaValid(localStorage.getItem('lga')) &&
              businessDetailsForm.state
              ? process.env.REACT_APP_RED_COLOUR
              : '#DFE2E6'
              }`}
            label={'LGA'}
            option={selectedState ? selectedStateData?.lgas : []}
            value={businessDetailsForm?.localGovernmentArea}
            getLga={localStorage.getItem('lga')}
            placeholder={'LGA'}
            onChange={(e) => {
              localStorage.setItem(
                'lga',
                selectedStateData?.lgas.find(
                  (value) => value.name === e.target.value
                ).name
              );
              updateFormField({
                localGovernmentArea: selectedStateData?.lgas.find(
                  (value) => value.name === e.target.value
                ).id,
              });
            }}
          />
          {!isBusinessLgaValid(localStorage.getItem('lga')) &&
            businessDetailsForm.state && (
              <FormLabel
                style={{
                  color: process.env.REACT_APP_RED_COLOUR,
                  fontSize: process.env.REACT_APP_SMALL_FONT_SIZE,
                  lineHeight: '19px',
                  position: 'relative',
                  top: '-15px',
                  width: '100%',
                }}
              >
                Select Local Government Area
              </FormLabel>
            )}

          <PrimaryButton
            // onClick={handleSubmit}
            // disabled={!isFormValid() || isLoading}
            onClick={() => setScreen(5)}
          >
            {isLoading ? 'Processing...' : 'Next'}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default BusinessDetailsScene;
