import React, { useState } from "react";
import CommisionsLabel from "./components/commisionsLabel";
import { connect } from "react-redux";
import { navigateTo } from "../../../../../services/redux/actions/navigation";
import { updateLoading } from "../../../../../services/redux/actions/tunnel";
import { Nav, Tab } from "react-bootstrap";
import EarningsScreen from "./earnings/earnings";
import WithdrawalsSceen from "./withdrawals/withdrawals";
import "./app.css";
import TransactionHistory from "../../../../../services/api/resources/transaction-history";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import { specialDateRangeFormatter_StartDate } from "../../../../../utils/formatters";

const CommisionsSceen = (props) => {
  const [getEarningBalance, setGetEarningBalance] = useState();
  const transactionHistory = new TransactionHistory();
  const [loading, setLoading] = useState(true);

  const fetchEarningBalance = async (dateIntervalType, startDate, endDate) => {
    setGetEarningBalance([]);
    setLoading(true);
    // const payload = {
    //   dateIntervalType: dateIntervalType,
    // };

    let payload = {};
    if (dateIntervalType) {
      payload = {
        dateIntervalType: dateIntervalType,
      };
    } else {
      payload = {
        from: specialDateRangeFormatter_StartDate(startDate),
        to: specialDateRangeFormatter_StartDate(endDate),
      };
    }
    const { status, response } = await transactionHistory.getEarnings(payload);

    setLoading(false);
    if (status === ERROR_STATUS) {
      // setError(true);
    } else if (status === SUCCESS_STATUS) {
      setLoading(false);

      setGetEarningBalance(response.data);
    } else {
      setLoading(false);
    }
  };
  return (
    <main>
      <CommisionsLabel />
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={
          props.location.state === "withdrawal" ? "second" : "first"
        }
      >
        <div className="tab-container-commission">
          <div>
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  style={{
                    color: process.env.REACT_APP_BLUE_COLOUR,
                  }}
                  eventKey="first"
                >
                  Earnings
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ color: process.env.REACT_APP_BLUE_COLOUR }}
                  eventKey="second"
                >
                  Withdrawals
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="commision-container">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <EarningsScreen
                loading={loading}
                getEarningBalance={getEarningBalance}
                fetchEarningBalance={fetchEarningBalance}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <WithdrawalsSceen
              // loading={loading}
              // getEarningBalance={getEarningBalance}
              // fetchEarningBalance={fetchEarningBalance}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.tunnel.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading: (isLoading) => dispatch(updateLoading(isLoading)),
    navigateTo: (pendingUrl) => dispatch(navigateTo(pendingUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommisionsSceen);
