import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { firetoast, notify } from "../../../../components/extras";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import Onboarding from "../../../../services/api/resources/onboarding";
import { isBvnValid, stripNonNumbers } from "./extras";
import InputText from "./input";

const BVNModal = ({ application, show, handleClose }) => {
    const history = useHistory();
    const onboarding = new Onboarding();

    const [error, setError] = useState(false)
    const [bvnDetails, setBvnDetails] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [bvnDetailsForm, setBVNDetailsForm] = useState({
        bvn: application ?
            application?.applicantDetails?.bvnDetails?.bvn
            : '',
    });

    const confirmBVN = async () => {
        setIsLoading(true);
        if (bvnDetailsForm.bvn) {
            const { status, response } = await onboarding.bvnConfirmation(bvnDetailsForm.bvn);

            setIsLoading(false);
            if (status === ERROR_STATUS) {
                firetoast("error", `${response.description}`, "");

            } else if (status === SUCCESS_STATUS) {
                if (response?.code === "20000") {
                    notify("success");
                    localStorage.setItem("bvnInformation", JSON.stringify(response));
                    history.push("/signup/onboarding-new-agent");
                } else {
                    setBvnDetails(response)
                    setError(true)
                }

            }
        }
    };

    const updateFormField = (params) => {
        const newForm = {
            ...bvnDetailsForm,
            ...params,
        };

        setBVNDetailsForm(newForm);
    };

    const isFormValid = () => {
        return (
            bvnDetailsForm.bvn &&
            isBvnValid(bvnDetailsForm)
        );
    };

    useEffect(() => {
        if (bvnDetailsForm.bvn === 11) {
            confirmBVN();
        }
    }, [bvnDetailsForm.bvn]);

    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              }}
            >
            <Modal.Body style={{ padding: "2rem" }}>
                <div style={{ alignContent: "center", padding: '1rem' }}>
                    <h1
                        style={{
                            fontWeight: "bold",
                            marginBottom: "1rem",
                            fontSize: "1.5rem",
                        }}
                    >
                        Enter your BVN
                    </h1>
                    <p
                        style={{
                            color: "#5F738C",
                            fontWeight: "500",
                            fontSize: "0.8rem",
                            letterSpacing: "0.3px",

                        }}
                    >
                        Dail *565*0# to securely get your BVN from your network provider.
                    </p>

                    <InputText
                        label={'BVN'}
                        labelColor={
                            !bvnDetailsForm.bvn ||
                                isBvnValid(bvnDetailsForm)
                                ? 'black'
                                : process.env.REACT_APP_RED_COLOUR
                        }
                        border={`1px solid ${!bvnDetailsForm.bvn || error ||
                            isBvnValid(bvnDetailsForm)
                            ? '#DFE2E6'
                            : process.env.REACT_APP_RED_COLOUR
                            }`}
                        placeholder={'Enter BVN'}
                        onChange={(e) => {
                            updateFormField({ bvn: stripNonNumbers(e.target.value.trim()) });
                        }}
                        maxLength={11}
                        value={bvnDetailsForm.bvn}
                    />
                    {error && (
                        <span style={{ color: process.env.REACT_APP_RED_COLOUR, fontWeight: '400', fontSize: '14px', lineHeight: '20px', marginTop: '-10px' }}>
                            BVN does not exist, Kindly Retry.
                        </span>
                    )}

                    <button disabled={!isFormValid() || isLoading}
                        onClick={confirmBVN}
                        className="signupButton2"
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                            color: "white",
                            backgroundColor: "#00425f",
                            width: '100%',
                        }}
                    >
                        {" "}
                        {isLoading ? "Processing..." : "Next"}
                        {" "}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BVNModal;
