import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col } from "react-bootstrap";
import { firetoast, notify } from "../../../../components/extras";
import H1 from "../../../../components/h1";
import HeaderSignup from "../../../../components/header-signup";
import { CAC_REGISTRATION, NIGERIA_PHONE_PREFIX } from "../../../../constants";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../constants/api";
import BackButton from "../component/backButton";
import PrimaryButton from "../component/button";
import { isProposedBusinessNameValid } from "../component/extras";
import InputText from "../component/input";
import SelectInput from "../component/select";
import Platform from "../../../../services/api/resources/platform";
import { generateChecksum } from "../../../../utils/helpers";
import { QUICKTELLER_TERMINAL_ID } from "../../../../utils/api-properties";
import { TRANSACTION_INITIATE_CLICK } from "../../../../constants/analytics";
import { stopwatch } from "../../../../App";
import { logEvent } from "../../../../core/logger";
import Transaction from "../../../../services/api/resources/transaction";
import { toast } from "react-toastify";

const MAX_BUSINESS_NAME_LENGTH = 50;
const CACRegistrationScene = ({ setScreen, application }) => {
  const history = useHistory();
  const platform = new Platform();
  const transaction = new Transaction();

  const [errorMessage, setErrorMessage] = useState("");
  const [isFormValidAfterSubmit, setIsFormValidAfterSubmit] = useState(true);
  const [cacRegistrationForm, setCacRegistrationForm] = useState({
    proposedName: application
      ? application?.applicantDetails?.cacRegistration?.proposedName
      : "",
    businessLine: application
      ? application?.applicantDetails?.cacRegistration?.businessLine
      : "",
  });

  useEffect(() => {
    const updatedcacRegistrationForm = JSON.parse(
      localStorage.getItem("cacRegistrationForm")
    );

    if (updatedcacRegistrationForm) {
      setCacRegistrationForm({
        proposedName:
          application?.applicantDetails?.cacRegistration?.proposedName ||
          updatedcacRegistrationForm.proposedName ||
          "",
        businessLine:
          application?.applicantDetails?.cacRegistration?.businessLine ||
          updatedcacRegistrationForm.businessLine ||
          "",
      });
    }
  }, [application]);

  useEffect(() => {
    localStorage.setItem(
      "cacRegistrationForm",
      JSON.stringify(cacRegistrationForm)
    );
  }, [cacRegistrationForm]);

  const [isLoading, setIsLoading] = useState(false);

  const updateFormField = (params) => {
    const newForm = {
      ...cacRegistrationForm,
      ...params,
    };

    setCacRegistrationForm(newForm);
  };

  const isFormValid = () => {
    return (
      cacRegistrationForm.proposedName &&
      cacRegistrationForm.businessLine &&
      isProposedBusinessNameValid(cacRegistrationForm.proposedName)
    );
  };

  const fetchBvnDetails = async () => {
    try {
      const { status, response } = await platform.getBvnInfo();

      if (status === ERROR_STATUS) {
        notify(response.description, "error");
      } else {
        localStorage.setItem("bvnInfo", JSON.stringify(response));
      }
    } catch (error) {
      console.error("Error fetching BVN details:", error);
    } finally {
    }
  };

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const createCacPaymentRequest = async () => {
    const paymentItemCode = "IFISCAC";
    const amount = 0;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const deviceId = localStorage.getItem("deviceUid");
    const httpMethod = "POST";
    const username = currentUser.username;

    logEvent(TRANSACTION_INITIATE_CLICK, {
      transaction_type: CAC_REGISTRATION,
    });

    stopwatch.start();

    if (
      cacRegistrationForm.proposedName &&
      cacRegistrationForm.businessLine &&
      isProposedBusinessNameValid(cacRegistrationForm.proposedName)
    ) {
      // Set form valid status to false while the request is being processed
      setIsFormValidAfterSubmit(false);

      try {
        const transactionAmount = amount;
        const paymentInstrumentType = "CASH";
        const businessName = cacRegistrationForm.proposedName;
        const lineOfBusiness = cacRegistrationForm.businessLine;
        const terminalId = QUICKTELLER_TERMINAL_ID;

        const { status, response } = await transaction.initiateCacRegPayment(
          {
            transactionAmount,
            paymentInstrumentType,
            businessName,
            lineOfBusiness,
            paymentItemCode,
            terminalId,
          },
          generateChecksum(
            `${username}${httpMethod}${amount}${httpMethod}${paymentItemCode}${httpMethod}${deviceId}`
          ),
          deviceId
        );

        if (status === ERROR_STATUS) {
          notify("Proposed name already exists", "error");
          setIsFormValidAfterSubmit(false);
        } else if (status === SUCCESS_STATUS) {
          localStorage.setItem(
            "cacInitiateRequest",
            JSON.stringify(response.cacInitiateRequest)
          );
          notify("Proposed name registered successfully", "success");
          setIsFormValidAfterSubmit(true);
        }
      } catch (error) {
        notify("An unexpected error occurred. Please try again.");
        setIsFormValidAfterSubmit(false);
        console.error(error);
      }
    }
  };

  const getMessageColor = () => {
    return errorMessage === "Proposed name registered successfully"
      ? "green"
      : process.env.REACT_APP_RED_COLOUR;
  };

  const businessLine = [
    "General Merchandise",
    "Trading",
    "Fashion design/tailoring",
    "ICT Service",
    "Data Analysis",
    "Poultry/Livestock Farming",
    "Crop production farming/Agro allied service",
    "Hair stylist/salon",
    "Solar Panel installation",
    "Digital Marketing",
    "Graphic Design",
    "Content Creation",
    "Web Design",
    "POS Agent",
  ];

  useEffect(() => {
    fetchBvnDetails();
  }, []);

  useEffect(() => {
    if (
      cacRegistrationForm.proposedName &&
      cacRegistrationForm.businessLine &&
      isProposedBusinessNameValid(cacRegistrationForm.proposedName)
    ) {
      createCacPaymentRequest(cacRegistrationForm);
    }
  }, [cacRegistrationForm]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <HeaderSignup />
        <BackButton marginBottom={0} onClick={() => history.goBack()} />
      </div>
      <main className="onboarding-container">
        <Col
          style={{
            marginBottom: "20px",
          }}
        >
          <H1
            style={{
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            Complete the details below
          </H1>
          <InputText
            label={"Proposed business name"}
            placeholder={"Enter Business Name"}
            onChange={(e) => {
              updateFormField({ proposedName: e.target.value });
            }}
            labelColor={
              !cacRegistrationForm.proposedName ||
              isProposedBusinessNameValid(cacRegistrationForm.proposedName)
                ? "black"
                : process.env.REACT_APP_RED_COLOUR
            }
            border={`1px solid ${
              !cacRegistrationForm.proposedName ||
              isProposedBusinessNameValid(cacRegistrationForm.proposedName)
                ? "#DFE2E6"
                : process.env.REACT_APP_RED_COLOUR
            }`}
            value={cacRegistrationForm.proposedName}
            maxLength={MAX_BUSINESS_NAME_LENGTH}
          />
          {errorMessage && (
            <span
              style={{
                color: getMessageColor(),
                fontSize: "10px",
                marginTop: "-3px",
              }}
            >
              {errorMessage}
            </span>
          )}

          <SelectInput
            label={"Line of business"}
            onChange={(e) => {
              updateFormField({ businessLine: e.target.value });
            }}
            placeholder={"business line"}
            value={cacRegistrationForm.businessLine}
            option={businessLine}
          />
          <PrimaryButton
            onClick={() => setScreen(2)}
            disabled={!isFormValid() || isLoading || !isFormValidAfterSubmit}
          >
            {isLoading ? "Processing..." : "Next"}
          </PrimaryButton>
        </Col>
      </main>
    </>
  );
};

export default CACRegistrationScene;
