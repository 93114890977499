import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../aggregator-module/performance/settings/components/customButton";
import InputText from "../../../../signup/onboarding-setup/component/input";
import CAC from "../../../../../services/api/resources/cac-service";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../constants/api";
import { firetoast, notify } from "../../../../../components/extras";
export const ModalComponent = ({
  handleOpenView,
  showModal,
  modalData,
  getApplication,
}) => {
  const cac = new CAC();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(
    modalData?.original?.registrationRequestPayload
  );
  const [imageErrors, setImageErrors] = useState({});
  const imageFields = ["supportingDoc", "signature", "meansOfId"];
  const hiddenFields = [
    "businessCommencementDate",
    "passport",
    "transactionRef",
    "proprietorEmail",
    "proprietorDob",
    "proprietorGender",
    "proprietorPhonenumber",
  ];
  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const isFormValid = () => {
    return (
      formData.lineOfBusiness &&
      formData.proprietorCity &&
      formData.companyCity &&
      formData.companyState &&
      formData.proprietorNationality &&
      formData.proprietorState &&
      formData.proprietorFirstname &&
      formData.proprietorOthername &&
      formData.proprietorSurname &&
      formData.proposedOption1 &&
      formData.proprietorStreetNumber &&
      formData.proprietorServiceAddress &&
      formData.companyEmail &&
      formData.companyStreetNumber &&
      formData.companyAddress &&
      formData.proprietorPostcode &&
      formData.proprietorLga &&
      formData.supportingDoc &&
      formData.signature &&
      formData.meansOfId
    );
  };

  const isFieldQueried = (fieldName) => {
    return modalData?.original?.queriedFieldMap?.some(
      (item) => item?.reason === fieldName
    );
  };

  const getFieldComment = (fieldName) => {
    const queriedField = modalData?.original?.queriedFieldMap?.find(
      (item) => item?.reason === fieldName
    );
    return queriedField ? queriedField?.comment : "";
  };

  const fieldsToShow = Object.keys(formData).filter(
    (field) => !hiddenFields.includes(field)
  );

  const groupedFields = [];
  for (let i = 0; i < fieldsToShow.length; i += 2) {
    groupedFields.push(fieldsToShow.slice(i, i + 2));
  }

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleImageChange = (e, field) => {
    const file = e.target.files[0];

    if (file.size > 1024 * 1024) {
      setImageErrors({
        ...imageErrors,
        [field]: "File size should not exceed 1MB",
      });
      return;
    }

    setImageErrors({
      ...imageErrors,
      [field]: "",
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({
        ...formData,
        [field]: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmitEdit = async () => {
    setIsLoading(true);
    const { response } = await cac.updateCacRegistrationReport(formData);
    setIsLoading(false);
    if (response.code === "400") {
      notify(response.description, "error");
    } else if (response.code === "200") {
      notify(response.description, "success");
      handleOpenView();
      getApplication();
    } else {
      notify("An error occur, please try again", "error");
    }
  };
  const handleSubmitRequery = async () => {
    setLoading(true);
    const { response } = await cac.requeryCacRegistrationReport(
      modalData.original.reference
    );
    setLoading(false);
    if (response.code === "00") {
      firetoast("success", "", "Request successful");
      handleOpenView();
      getApplication();
    } else if (response.code === "02") {
      firetoast("warning", "", "Your request is being reviewed");
      handleOpenView();
      getApplication();
    } else {
      firetoast("error", `${response.description}`, "");
    }
  };

  return (
    <Modal size="lg" onHide={() => handleOpenView()} centered show={showModal}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontWeight: 700,
            fontSize: "16px",
            textTransform: "capitalize",
          }}
        >
          {modalData?.original.businessName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "1.5rem" }}>
        <form>
          {modalData?.original?.status === "QUERIED" && (
            <div style={{ marginBottom: "20px", color: "red" }}>
              <strong>Please update the information below:</strong>
            </div>
          )}
          {modalData?.original?.status === "PROCESSING" && (
            <div style={{ marginBottom: "20px", color: "red" }}>
              <strong>Will you like to requery this registration?</strong>
            </div>
          )}

          <div
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
            }}
          >
            {groupedFields.map((fieldGroup, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {fieldGroup.map((field) => (
                  <div key={field} style={{ width: "48%" }}>
                    {imageFields.includes(field) ? (
                      <>
                        <div
                          style={{
                            marginTop: "5px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              color: isFieldQueried(field) ? "red" : "black",
                            }}
                            htmlFor={field}
                          >
                            {formatFieldName(field)}
                          </label>
                          <div
                            style={{
                              maxWidth: "50%",
                              height: "auto",
                            }}
                          >
                            <img
                              src={formData[field]}
                              alt={field}
                              style={{
                                borderRadius: "10px",
                                border: `1px solid ${
                                  isFieldQueried(field) ? "red" : "#D3D3D3"
                                }`,
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </div>
                        </div>
                        {isFieldQueried(field) && (
                          <>
                            {imageErrors[field] ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  marginTop: "-10px",
                                }}
                              >
                                {imageErrors[field]}
                              </p>
                            ) : (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  marginTop: "-10px",
                                }}
                              >
                                {getFieldComment(field)}
                              </p>
                            )}
                            <input
                              type="file"
                              style={{
                                marginTop: "-10px",
                                fontSize: "13px",
                              }}
                              accept="image/*"
                              onChange={(e) => handleImageChange(e, field)}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <InputText
                          type="text"
                          label={formatFieldName(field)}
                          disabled={!isFieldQueried(field)}
                          onChange={(e) => handleInputChange(e, field)}
                          labelColor={isFieldQueried(field) ? "red" : "initial"}
                          border={`1px solid ${
                            isFieldQueried(field) ? "red" : "initial"
                          }`}
                          value={formData[field]}
                        />
                        {isFieldQueried(field) && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "-15px",
                            }}
                          >
                            {getFieldComment(field)}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            ))}{" "}
          </div>

          <div
            style={{
              float: "right",
              margin: "1rem 1rem 0 0",
            }}
          >
            {modalData?.original?.status === "QUERIED" && (
              <PrimaryButton
                margin={0}
                disabled={isLoading || !isFormValid()}
                children={isLoading ? "Processing..." : "Update"}
                onClick={handleSubmitEdit}
              />
            )}
            {modalData?.original?.status === "PROCESSING" && (
              <PrimaryButton
                margin={0}
                disabled={loading}
                children={loading ? "Processing..." : "Requery"}
                onClick={handleSubmitRequery}
              />
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
