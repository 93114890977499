import React from "react";
import { Form } from "react-bootstrap";

const SelectInput = ({
  label,
  disabled = false,
  option,
  onChange,
  placeholder,
  flex,
  value,
  name,
}) => {
  return (
    <div
      style={{
        // marginTop: '1rem',
        // marginBottom: '1rem',
        flex: flex,
      }}
    >
      <Form.Label
        style={{
          color: "#313b3b",

          fontSize: "14px",
        }}
      >
        {label}
      </Form.Label>
      <Form.Control
        name={name}
        onChange={onChange}
        disabled={disabled}
        as="select"
      >
        <option value={value} disabled selected>
          {value ? value : ` Select ${placeholder}...`}
        </option>
        {option.map((item) => {
          return (
            <>
              <option key={item} value={item}>
                {item}
              </option>
            </>
          );
        })}
      </Form.Control>
    </div>
  );
};

export default SelectInput;
